<template>
    <form ref="formRef" class="container">
        <div class="row mb-3" v-for="(connection, index) in hydraulicConnections" :key="index">
            <!-- Reduce space between component name and dropdown -->
            <div class="col-3 component-name text-start fw-bold">{{ connection.input.name }}</div>
            <div class="col-9">
                <div v-for="(output, outputIndex) in connection.outputs" :key="outputIndex" class="mb-2">
                    <!-- Dropdown with label and associated height/maxQ fields -->
                    <div class="d-flex align-items-center mb-2">
                        <div class="form-floating me-3 flex-grow-1">
                            <select v-model="connection.outputs[outputIndex]" class="form-select" required>
                                <option disabled value="">{{ $t('hydraulics.select_connected_component') }}</option>
                                <option v-for="option in remainingComponents(connection.input)" :key="option.name" :value="option">{{ option.name }}</option>
                            </select>
                            <label>{{ $t('hydraulics.auslass') }} {{ outputIndex + 1 }}</label>
                        </div>
                        
                        <!-- Use form-floating for height and maxQ -->
                        <div class="d-flex align-items-center">
                            <div class="form-floating me-2 input-small">
                                <input v-model="connection.input.outlets[outputIndex].height" type="number" step="any" class="form-control" required>
                                <label>{{ $t('hydraulics.outlet_height') }}</label>
                            </div>
                            <div class="form-floating input-small">
                                <input v-model="connection.input.outlets[outputIndex].maxQ" type="number" step="any" class="form-control" required>
                                <label>{{ $t('hydraulics.outlet_maxQ') }}</label>
                            </div>
                        </div>

                        <!-- Minus button for removable outlets or empty space when not applicable -->
                        <button v-if="outputIndex > 0" type="button" class="btn btn-danger ms-2" @click="removeOutput(index, outputIndex)">
                            <font-awesome-icon icon="fa-solid fa-minus" />
                        </button>
                        <div v-else class="ms-2" style="width: 42px;"></div> <!-- Placeholder for alignment -->
                    </div>
                </div>

                <!-- Button for adding new outlets -->
                <button v-if="typesToShowOutlets.includes(connection.input.type)" type="button" class="btn btn-secondary mt-2" @click="addOutput(index)">
                    {{ $t('hydraulics.add_outlet') }}
                </button>
            </div>
        </div>
    </form>
</template>


<script setup>
import { ref } from 'vue';
import QuestionMark from '@/components/icons/Questionmark.vue';

defineExpose({ setConnections, updateConnections, validateInput, getConnections });

const hydraulicConnections = ref([]);
const hydraulicComponents = ref([]);
const tooltipVisibility = ref([]);  // Array to track tooltip visibility for each connection
const formRef = ref(null);
const maxOutputs = 5; // Define the maximum number of outputs per connection

const typesToShowOutlets = ['greenroof', 'storage'];

const remainingComponents = (currentComponent) => {
    return hydraulicComponents.value.filter(component => component.name !== currentComponent.name);
};

function getConnections() {
    return hydraulicConnections.value;
}

function setConnections(connections, components) {
    const connection_helper = [];
    const groupedConnections = {};

    // Group connections by input_id
    for (const connection of connections) {
        if (!groupedConnections[connection.input_id]) {
            groupedConnections[connection.input_id] = [];
        }
        groupedConnections[connection.input_id].push(connection);
    }

    // Handle the case when there are no connections but there are components
    if (connections.length === 0 && components.length !== 0) {
        for (const component of components) {
            connection_helper.push({ ids: [null], input: component, outputs: [""] });
        }
    } else {
        // Create connection_helper entries
        for (const input_id in groupedConnections) {
            const connectionGroup = groupedConnections[input_id];
            const component_input = components.find(component => component.id === parseInt(input_id));
            const component_outputs = connectionGroup.map(connection =>
                components.find(component => component.id === connection.output_id)
            );
            const ids = connectionGroup.map(connection => connection.id);
            connection_helper.push({ ids: ids, input: component_input, outputs: component_outputs });
        }
    }

    hydraulicConnections.value = connection_helper;
    hydraulicComponents.value = components;

    // Initialize tooltip visibility array
    tooltipVisibility.value = new Array(hydraulicConnections.value.length).fill(false);
}

function updateConnections(components) {
    const newConnections = [];

    // Add or preserve existing connections
    for (const component of components) {
        if (component.type === 'river' || component.type === 'groundwater') {
            continue;
        }
        const existingConnection = hydraulicConnections.value.find(connection => connection.input.id === component.id);
        if (existingConnection) {
            existingConnection.input = component;
            newConnections.push(existingConnection);
        } else {
            newConnections.push({ ids: [null], input: component, outputs: [""] });
        }
    }

    hydraulicConnections.value = newConnections;

    // Update the hydraulicComponents
    hydraulicComponents.value = components;

    // Update the tooltip visibility array
    tooltipVisibility.value = new Array(hydraulicConnections.value.length).fill(false);
}

function validateInput() {
    return formRef.value.reportValidity();
}

function addOutput(connectionIndex) {
    if (hydraulicConnections.value[connectionIndex].outputs.length < maxOutputs) {
        hydraulicConnections.value[connectionIndex].outputs.push("");
        hydraulicConnections.value[connectionIndex].ids.push(null);
        hydraulicConnections.value[connectionIndex].input.outlets.push({
            height: 0.0,
            maxQ: 0.0
        });
    }
}

function removeOutput(connectionIndex, outputIndex) {
    if (hydraulicConnections.value[connectionIndex].outputs.length > 1) {
        hydraulicConnections.value[connectionIndex].outputs.splice(outputIndex, 1);
        hydraulicConnections.value[connectionIndex].ids.splice(outputIndex, 1);
        hydraulicConnections.value[connectionIndex].input.outlets.splice(outputIndex, 1);
    }
}
</script>

<style scoped>
.container {
    margin-top: 1rem;
    overflow: auto;
}

.component-name {
    text-align: right;
    padding-right: 10px;
}

.input-small {
    max-width: 100px;
}

.tooltip-wrapper {
    position: relative;
    display: inline-block;
}

.custom-tooltip {
    position: absolute;
    width: fit-content;
    background-color: #505050;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    z-index: 1;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.input-group {
    display: flex;
    gap: 10px;
    margin-top: 1rem;
}

.input-small {
    max-width: 100px;
}

.form-control {
    padding: 0.5rem;
    font-size: 1rem;
}

.form-floating .form-control {
    color: #000;
    /* Ensures text is visible */
    background-color: #f8f9fa;
    /* Adjust the background color if needed */
}

.form-floating label {
    color: #6c757d;
    /* Default color for the label */
}
</style>
