<template>
    <div v-show="!pickFaceIn3DModel" class="custom-modal">
        <div class="modal-dialog bg-dark">
            <div class="modal-content bg-dark text-light">
                <div class="modal-header">
                    <h2>{{ $t('levels.modal_title') }}</h2>
                    <button class="close btn-close btn-close-white" @click="closeModal"></button>
                </div>
                <form class="modal-body" @submit.prevent="confirm">
                    <button type="button" class="btn btn-light max-content" @click="addLevel" :title="$t('levels.add_level')">
                        <font-awesome-icon icon="fa-solid fa-plus" />
                    </button>
                    <div class="list-container">
                        <div v-for="(level, index) in levels" :key="index" class="level-row">
                            <!-- Floating Label for Name Input -->
                            <div class="form-floating flex-grow-1">
                              <input
                                type="text"
                                class="form-control"
                                v-model="level.name"
                                :id="`name-${index}`"
                                required
                                :disabled="level.name === default_values.default_level_name"
                              />
                              <label :for="'name-' + index">{{ $t('levels.enter_level_name') }}</label>
                            </div>

                            <!-- Floating Label for Z-Elevation Input -->
                            <div class="form-floating">
                              <input
                                type="number"
                                step="any"
                                class="form-control"
                                v-model="level.zElevation"
                                :id="`z-elevation-${index}`"
                                required
                                :disabled="level.name === default_values.default_level_name"
                              />
                              <label :for="'z-elevation-' + index">{{ $t('levels.z_elevation') }}</label>
                            </div>

                            <button
                                type="button"
                                class="btn btn-secondary pick-height-btn"
                                @click="selectObject(index)"
                                :disabled="level.name === default_values.default_level_name"
                                :title="$t('levels.pick_height_from_model')"
                            >
                                <font-awesome-icon icon="fa-solid fa-arrows-up-down" />
                                <font-awesome-icon icon="fa-solid fa-building" />   
                            </button>
                            
                            <div class="me-4"></div>

                            <!-- Custom File Input -->
                            <div class="custom-file-input"> 
                                <div class="label-wrapper">
                                    <input type="file" accept=".dxf" :id="`fileInput-${index}`" @change="handleFileUpload($event, index)" />
                                    <label :for="`fileInput-${index}`" class="file-label" :title="level.dxfName || ''">{{ level.dxfName || '' }}</label>
                                    <!-- Cross button to delete the uploaded file -->
                                    <div v-if="level.dxfObject" class="cross-btn" @click="removeDxf(index)">
                                        <font-awesome-icon icon="fa-solid fa-circle-xmark"/>
                                    </div>

                                </div>
                                
                                <button class="btn btn-outline-secondary custom-file-button" type="button" @click="triggerFileInput(index)">
                                    <font-awesome-icon icon="fa-solid fa-upload" />
                                    {{ $t('levels.upload_dxf') }}
                                </button>
                            </div>

                            <!-- BUTTON TO PICK Positioning of DXF -->

                            <!-- BUTTON TO ROTATE DXF -->

                            <!-- Floating Label for Scale of dxf -->
                            <div class="form-floating">
                              <input
                                type="number"
                                step="any"
                                class="form-control"
                                v-model="level.dxfScale"
                                :id="`dxf-scale-${index}`"
                                :disabled="!level.dxfObject"
                              />
                              <label :for="'dxf-scale-' + index">{{ $t('levels.scale') }}</label>
                            </div>

                            <button
                              type="button"
                              class="btn btn-danger ms-4 align-self-stretch"
                              @click="removeLevel(index)"
                              :disabled="level.name === default_values.default_level_name"
                              :title="$t('levels.remove_level')"
                            >
                              <font-awesome-icon icon="fa-solid fa-minus" />
                            </button>
                        </div>
                    </div>

                    <button class="btn btn-primary" type="submit" :disabled="processingDxf">
                        <font-awesome-icon icon="fa-solid fa-save" />
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onBeforeMount, ref, markRaw  } from 'vue';
    import { default_values } from '@/js/modeling_utils';
    import { parseDXF } from '@/js/dxf_converter';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();

    const props = defineProps({
        levels_threejs: Object
    });

    const pickFaceIn3DModel = ref(false);
    let current_level_index = null;
    const levels = ref([]);
    const processingDxf = ref(false);

    onBeforeMount(()=>{
        levels.value = props.levels_threejs.children.map((level, index) => {
            return { id: level.name, 
                name: level.userData.cg_level_name, 
                zElevation: parseFloat(level.position.y.toFixed(2)), 
                dxfObject: level.children.length > 0 ? markRaw(level.children[0]) : null, 
                dxfName: level.children.length > 0 ? level.children[0].userData.dxf_file_name : null,
                dxfScale: level.children.length > 0 ? level.children[0].scale.x : 1.0, 
            };
        })
        .sort((a, b) => a.zElevation -b.zElevation);
    })

    defineExpose({setLevelHeight});

    const emit = defineEmits(['close-modal', 'pick-face', 'levels-confirmed']);

    const addLevel = () => {
        levels.value.push({id: null, name: '', zElevation: 0, dxfObject: null, dxfScale: 1.0 });
    };

    const removeLevel = (index) => {
        levels.value.splice(index, 1);
    };

    const removeDxf = (index) =>{
        levels.value[index].dxfObject = null;
        levels.value[index].dxfName = null;
    }

    const triggerFileInput = (index) => {
        document.getElementById(`fileInput-${index}`).click();
    };
    const handleFileUpload = async (event, index) => {
        processingDxf.value = true;
        const file = event.target.files[0];

        if (file) {
            // Validate file size (e.g., maximum size: 2 MB)
            const mb_limit = 20;
            const maxSize = mb_limit * 1024 * 1024; // 2 MB in bytes
            if (file.size > maxSize) {
                alert(t('levels.file_exceeds_limit', { limit: mb_limit, file_size: (file.size / 1024 / 1024).toFixed(2)}));
                event.target.value = ''; // Clear the file input
                processingDxf.value = false;
                return;
            }

            levels.value[index].dxfName = file.name;
            console.log("FILE-name", levels.value[index].dxfName);
            const three_js_objects = await parseDXF(file, levels.value[index].dxfScale, file.name);
            levels.value[index].dxfObject = markRaw(three_js_objects);
        }
        processingDxf.value = false;
    };

    const selectObject = (index) => {
        // Logic to leave modal and select object from main interface
        current_level_index = index;
        pickFaceIn3DModel.value = true;
        emit('pick-face');
    };

    const confirm = () => {
        emit('levels-confirmed', levels.value);
        closeModal();
    };

    const closeModal = () => {
        emit('close-modal');
    };

    function setLevelHeight(height){
        pickFaceIn3DModel.value = false;
        levels.value[current_level_index].zElevation = parseFloat(height.toFixed(2));
    }
</script>

<style scoped>
    .modal-dialog {
        border-radius: 0.5rem;
        max-width: 750px;
        min-width: 500px;
        padding: 1rem;
        max-width: none;
        max-height: 95vh;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex: 1;
    }

    .list-container {
        display: flex;
        flex-direction: column-reverse;
        flex: 1;
        overflow-y: auto;
        max-height: calc(100vh - 300px);
    }

    .level-row {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        align-items: flex-end;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-right: 0.5rem;

    }


    .form-floating {
        flex: 1;
        min-width: 100px;
    }

    /* @media (min-width: 576px) {
      .modal-dialog {
        width: 1000px;
      }
    } */

    .pick-height-btn{
       display: flex;
       flex-direction: row;
        /* height: 100%; */
        align-self: stretch;
        justify-content: center;
        align-items: center;
    }

    .file-uploaded-indicator {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-top: 0.5rem;
    }

    .max-content{
        width: max-content;
    }

    .custom-file-input {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        align-items: flex-start;
        height: 100%;
        width: 150px;
    }


    .file-label{
        max-width: 85%;
        flex: 1;
        white-space: nowrap;        /* Prevent text from wrapping */
        overflow: hidden;           /* Hide the overflowed content */
        text-overflow: ellipsis;    /* Show ellipsis (...) when text overflows */
        display: inline-block;      /* Ensure ellipsis works */
        cursor: pointer;  
        font-size: 0.8rem;
    }


    .custom-file-input button {
        width: 100%;
    }
    .custom-file-input input[type="file"] {
        display: none;
    }

    .cross-btn{
        display: flex;
        width: fit-content;
        cursor: pointer;
    }   
    .label-wrapper{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
</style>
