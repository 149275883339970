<template>
    <div class="custom-modal">
        <div class="modal-dialog bg-dark">
            <div class="modal-content bg-dark text-light">
                <div class="modal-header">
                    <h2>{{ $t('modeling_instructions.green_roof_name_modal_title') }}</h2>
                    <button class="close btn-close btn-close-white" @click="closeModal"></button>
                </div>
                <form class="modal-body" @submit.prevent="confirm">
                    <input type="text" class="form-control" v-model="name" :placeholder="$t('modeling_instructions.enter_green_roof_name')" required/>
                    <button class="btn btn-primary" type="submit"><font-awesome-icon icon="fa-solid fa-check"/></button>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const name = ref('');
const emit = defineEmits(['close-modal', 'name-confirmed']);

const confirm = () => {
    // Handle the confirmation logic here
    console.log('Name:', name.value);
    emit('name-confirmed', name.value);
};
const closeModal = () => {
        // Emit the close event
        emit('close-modal');
    };
</script>

<style scoped>
  .modal-dialog {
    border-radius: 0.5rem;
    width: 90%;
    padding: 1rem;
    max-width: none;
  }

  .modal-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  
  @media (min-width: 576px) {
    .modal-dialog {
      width: 400px;
    }
  }
</style>
