<template>
    <svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   width="220mm"
   height="120mm"
   viewBox="0 0 220 120"
   version="1.1"
   id="svg460"
   inkscape:version="0.92.3 (2405546, 2018-03-11)"
   sodipodi:docname="open_terra_logo_full.svg">
  <defs
     id="defs454">
    <clipPath
       clipPathUnits="userSpaceOnUse"
       id="clipPath152-9">
      <path
         d="M 0,0 H 1190.551 V 1683.78 H 0 Z"
         id="path150-0"
         inkscape:connector-curvature="0" />
    </clipPath>
    <clipPath
       clipPathUnits="userSpaceOnUse"
       id="clipPath40-4">
      <path
         d="M 0,0 H 1190.551 V 1683.78 H 0 Z"
         id="path38-1"
         inkscape:connector-curvature="0" />
    </clipPath>
    <clipPath
       clipPathUnits="userSpaceOnUse"
       id="clipPath26">
      <path
         d="M 0,561.26 H 1190.552 V 1683.78 H 0 Z"
         id="path24"
         inkscape:connector-curvature="0" />
    </clipPath>
  </defs>
  <g
     inkscape:label="Layer 1"
     inkscape:groupmode="layer"
     id="layer1"
     transform="translate(0,-177)">
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,-100.06586,686.04222)"
       id="g34-7">
      <g
         id="g36-2"
         clip-path="url(#clipPath40-4)">
        <g
           id="g42-5"
           transform="translate(596.3335,1277.8715)">
          <path
             d="m 0,0 h -0.123 c -4.606,0 -8.344,3.739 -8.344,8.345 0,4.606 3.738,8.344 8.344,8.344 H 0 0.089 C 4.695,16.701 8.445,12.984 8.468,8.367 8.479,3.761 4.751,0.011 0.145,0 H 0.123 Z"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path44-9"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g46-4"
           transform="translate(557.4917,1288.2301)">
          <path
             d="m 0,0 c -3.983,2.303 -5.351,7.41 -3.048,11.394 2.313,3.994 7.409,5.362 11.405,3.048 3.982,-2.303 5.351,-7.41 3.047,-11.393 C 9.858,0.367 7.054,-1.123 4.172,-1.123 2.749,-1.123 1.313,-0.768 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path48-5"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g50-1"
           transform="translate(623.7483,1291.3676)">
          <path
             d="M 0,0 C -2.313,3.983 -0.967,9.091 3.016,11.416 6.999,13.73 12.106,12.384 14.421,8.401 16.746,4.429 15.4,-0.689 11.417,-3.004 10.092,-3.783 8.646,-4.139 7.221,-4.139 4.351,-4.139 1.558,-2.659 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path52-8"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g54-1"
           transform="translate(529.1533,1316.5468)">
          <path
             d="m 0,0 v 0 c -2.314,3.983 -0.957,9.101 3.038,11.404 3.982,2.315 9.09,0.957 11.404,-3.037 H 14.43 C 16.745,4.384 15.388,-0.723 11.394,-3.037 10.08,-3.794 8.645,-4.162 7.221,-4.162 4.351,-4.162 1.546,-2.67 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path56-3"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g58-0"
           transform="translate(652.0203,1313.6982)">
          <path
             d="m 0,0 c -4.005,2.303 -5.374,7.399 -3.081,11.393 2.303,3.995 7.398,5.374 11.393,3.082 C 12.306,12.172 13.686,7.076 11.394,3.082 9.847,0.39 7.032,-1.113 4.151,-1.113 2.737,-1.113 1.302,-0.757 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path60-7"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g62-3"
           transform="translate(518.7615,1355.255)">
          <path
             d="m 0,0 c -0.011,4.606 3.727,8.345 8.334,8.356 4.606,0 8.345,-3.728 8.355,-8.345 0,-4.607 -3.727,-8.345 -8.344,-8.345 C 3.738,-8.334 0,-4.606 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path64-0"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g66-9"
           transform="translate(656.9827,1355.4772)">
          <path
             d="m 0,0 c -0.011,4.617 3.705,8.356 8.323,8.367 4.606,0.011 8.355,-3.716 8.366,-8.322 0.012,-4.607 -3.716,-8.356 -8.322,-8.367 H 8.345 C 3.75,-8.322 0.012,-4.595 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path68-8"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g70-7"
           transform="translate(656.9827,1355.4772)">
          <path
             d="M 0,0 Z"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path72-9"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g74-2"
           transform="translate(532.1348,1382.592)">
          <path
             d="m 0,0 c -3.983,2.303 -5.351,7.399 -3.048,11.393 2.303,3.995 7.41,5.363 11.393,3.06 3.994,-2.303 5.363,-7.41 3.06,-11.405 V 3.06 C 9.858,0.378 7.054,-1.124 4.172,-1.124 2.76,-1.124 1.324,-0.757 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path76-2"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g78-4"
           transform="translate(648.7825,1385.83)">
          <path
             d="M 0,0 C -2.313,3.983 -0.967,9.09 3.016,11.404 6.998,13.718 12.117,12.372 14.431,8.389 16.746,4.406 15.4,-0.701 11.416,-3.026 10.092,-3.794 8.646,-4.161 7.221,-4.161 4.351,-4.161 1.547,-2.671 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path80-7"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g82-5"
           transform="translate(554.3547,1410.9526)">
          <path
             d="m 0,0 c -2.314,3.983 -0.957,9.091 3.026,11.404 3.983,2.315 9.09,0.958 11.404,-3.037 2.315,-3.983 0.958,-9.09 -3.025,-11.404 C 10.091,-3.794 8.645,-4.161 7.22,-4.161 4.349,-4.161 1.546,-2.67 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path84-3"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g86-7"
           transform="translate(626.4863,1408.0266)">
          <path
             d="m 0,0 c -3.995,2.303 -5.363,7.399 -3.06,11.393 2.292,3.995 7.399,5.363 11.393,3.071 C 12.327,12.161 13.696,7.054 11.405,3.06 9.857,0.379 7.054,-1.113 4.16,-1.113 2.748,-1.113 1.313,-0.756 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path88-5"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g90-0"
           transform="translate(587.7554,1424.4155)">
          <path
             d="m 0,0 c -0.011,4.617 3.716,8.356 8.323,8.367 4.606,0 8.355,-3.727 8.355,-8.333 C 16.689,-4.573 12.962,-8.311 8.355,-8.322 3.739,-8.333 0,-4.606 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path92-5"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g94-6"
           transform="translate(596.2998,1303.7738)">
          <path
             d="m 0,0 h -0.089 c -4.606,0 -8.345,3.738 -8.345,8.345 0,4.606 3.739,8.344 8.345,8.344 H 0 0.057 C 4.673,16.712 8.423,12.995 8.434,8.378 8.457,3.771 4.741,0.022 0.123,0 H 0.134 0.101 Z"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path96-4"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g98-2"
           transform="translate(559.7842,1318.8611)">
          <path
             d="m 0,0 v -0.011 c -3.261,3.26 -3.261,8.545 -0.012,11.804 3.261,3.26 8.546,3.26 11.805,0.012 C 15.053,8.545 15.053,3.26 11.804,0 10.169,-1.636 8.033,-2.448 5.897,-2.448 3.76,-2.448 1.625,-1.636 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path100-2"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g102-9"
           transform="translate(621.0005,1318.9609)">
          <path
             d="M 0,0 C -3.282,3.249 -3.293,8.534 -0.045,11.805 3.193,15.076 8.478,15.098 11.75,11.849 15.021,8.601 15.043,3.316 11.794,0.045 10.158,-1.602 8.022,-2.414 5.875,-2.414 3.75,-2.414 1.624,-1.614 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path104-2"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g106-9"
           transform="translate(544.6638,1355.2659)">
          <path
             d="M 0,0 C 0,4.617 3.727,8.356 8.333,8.356 12.951,8.367 16.689,4.629 16.689,0.022 16.7,-4.584 12.961,-8.323 8.355,-8.333 3.749,-8.333 0.011,-4.606 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path108-3"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g110-8"
           transform="translate(631.0696,1355.4326)">
          <path
             d="M 0,0 C -0.011,4.606 3.705,8.356 8.323,8.367 12.929,8.389 16.678,4.662 16.689,0.056 16.701,-4.562 12.973,-8.3 8.367,-8.322 H 8.345 C 3.75,-8.322 0.012,-4.595 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path112-3"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g114-8"
           transform="translate(561.3533,1355.2881)">
          <path
             d="M 0,0 Z"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path116-9"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g118-1"
           transform="translate(559.7288,1379.9104)">
          <path
             d="m 0,0 c -3.271,3.26 -3.271,8.545 -0.023,11.805 3.26,3.26 8.545,3.271 11.806,0.023 3.259,-3.26 3.27,-8.546 0.021,-11.806 C 10.169,-1.613 8.033,-2.425 5.885,-2.425 3.76,-2.425 1.625,-1.624 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path120-8"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g122-1"
           transform="translate(620.8005,1380.0332)">
          <path
             d="M 0,0 V 0 C -3.272,3.249 -3.282,8.534 -0.034,11.805 3.215,15.064 8.5,15.087 11.772,11.838 15.031,8.578 15.054,3.304 11.793,0.033 10.169,-1.603 8.022,-2.426 5.886,-2.426 3.75,-2.426 1.624,-1.614 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path124-1"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g126-0"
           transform="translate(587.7781,1398.5138)">
          <path
             d="M 0,0 C -0.012,4.606 3.716,8.345 8.333,8.356 12.94,8.367 16.677,4.64 16.689,0.033 16.7,-4.584 12.972,-8.323 8.366,-8.334 H 8.345 C 3.749,-8.334 0.011,-4.606 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path128-2"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g130-0"
           transform="translate(587.8889,1337.1526)">
          <path
             d="m 0,0 c -0.011,4.606 3.727,8.345 8.334,8.356 4.606,0 8.355,-3.728 8.355,-8.345 C 16.7,-4.596 12.963,-8.334 8.355,-8.334 3.75,-8.345 0,-4.606 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path132-6"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g134-3"
           transform="translate(569.7087,1355.2659)">
          <path
             d="M 0,0 C -0.011,4.606 3.716,8.356 8.322,8.367 12.929,8.378 16.678,4.651 16.689,0.034 16.7,-4.573 12.961,-8.311 8.356,-8.323 H 8.345 C 3.738,-8.323 0.011,-4.606 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path136-9"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g138-3"
           transform="translate(606.0354,1355.3213)">
          <path
             d="m 0,0 v 0.034 0.011 c -0.022,4.606 3.694,8.356 8.312,8.378 4.606,0.022 8.356,-3.694 8.377,-8.312 V 0.034 0 c 0,-4.606 -3.737,-8.345 -8.344,-8.345 C 3.739,-8.345 0,-4.606 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path140-9"
             inkscape:connector-curvature="0" />
        </g>
      </g>
    </g>
    <g
       id="g142-3"
       transform="matrix(0.35277777,0,0,-0.35277777,136.02558,261.96496)">
      <path
         d="M 0,0 H -21.914 V -62.991 H -38.967 V 0 H -60.882 V 15.824 H 0 Z"
         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
         id="path144-3"
         inkscape:connector-curvature="0" />
    </g>
    <g
       transform="matrix(0.35277777,0,0,-0.35277777,-100.06586,686.04222)"
       id="g146-3">
      <g
         id="g148-3"
         clip-path="url(#clipPath152-9)">
        <g
           id="g154-9"
           transform="translate(733.0498,1169.734)">
          <path
             d="m 0,0 c 0,-1.607 -0.112,-3.214 -0.317,-4.821 h -46.017 c 1.429,-8.298 6.885,-12.867 14.859,-12.867 5.712,0 10.4,2.702 12.897,7.053 h 17.277 c -4.421,-13.255 -16.059,-21.7 -30.173,-21.7 -17.953,0 -31.536,13.9 -31.536,32.12 0,18.312 13.49,32.12 31.536,32.12 18.67,0 31.475,-14.391 31.475,-31.905 z m -45.9,6.407 h 29.464 c -2.324,7.433 -7.663,11.464 -15.039,11.464 -7.319,0 -12.515,-4.267 -14.425,-11.464"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path156-1"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g158-5"
           transform="translate(784.0588,1200.2562)">
          <path
             d="m 0,0 v -15.507 h -7.133 c -9.265,0 -12.929,-4.104 -12.929,-14.044 V -61.138 H -36.5 v 60.8 h 10.541 l 2.923,-7.448 C -18.707,-2.41 -13.213,0 -5.405,0 Z"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path160-3"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g162-2"
           transform="translate(832.7014,1200.2562)">
          <path
             d="m 0,0 v -15.507 h -7.133 c -9.265,0 -12.929,-4.104 -12.929,-14.044 V -61.138 H -36.5 v 60.8 h 10.541 l 2.923,-7.448 C -18.707,-2.41 -13.213,0 -5.404,0 Z"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path164-1"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g166-5"
           transform="translate(905.3982,1200.0096)">
          <path
             d="m 0,0 v -60.8 h -12.886 l -1.413,5.439 c -5.221,-4.571 -12.034,-7.25 -19.703,-7.25 -18.29,0 -31.894,13.808 -31.894,32.211 0,18.333 13.604,32.029 31.894,32.029 7.804,0 14.719,-2.751 19.976,-7.438 l 1.755,5.81 z m -16.111,-30.4 c 0,9.55 -7.001,16.664 -16.551,16.664 -9.55,0 -16.572,-7.134 -16.572,-16.664 0,-9.53 7.022,-16.664 16.572,-16.664 9.55,0 16.551,7.114 16.551,16.664"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path168-3"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g170-5"
           transform="translate(417.481,1201.1494)">
          <path
             d="m 0,0 c 18.067,0 30.998,-13.572 30.998,-31.63 0,-18.008 -12.931,-31.632 -30.998,-31.632 -11.183,0 -20.62,5.426 -26.062,13.955 v -34.274 h -5.516 v 82.35 h 4.007 l 1.177,-13.235 c 5.384,8.834 14.981,14.465 26.395,14.465 z m 25.43,-31.63 c 0,15.16 -10.876,26.268 -25.795,26.268 -14.798,0 -25.906,-11.282 -25.906,-26.268 0,-14.928 11.108,-26.27 25.906,-26.27 14.919,0 25.795,11.109 25.795,26.27"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path172-3"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g174-6"
           transform="translate(522.8406,1167.5837)">
          <path
             d="m 0,0 h -55.206 c 0.89,-14.204 11.257,-24.281 25.178,-24.281 9.982,0 17.965,5.046 22.475,13.539 h 5.838 c -5.551,-12.187 -15.633,-18.954 -28.313,-18.954 -17.436,0 -30.653,13.625 -30.653,31.692 0,17.946 13.217,31.57 30.653,31.57 17.79,0 30.132,-13.816 30.132,-31.424 C 0.104,1.448 0.052,0.694 0,0 m -55.111,5.152 h 49.772 c -1.329,13.945 -11.526,23.157 -24.689,23.157 -13.538,0 -23.716,-9.568 -25.083,-23.157"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path176-1"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g178-4"
           transform="translate(569.824,1201.1483)">
          <path
             d="M 0,0 C 14.594,0 23.721,-10.497 23.721,-25.913 V -62.03 h -5.517 v 34.972 c 0,13.802 -7.211,21.958 -19.454,21.958 -11.768,0 -21.542,-10.069 -21.542,-22.238 V -62.03 h -5.517 v 60.8 h 4.111 l 1.088,-12.317 C -18.719,-5.395 -10.057,0 -0.001,0 Z"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path180-9"
             inkscape:connector-curvature="0" />
        </g>
        <g
           id="g182-3"
           transform="translate(325.9297,1219.1633)">
          <path
             d="M 0,0 H -0.001 V -5.571 H 0 c 20.356,0 35.06,-15.066 35.06,-35.129 0,-20.002 -14.704,-35.008 -35.06,-35.008 -19.557,0 -33.888,13.955 -34.985,32.686 h -5.792 C -39.673,-64.983 -22.948,-81.277 0,-81.277 v 0.001 c 23.749,0 40.837,17.391 40.837,40.578 C 40.837,-17.452 23.749,0 0,0"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             id="path184-3"
             inkscape:connector-curvature="0" />
        </g>
      </g>
    </g>
  </g>
</svg>

</template>