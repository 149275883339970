<script setup>
    import { onBeforeMount } from 'vue';
    import { useStore } from 'vuex';
    import { RouterView } from 'vue-router';
    import { onMounted } from 'vue';

    const store = useStore();

    await store.dispatch('initialize');

</script>

<template>
    <RouterView></RouterView>
</template>

<style scoped>

</style>
