<template>
    <input type="checkbox" v-model="annotations" id="annotations" class="btn-check" @change="emitAnnotations"/>
    <label class="btn btn-secondary" :class="{'active': annotations}" for="annotations" :title="annotations ? $t('toolbar.annotations_off'):$t('toolbar.annotations_on')">
        <font-awesome-icon icon="fa-solid fa-font"/>
    </label>

    <input type="checkbox" v-model="measurement" id="measurement" class="btn-check" @change="emitMeasurement"/>
    <label class="btn btn-secondary" :class="{'active': measurement}" for="measurement" :title="measurement ? $t('toolbar.measurement_off'):$t('toolbar.measurement_on')">
        <font-awesome-icon icon="fa-solid fa-ruler"/>
    </label>


    <button class="btn btn-danger" @click="emitExitPlanView" :title="$t('toolbar.exit_plan')"><font-awesome-icon icon="fa-solid fa-xmark"/></button>


</template>

<script setup>
    import { onMounted } from "vue";
    import {ref} from "vue";
    import {useStore} from "vuex";

    const emit = defineEmits(['exitPlanView','annotationsChange','measurementChange']);

    const store = useStore();

    const annotations =ref(true);
    const measurement =ref(false);

    onMounted(()=>{

    })

    function emitExitPlanView(){
        emit('exitPlanView');
    }

    function emitAnnotations() {
        emit('annotationsChange', annotations.value)
    }

    function emitMeasurement() {
        emit('measurementChange', measurement.value)
    }

</script>
  
<style scoped>
    .active {
        border-color: #0a5bb3; /* Slightly darker border for depth */
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5); /* A subtle glow effect */
        opacity: 1;
    }

</style>
  