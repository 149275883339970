<template>
    <div id="plan-title-block" class="title-block-container">
        <!-- Title Block Header -->
        <div class="border-bottom mb-3 pb-2">
            <h3 class="text-center">{{store.state.current_project.name}}</h3>
        </div>

        <!-- Project Information -->
        <div class="project-info mb-3">
            <p><strong>{{$t('welcome_page.new_project_modal.p_location')}}: </strong> {{ store.state.current_project.location }}</p>
            <p><strong>{{$t('welcome_page.new_project_modal.p_start')}}: </strong> {{ formatDateToEuropean(store.state.current_project.start_date) }}</p>
            <p><strong>{{$t('welcome_page.new_project_modal.p_end')}}: </strong> {{ formatDateToEuropean(store.state.current_project.end_date) }}</p>
        </div>


        <!-- Collapsible Section 1: Green Roof Elements -->
        <div>
        <div class="section-header" @click="toggleSection('section1')">
            <h5>{{$t('plan.green_roof_section_label')}}</h5>
            <font-awesome-icon :icon="openSections.section1 ? 'fa-chevron-down' : 'fa-chevron-right'" />
        </div>
        <div v-if="openSections.section1">
            <ul class="list-group mb-3">
            <li v-for="(item, index) in section1Items" :key="index" class="list-group-item">
                <div class="d-flex align-items-center flex-wrap">
                    <button 
                        class="btn btn-light me-1"
                        @click="toggleTable('section1', index)">
                        {{ item.title }}
                        <font-awesome-icon icon='fa-chevron-down' />
                    </button>
                </div>
                <div v-if="openTables['section1'][index]">
                    <!-- <span>{{ item.description }}</span> -->
                    <span>{{ item.type }}</span>
                    <table class="table table-bordered mt-2">
                    <thead>
                        <tr>
                        <th><strong>{{$t('generic.material')}}</strong></th>
                        <th><strong>{{$t('type_forms.layer_form.depth')}}</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(layer, layerIndex) in item.layers" :key="layerIndex">
                        <td>{{ getMaterialName(layer.material) }}</td>
                        <td>{{ layer.thickness }}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>

            </li>
            </ul>
        </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, reactive, ref, watch } from 'vue';
    import { useStore } from 'vuex';

    const store = useStore();

    const props = defineProps({
    areaData: Array,
    });



    const openSections = ref({
        section1: true,
        section2: false,
    });

    const openTables = ref({
        section1: [],
        section2: [],
    });

    const section1Items = ref([]);
    const materialCache = ref({});

    const fetchMaterialInfo = (materialId) => {
    const materials = store.state.climagruen_types.materials;
    if (!materialCache.value[materialId]) {
        const material = materials.find(m => m.id === materialId);
        if (material) {
        materialCache.value[materialId] = material.common_name;
        } else {
        console.error('Material not found in store:', materialId);
        materialCache.value[materialId] = 'Unknown';
        }
    }
    };

    const getLayers = (typeDetails) => {
    const layers = [];
    for (let i = 1; i <= 10; i++) {
        const depth = typeDetails[`layer${i}_depth`];
        const material = typeDetails[`layer${i}_material`];
        if (depth !== null && material !== null) {
        layers.push({ thickness: depth, material });
        } else {
        break;
        }
    }
    return layers;
    };

    const getMaterialName = (materialId) => {
    return materialCache.value[materialId] || 'Loading...';
    };

    const fetchSection1Data = () => {
    section1Items.value = props.areaData.map((response, index) => ({
        title: response.name,
        type: response.type_details.common_name,
        description: response.type_details.description,
        layers: getLayers(response.type_details),
    }));
    const materialIds = [];
    section1Items.value.forEach(item => {
        item.layers.forEach(layer => materialIds.push(layer.material));
    });
    materialIds.forEach(id => {
        fetchMaterialInfo(id);
    });
    };

    // onMounted(() => {
    // console.log('Component mounted');
    // fetchSection1Data();
    // });

    const toggleSection = (section) => {
    openSections.value[section] = !openSections.value[section];
    };

    const toggleTable = (section, index) => {
    openTables.value[section][index] = !openTables.value[section][index];
    };

    watch(() => props.areaData, (newVal) => {
        if (newVal) {
            fetchSection1Data();
        }
    }, { immediate: true });

    const formatDateToEuropean = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };
</script>

<style scoped>
    .title-block-container {
        position: absolute;
        top: 1rem;
        right: 1rem;
        bottom: 1rem;
        width: 350px;
        border: 1px solid #dee2e6;
        padding: 15px;
        background-color: #f8f9fa;
        overflow-y: auto;
        z-index: 100;
    }
    .project-info {
        padding: 10px;
        background-color: #e9ecef;
        border: 1px solid #dee2e6;
        border-radius: 5px;
        margin-bottom: 10px;
    }
    
    .section-header {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background-color: #e9ecef;
        border: 1px solid #dee2e6;
        border-radius: 5px;
        margin-bottom: 10px;
    }
    
    .section-header h5 {
        margin: 0;
        display: flex;
        align-items: center;
    }
    
    .section-header i {
        margin-left: 10px;
    }
    
    .table-collapsible {
        cursor: pointer;
    }
    
    .table-collapsible:hover {
        background-color: #f8f9fa;
    }
</style>