<template>
    <div class="container d-flex flex-column h-calc overflow-hidden">
        <div class="row mb-3">
            <div class="col-md-6">
                <div class="label-wrapper">
                    <div class="list-label">
                        {{ $t('welcome_page.project_list_label') }}
                    </div>
                    <button class="btn btn-light btn-fit-content" :title="$t('welcome_page.create_new_project')" @click="showNewProjectModal = true">
                        <font-awesome-icon icon="fa-solid fa-plus"/>
                    </button>
                </div>
            </div>
        </div>

        <div class="row overflow-hidden">
            <div class="col-md-6 overflow-hidden h-100">
                <ul class="list-group list-group-flush round-border overflow-auto h-100">
                    <li v-for="(project, index) in store.state.projects" :key="project.id" class="list-group-item bg-dark text-white">
                        <div @click="selectProject(project.id)" class="d-flex justify-content-between align-items-center">
                            <span v-if="selectedProjectId === project.id"><strong>{{ project.name }}</strong></span>
                            <span v-else>{{ project.name }}</span>
                            <div v-if="selectedProjectId === project.id" class="button-duo">
                                <button class="btn custom-color-danger" :title="$t('welcome_page.delete_project')" @click="deleteProject(project.id)">
                                    <font-awesome-icon icon="fa-solid fa-trash"/>
                                </button>
                                <button class="btn custom-color" :title="$t('welcome_page.open_project')" @click="openProject(project.id)">
                                    <font-awesome-icon icon="fa-solid fa-arrow-right"/>
                                </button>
                            </div>
                        </div>
                        <div v-if="selectedProjectId === project.id" class="mt-2">
                            <p>{{ project.location }}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="selectedProject && selectedProjectId !== null" class="col-md-6 d-none d-md-block">
                <div class="card bg-dark text-white">
                    <div class="card-body">
                        <h5 class="card-title">{{ selectedProject.name }}</h5>
                        <p class="card-text">{{ selectedProject.location }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Create New Project Modal -->
    <NewProjectModal :showModal="showNewProjectModal" @update:showModal="showNewProjectModal = $event" />
    
    <!-- Success and Error messages -->
    <SuccessNotification v-if="success"/>
    <ErrorNotification v-if="failure" :message="error_message"/>
</template>
  
<script setup>
    import axios from 'axios';
    import { ref, computed } from 'vue';
    import { useStore } from 'vuex';
    import {api_endpoints} from '../js/api_endpoints.js';
    import NewProjectModal from './modals/project/NewProjectModal.vue';
    import SuccessNotification from './notifications/SuccessNotification.vue';
    import ErrorNotification from './notifications/ErrorNotification.vue';
    import { useI18n } from 'vue-i18n';
    import { useRouter } from 'vue-router';

    const { t } =  useI18n();
    const router = useRouter();
    const store = useStore();
    const selectedProjectId = ref(null);
    const showNewProjectModal = ref(false);

    const success = ref(false);
    const failure = ref(false);
    const error_message = ref("");
    
    const selectProject = (id) => {
        if (selectedProjectId.value === id) {
            selectedProjectId.value = null
        } else {
            selectedProjectId.value = id
        }
    }
    
    const selectedProject = computed(() => {
        return store.state.projects.find(project => project.id === selectedProjectId.value);
    })

    function openProject(id){
        store.commit('updateProjectInfo', id);
        router.push({name: 'canvas', params: {id: id}})
    }

    function deleteProject(id){
        if (window.confirm(t('welcome_page.delete_project_confirmation'))) {
            const token = window.localStorage.getItem('climagruen_token');

            axios.delete(api_endpoints.projects_id(id), {headers: {'Authorization': `Bearer ${token}`}})
                .then((response)=>{
                    console.log(response.data);
                    updateStore();
                    showSuccess();
                })
                .catch((error) => {
                    console.log(error);
                    showError(error);
            });
        }
    }

    function updateStore(){
        store.dispatch('getProjects');
    }

    function showSuccess(){
        success.value = true;
        setTimeout(() => {
            success.value = false;
        }, 2500);
    }

    function showError(message){
        failure.value = true;
        error_message.value = message;
        setTimeout(() => {
            failure.value = false;
            error_message.value = "";
        }, 2500);
    }
</script>
  
<style scoped>

    .list-group-item {
        cursor: pointer;
    }
    .round-border{
        border-radius: 0.5rem;
    }
    .list-label{
        font-size: 2rem;
        font-weight: thin;
        color: white;
    }
    .button-duo{
        display: flex;
        gap: 1rem;
    }
    .label-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .btn-fit-content{
        width: max-content;
        height: max-content;
    }

    .custom-color{
        background-color: #407f85;
    }

    .custom-color-danger{
        background-color: #a03939;
    }

    .h-calc{
        box-sizing: border-box;
        max-height: calc(100% - 15vh);
    }
    .overflow-hidden{
        overflow: hidden;
    }
    .overflow-auto{
        overflow: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .h-100{
        max-height: 100%;
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .overflow-auto::-webkit-scrollbar {
        display: none;
    }


</style>
  