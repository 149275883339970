<template>
    <div class="control-panel" @mouseover="hover = true" @mouseleave="hover = false">
        <div class="control-item">
            {{ $t('toolbar.rotation') }}
            <span class="mouse-instruction">
                {{ $t('toolbar.shift') }}
            </span>
            +
            <span class="mouse-instruction">
                <font-awesome-icon icon="fa-solid fa-mouse"></font-awesome-icon>
                {{ $t('toolbar.left_click') }}
            </span>
        </div>
        <div class="control-item">
            {{ $t('toolbar.panning') }}
            <span class="mouse-instruction">
                <font-awesome-icon icon="fa-solid fa-mouse"></font-awesome-icon>
                {{ $t('toolbar.right_click') }}
            </span>
        </div>
        <div class="control-item">
            {{ $t('toolbar.zooming') }}
            <span class="mouse-instruction">
                <font-awesome-icon icon="fa-solid fa-mouse"></font-awesome-icon>
            {{ $t('toolbar.middle_scroll') }}
            </span>
         </div>
        <!-- <div class="control-item">
            {{ $t('toolbar.selection') }}
            <span class="mouse-instruction">
                <font-awesome-icon icon="fa-solid fa-mouse"></font-awesome-icon>
                {{ $t('toolbar.right_click') }}
            </span>
        </div> -->
        <div class="control-item">
            {{ $t('toolbar.exit_function') }}
            <span class="keyboard-shortcut">Esc</span>
        </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue'
  
  const hover = ref(false)
  </script>
  
<style scoped>
    .control-panel {
        position: absolute;
        bottom: 0;
        left: 0;
        width: max-content;
        color: rgba(255, 255, 255, 0.4); /* Semi-transparent text color */
        padding: 0.5rem;
        font-size: 0.75rem;
        text-align: center;
        display: flex;
        justify-content: flex-start;
        gap: 1.5rem;
        margin-bottom: 1rem;
        margin-left: 1rem;
    }

    .control-panel:hover {
        color: rgba(255, 255, 255, 0.8);
    }

    .control-item {
        display: flex;
        align-items: center;
        position: relative;
        gap: 0.5rem;
    }

    .mouse-instruction, .keyboard-shortcut {
        padding: 0.2rem;
        border: 1px solid rgba(255, 255, 255, 0.4);;
        border-radius: 0.2rem;
        font-family: 'Courier New', Courier, monospace;
    }

    .keyboard-shortcut {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .mouse-instruction {
        background-color: rgba(255, 255, 255, 0.1);
    }  
</style>
