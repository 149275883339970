<template>
    <form id="type-form" @submit.prevent="submitForm" class="bg-dark text-white p-3">
        

        <div class="row">
                <div class="col mb-3">
                    <label for="common_name" class="form-label">{{ $t('type_forms.common_name') }}</label>
                    <input type="text" id="common_name" v-model="form_helper.common_name" :placeholder="$t('type_forms.placeholder_name')" class="form-control">
                </div>

                <div class="col mb-3">
                    <label for="description" class="form-label">{{ $t('type_forms.description') }}</label>
                    <input type="text" id="description" v-model="form_helper.description" :placeholder="$t('type_forms.placeholder_name')" class="form-control">
                </div>
        </div>

        <div class="row background">
            <div class="label-wrapper">   
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" v-model="form_helper.enable">
                </div> 
                <span v-if="form_helper.enable"><strong>{{$t('type_forms.disable_type')}}</strong></span>
                <span v-else> <strong>{{$t('type_forms.enable_type')}}</strong><i class="align-end">{{$t('type_forms.enable_type_note')}}</i></span>
            </div>
        </div>

        <!-- FIRST LAYER -->
        <div class="row background">
            <div class="col mb-3 gap">
                <div class="label-wrapper">   
                    <h5 class="layer-label">{{ $t('type_forms.layer_form.layer',{ layer_no: "1" }) }}</h5>
                    <span class="question-mark-icon" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                        <QuestionMark/>
                    </span>
                </div>
                <div v-if="showTooltip" class="tooltip bs-tooltip-top" role="tooltip">
                        <img class="explanatory-img" :src="image" alt="explanatory_image">
                </div>
                <div class="label-wrapper">
                    <span><i>{{ $t('type_forms.layer_form.flex_label') }}</i></span>
                    <span><i><strong>{{ $t('generic.material') }}</strong></i></span>
                    <input class="form-check-input" type="checkbox" v-model="form_helper.layer1_material_editable">
                    <span><i><strong>{{ $t('generic.depth') }}</strong></i></span>
                    <input class="form-check-input" type="checkbox" v-model="form_helper.layer1_depth_editable">
                </div>  
                <div class="form-floating bottom-aligned" data-bs-theme="dark">
                    <select id="type-select" class="form-select"  v-model="form_helper.layer1_material">
                        <option v-for="cg_type in store.state.climagruen_types.materials" :value="cg_type.id">{{ cg_type.common_name }}</option>
                    </select>
                    <label for="type-select">{{ $t('type_forms.layer_form.select_material') }}</label>
                </div>
            </div>

            <div class="col mb-3 bottom-aligned">
                <div class="label-wrapper">
                    <label for="depth" class="form-label">{{ $t('type_forms.layer_form.depth') }}</label>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" :title="$t('type_forms.layer_form.flex_depth_tooltip')" :checked="form_helper.flexible_layer === 1" @change="setLayerFlex(1)">
                    </div>  
                </div>  
                <input type="number" step="any" id="depth" v-model="form_helper.layer1_depth" :placeholder="$t('type_forms.placeholder_number')" class="form-control">
            </div>
        </div>

        <!-- ALL FOLLOWING LAYERs (MAX max_nof_layers) -->
        <template v-for="(item, index) in layer_helper" :key="index">
            <div class="row background" v-if="item.material!=null || index+1 < layer_count">
                <div class="col mb-3 gap">
                    <div class="label-wrapper">                
                        <h5 class="layer-label">{{ $t('type_forms.layer_form.layer',{ layer_no: index + 2 }) }}</h5>
                        <button type="button" :id="index" class="btn btn-danger small" @click="removeLayer" :title="$t('type_forms.layer_form.remove')"><font-awesome-icon icon="fa-solid fa-minus"/></button>
                    </div>

                    <div class="label-wrapper">
                        <span><i>{{ $t('type_forms.layer_form.flex_label') }}</i></span>
                        <span><i><strong>{{ $t('generic.material') }}</strong></i></span>
                        <input class="form-check-input" type="checkbox" v-model="layer_helper[index].material_editable">
                        <span><i><strong>{{ $t('generic.depth') }}</strong></i></span>
                        <input class="form-check-input" type="checkbox" v-model="layer_helper[index].depth_editable">
                    </div> 

                    <div class="form-floating bottom-aligned" data-bs-theme="dark">
                        <select :id="`type-select-${index+2}`" class="form-select" v-model="layer_helper[index].material">
                            <option v-for="cg_type in store.state.climagruen_types.materials" :value="cg_type.id">{{ cg_type.common_name }}</option>
                        </select>
                        <label :for="`type-select-${index+2}`">{{ $t('type_forms.layer_form.select_material') }}</label>
                    </div>
                </div>

                <div class="col mb-3 bottom-aligned">
                    <div class="label-wrapper">
                        <label :for="`depth-${index+2}`" class="form-label">{{ $t('type_forms.layer_form.depth') }}</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" :title="$t('type_forms.layer_form.flex_depth_tooltip')" :checked="form_helper.flexible_layer === index+2" @change="setLayerFlex(index+2)">
                        </div> 
                    </div>
                    
                    <input type="number" step="any" :id="`depth-${index+2}`" :placeholder="$t('type_forms.placeholder_number')" class="form-control" v-model="layer_helper[index].depth">
                </div>
            </div>
        </template>



        <button type="button" class="btn btn-secondary mb-3" @click="addLayer" :title="$t('type_forms.layer_form.add')"><font-awesome-icon icon="fa-solid fa-plus"/></button>
        <br/>

        <button v-if="create_new" type="submit" class="btn btn-primary">{{ $t('type_forms.create_type') }} </button>
        <div v-else class="button-container">
            <button type="submit" class="btn btn-info">{{ $t('type_forms.modify_type') }}</button>
            <!-- <button type="button" class="btn btn-danger" @click="deleteType">{{ $t('type_forms.delete_type') }}</button> -->
        </div>
    </form>
</template>

<script setup>
    import axios from 'axios';
    import {api_endpoints} from '../../js/api_endpoints.js';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import {onMounted, ref, watch } from 'vue';
    import QuestionMark from '../icons/Questionmark.vue';

    const store = useStore();

    const { t } =  useI18n();

    const props = defineProps({
                                form_data: Object,
                                create_new: Boolean,
                                image: String,
                                
    });

    const emit = defineEmits(['success', 'failure']);

    const showTooltip = ref(false);
    const layer_count = ref(1);
    const layer_helper = ref(null);
    const form_helper = ref({common_name: null, description: null, flexible_layer: null,
                                layer1_material: null, layer1_depth: null
    });

    onMounted(()=>{
        initializeFormHelpers();

    });

    watch(()=>props.form_data, ()=>{
        console.log("form_changed");
        initializeFormHelpers();
    })

    function initializeFormHelpers(){
        form_helper.value = {   enable: props.form_data.enable,
                                common_name: props.form_data.common_name, description: props.form_data.description, flexible_layer: props.form_data.flexible_layer,
                                layer1_material: props.form_data.layer1_material, layer1_depth: props.form_data.layer1_depth,
                                layer1_material_editable: props.form_data.layer1_material_editable, layer1_depth_editable: props.form_data.layer1_depth_editable
        }
        layer_helper.value = [
            {
                material: props.form_data.layer2_material,
                depth: props.form_data.layer2_depth,
                material_editable: props.form_data.layer2_material_editable,
                depth_editable: props.form_data.layer2_depth_editable
            },
            {
                material: props.form_data.layer3_material,
                depth: props.form_data.layer3_depth,
                material_editable: props.form_data.layer3_material_editable,
                depth_editable: props.form_data.layer3_depth_editable
            },
            {
                material: props.form_data.layer4_material,
                depth: props.form_data.layer4_depth,
                material_editable: props.form_data.layer4_material_editable,
                depth_editable: props.form_data.layer4_depth_editable
            },
            {
                material: props.form_data.layer5_material,
                depth: props.form_data.layer5_depth,
                material_editable: props.form_data.layer5_material_editable,
                depth_editable: props.form_data.layer5_depth_editable
            },
            {
                material: props.form_data.layer6_material,
                depth: props.form_data.layer6_depth,
                material_editable: props.form_data.layer6_material_editable,
                depth_editable: props.form_data.layer6_depth_editable
            },
            {
                material: props.form_data.layer7_material,
                depth: props.form_data.layer7_depth,
                material_editable: props.form_data.layer7_material_editable,
                depth_editable: props.form_data.layer7_depth_editable
            },
            {
                material: props.form_data.layer8_material,
                depth: props.form_data.layer8_depth,
                material_editable: props.form_data.layer8_material_editable,
                depth_editable: props.form_data.layer8_depth_editable
            },
            {
                material: props.form_data.layer9_material,
                depth: props.form_data.layer9_depth,
                material_editable: props.form_data.layer9_material_editable,
                depth_editable: props.form_data.layer9_depth_editable
            },
            {
                material: props.form_data.layer10_material,
                depth: props.form_data.layer10_depth,
                material_editable: props.form_data.layer10_material_editable,
                depth_editable: props.form_data.layer10_depth_editable
            }
        ];
        const [count, corrupted] = countNonNullMaterials(layer_helper.value);
        if(corrupted){
            console.log("This Layer Type is corrupted!")
            emitFailure("Layer structure is corrupted! Delete this Type and create a new one with the desired structure.");
        }
        layer_count.value = count + 1;
                
    }
    function submitForm(){
        if(props.create_new){
            createType();
        }else{
            modifyType();
        }
    }
    function createType() {
        const token = window.localStorage.getItem('climagruen_token');

        const new_layer_type = bakeLayerTypeObject();

        axios.post(api_endpoints.layers, new_layer_type, {headers: {'Authorization': `Bearer ${token}`}})
            .then(()=>{
                updateStore();
                emitSuccess();
                console.log('Form submitted:', new_layer_type);
            })
            .catch((error) => {
                console.log(error);
                emitFailure(error);
            });


    }

    function modifyType(){
        if (window.confirm(t('type_forms.modify_confirmation'))) {
            const token = window.localStorage.getItem('climagruen_token');

            const new_layer_type = bakeLayerTypeObject();

            axios.put(api_endpoints.layers_id(props.form_data.id), new_layer_type, {headers: {'Authorization': `Bearer ${token}`}})
                .then(()=>{
                    updateStore();
                    emitSuccess();
                    console.log('Form submitted:', new_layer_type);
                })
                .catch((error) => {
                    console.log(error);
                    emitFailure(error);
                });
        }
    }

    function deleteType(){
        if (window.confirm(t('type_forms.delete_confirmation'))) {
            const token = window.localStorage.getItem('climagruen_token');

            axios.delete(api_endpoints.layers_id(props.form_data.id), {headers: {'Authorization': `Bearer ${token}`}})
                .then((response)=>{
                    console.log(response.data);
                    updateStore();
                    emitSuccess();
                    document.getElementById("type-form").reset();
                })
                .catch((error) => {
                    console.log(error);
                    emitFailure(error);
                });
        }
    }
    
    function addLayer(){
        if(layer_count.value < layer_helper.value.length + 1){
            ++layer_count.value;
            console.log(layer_count.value);
        }
    }

    function removeLayer(event){
        const layer_index = parseInt(event.currentTarget.id);
        layer_helper.value.splice(layer_index, 1); 
        --layer_count.value;

        layer_helper.value.push({material: null, depth: null, material_editable: false, depth_editable: false});
        console.log(layer_helper.value);
        console.log(layer_count.value);
    }

    function setLayerFlex(index){
        console.log(form_helper.value);
        console.log(index);
        form_helper.value.flexible_layer = index;
    }

    function updateStore(){
        store.dispatch('getLayerTypes')
    }

    function bakeLayerTypeObject(){
        const new_layer_type = {
            enable: form_helper.value.enable,
            common_name: form_helper.value.common_name,
            description: form_helper.value.description,
            flexible_layer: form_helper.value.flexible_layer,
            layer1_depth: form_helper.value.layer1_depth,
            layer1_material: form_helper.value.layer1_material,
            layer1_material_editable: form_helper.value.layer1_material_editable,
            layer1_depth_editable: form_helper.value.layer1_depth_editable,
            layer2_depth: layer_helper.value[0].depth,
            layer2_material: layer_helper.value[0].material,
            layer2_material_editable: layer_helper.value[0].material_editable,
            layer2_depth_editable: layer_helper.value[0].depth_editable,
            layer3_depth: layer_helper.value[1].depth,
            layer3_material: layer_helper.value[1].material,
            layer3_material_editable: layer_helper.value[1].material_editable,
            layer3_depth_editable: layer_helper.value[1].depth_editable,
            layer4_depth: layer_helper.value[2].depth,
            layer4_material: layer_helper.value[2].material,
            layer4_material_editable: layer_helper.value[2].material_editable,
            layer4_depth_editable: layer_helper.value[2].depth_editable,
            layer5_depth: layer_helper.value[3].depth,
            layer5_material: layer_helper.value[3].material,
            layer5_material_editable: layer_helper.value[3].material_editable,
            layer5_depth_editable: layer_helper.value[3].depth_editable,
            layer6_depth: layer_helper.value[4].depth,
            layer6_material: layer_helper.value[4].material,
            layer6_material_editable: layer_helper.value[4].material_editable,
            layer6_depth_editable: layer_helper.value[4].depth_editable,
            layer7_depth: layer_helper.value[5].depth,
            layer7_material: layer_helper.value[5].material,
            layer7_material_editable: layer_helper.value[5].material_editable,
            layer7_depth_editable: layer_helper.value[5].depth_editable,
            layer8_depth: layer_helper.value[6].depth,
            layer8_material: layer_helper.value[6].material,
            layer8_material_editable: layer_helper.value[6].material_editable,
            layer8_depth_editable: layer_helper.value[6].depth_editable,
            layer9_depth: layer_helper.value[7].depth,
            layer9_material: layer_helper.value[7].material,
            layer9_material_editable: layer_helper.value[7].material_editable,
            layer9_depth_editable: layer_helper.value[7].depth_editable,
            layer10_depth: layer_helper.value[8].depth,
            layer10_material: layer_helper.value[8].material,
            layer10_material_editable: layer_helper.value[8].material_editable,
            layer10_depth_editable: layer_helper.value[8].depth_editable,
        };
        new_layer_type.total_depth = calculateTotalDepth(new_layer_type);

        return new_layer_type;
    }

    function countNonNullMaterials(layers) {
        let count = 0;
        let encounteredNull = false;
        let corrupted = false;

        for (let i = 0; i < layers.length; i++) {
            if (layers[i].material === null) {
                encounteredNull = true;
            } else if (encounteredNull) {
                corrupted = true;
            } else {
                count++;
            }
        }

        return [count, corrupted];
    }

    function calculateTotalDepth(layerObject){
        let sum = 0;
        for (let i = 1; i <= 10; i++) {
            let depth = layerObject[`layer${i}_depth`];
            if (depth === null) {
                break;
            }
            sum += depth;
        }
        return sum;
    }

    function emitSuccess(){
        emit('success');
    }

    function emitFailure(error){
        let message;
        try{
            message = error.request.responseText;
        }
        catch(error){
            console.log(error)
            message = "";
        }
        console.log(message);
        emit('failure', message);
    }
</script>

<style scoped>
    .bg-dark {
        border-radius: 5px;
    }

    .button-container{
        display: flex;
        gap: 1rem;
    }

    .explanatory-img{
        max-height: 300px;
        width: auto;
    }

    .question-mark-icon {
        cursor: pointer;
        position: relative;
        display: inline-block;
    }

    .tooltip {
        position: absolute;
        transform: translateX(50%);
        z-index: 1000;
        display: block;
        visibility: visible;
        opacity: 1;
        padding: 0; /* Removes default padding */
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

.label-tip{
    display: flex;
    gap: 0.5rem;
}

.label-wrapper{
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
}

.layer-label{
    font-weight: 700;
}

.gap{
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.bottom-aligned{
    margin-top: auto ;
}

.small{
    padding: 0 5px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    height: 1rem;
}

.background{
    background-color: #343a40;
    border-radius: 5px;
    margin: 0.5rem;	
    padding: 0.2rem;
}
h5{
    margin:0;
}

.align-end{
    display: flex;
    margin-left: auto;
}

</style>