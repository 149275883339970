// dxf_converter.js

// Import necessary modules
import * as THREE from 'three';
import DxfParser from 'dxf-parser';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import helvetikerFont from 'three/examples/fonts/helvetiker_regular.typeface.json';
import { v4 as uuidv4 } from 'uuid';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { objectTypes } from './modeling_utils';


const colors = {
    lines: 'black',
    lwPolylines: 'black',
    polylines: 'black',
    circles: 'black',
    arcs: 'black',
    ellipses: 'black',
    splines: 'black',
    solids: 'black',
    text: 'black',
    dimensions: 'blue', // Added color for DIMENSION entities
    dimensionsText: 'red', // Added color for DIMENSION text
    background: 'white'
}

let cachedFont = null;

const z_fighting_offset = 0.001; // Offset to prevent z-fighting between the DXF group and the background plane


/**
 * Parses a DXF input (File, ArrayBuffer, or string) and returns a THREE.Group containing the converted objects.
 *
 * @param {File | ArrayBuffer | string} input - The DXF data as a File, ArrayBuffer, or string.
 * @returns {Promise<THREE.Group>} - A promise that resolves to a THREE.Group with all converted objects.
 */
export async function parseDXF(input, scale, file_name) {
    let dxfText;
    // Determine the type of input and convert to text if necessary
    if (input instanceof File) {
        dxfText = await readFileAsText(input);
    } else if (input instanceof ArrayBuffer) {
        dxfText = arrayBufferToString(input);
    } else if (typeof input === 'string') {
        // If input is a string, assume it's the DXF text
        dxfText = input;
    } else {
        throw new Error('Unsupported input type. Provide a File, ArrayBuffer, or string.');
    }

    // Parse the DXF text
    const parser = new DxfParser();
    let dxf;
    try {
        dxf = parser.parseSync(dxfText);
    } catch (error) {
        console.error('Error parsing DXF:', error);
        throw error;
    }

    // Create a group to hold all objects
    const dxfGroup = new THREE.Group();

    // Ensure the font is loaded
    if (!cachedFont) {
        await initializeFont();
        if (!cachedFont) {
            throw new Error('Font could not be loaded.');
        }
    }
    const font = cachedFont

    // Iterate over each entity and convert to Three.js object
    for (const entity of dxf.entities) {
        let object = null;
        console.log(entity.type);
        switch (entity.type) {
            case 'INSERT':
                object = createInsert(entity, dxf.blocks, font);
                break;
            case 'LINE':
                object = createLine(entity);
                break;
            case 'LWPOLYLINE':
                object = createLwPolyline(entity);
                break;
            case 'POLYLINE':
                object = createPolyline(entity);
                break;
            case 'CIRCLE':
                object = createCircle(entity);
                break;
            case 'ARC':
                object = createArc(entity);
                break;
            case 'ELLIPSE':
                object = createEllipse(entity);
                break;
            case 'SPLINE':
                object = createSpline(entity);
                break;
            // case 'TEXT':
            //     object = createText(entity, font);
            //     break;
            // case 'MTEXT': // Added support for MTEXT
            //     object = createMText(entity, font);
            //     break;
            // case 'DIMENSION': // Added support for DIMENSION
            //     object = createDimension(entity, font);
            //     break;
            case 'SOLID':
                object = createSolid(entity);
                break;
            // Add more entity types as needed
            default:
                console.warn(`Unsupported entity type: ${entity.type}`);
        }

        if (object) {
            dxfGroup.add(object);
        }
    }

    createBackground(dxfGroup);

    dxfGroup.name = uuidv4();
    dxfGroup.userData.dxf_file_name = file_name

    return dxfGroup;
}

export function scaleAndCenterGroup(group, scale){
    scaleGroup(group, scale);
    centerGroup(group);
    // offset background plane to be below the DXF group
    const plane_position = group.children[group.children.length-1].position;
    plane_position.copy(new THREE.Vector3(plane_position.x, plane_position.y, plane_position.z - z_fighting_offset/scale));
}

function createBackground(group){
    // Compute bounding box and center
    const boundingBox = new THREE.Box3().setFromObject(group);
    const size = boundingBox.getSize(new THREE.Vector3());
    const center = boundingBox.getCenter(new THREE.Vector3());

    // Create the background plane with the same dimensions as the bounding box
    const planeWidth = size.x + size.x * 0.05; // Add some extra width for the background plane
    const planeHeight = size.y + size.y * 0.05; // Assuming the plane lies on the XY plane

    const planeGeometry = new THREE.PlaneGeometry(planeWidth, planeHeight);
    const planeMaterial = new THREE.MeshBasicMaterial({
        color: colors.background,
        transparent: false,
        // opacity: 0.85,
        side: THREE.DoubleSide,
    }); 
    const backgroundPlane = new THREE.Mesh(planeGeometry, planeMaterial);

    // Adjust the Y position to place the plane slightly below the DXF group
    backgroundPlane.position.set(center.x, center.y, center.z);
    // transformToXZPlane(backgroundPlane);
    backgroundPlane.userData.cg_type = objectTypes.level_dxf_background;

    group.add(backgroundPlane);
};

/**
 * Reads a File object as text.
 *
 * @param {File} file - The file to read.
 * @returns {Promise<string>} - A promise that resolves to the file content as a string.
 */
function readFileAsText(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = () => {
            reject(new Error('Error reading file.'));
        };
        reader.readAsText(file);
    });
}

/**
 * Converts an ArrayBuffer to a string.
 *
 * @param {ArrayBuffer} buffer - The ArrayBuffer to convert.
 * @returns {string} - The resulting string.
 */
function arrayBufferToString(buffer) {
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(buffer);
}


async function initializeFont() {
    if (!cachedFont) {
        try {
            const loader = new FontLoader();
            cachedFont = loader.parse(helvetikerFont);
        } catch (error) {
            console.error('Failed to load font:', error);
        }
    }
}

/**
 * Centers the given THREE.Group based on its bounding box.
 *
 * @param {THREE.Group} group - The group to center.
 */
function centerGroup(group) {
    // Compute the bounding box of the group
    const boundingBox = new THREE.Box3().setFromObject(group);

    // Calculate the center of the bounding box
    const center = boundingBox.getCenter(new THREE.Vector3());
    console.log("Center", center);
    console.log("BBOX", boundingBox);
    console.log("Pos. Vorher", group.position);

    // Shift the group's position to center it
    group.position.x += (- center.x);
    group.position.y += (- center.y);
    group.position.z += (- center.z);

    console.log("Pos. Nachher", group.position);
    
}



/**
 * Scales the given THREE.Group by the specified factor.
 * 
 * @param {THREE.Group} group - The group to scale.
 * @param {number} scale - The scale factor. 
*/
function scaleGroup(group, scale) {
    group.scale.set(scale, scale, scale);
}

/**
 * Creates a Three.js Group from a DXF INSERT entity.
 *
 * @param {Object} entity - The DXF INSERT entity.
 * @param {Object} blocks - The blocks dictionary from the parsed DXF.
 * @param {THREE.Font} font - The loaded font for TEXT entities.
 * @returns {THREE.Group | null} - The resulting Three.js Group object representing the block, or null if block not found.
 */
function createInsert(entity, blocks, font) {
    const blockName = entity.name;
    
    // Retrieve the block definition
    const block = blocks[blockName];
    if (!block) {
        console.warn(`Block "${blockName}" not found.`);
        return null;
    }

    // Create a group for the block
    const blockGroup = new THREE.Group();

    // Iterate over each entity in the block definition
    block.entities.forEach(blockEntity => {
        let blockObject = null;
        console.log(blockEntity.type);
        switch (blockEntity.type) {
            case 'INSERT':
                blockObject = createInsert(blockEntity, blocks, font); // Recursive call for nested INSERTs
                break;
            case 'LINE':
                blockObject = createLine(blockEntity);
                break;
            case 'LWPOLYLINE':
                blockObject = createLwPolyline(blockEntity);
                break;
            case 'POLYLINE':
                blockObject = createPolyline(blockEntity);
                break;
            case 'CIRCLE':
                blockObject = createCircle(blockEntity);
                break;
            case 'ARC':
                blockObject = createArc(blockEntity);
                break;
            case 'ELLIPSE':
                blockObject = createEllipse(blockEntity);
                break;
            case 'SPLINE':
                blockObject = createSpline(blockEntity);
                break;
            // case 'MTEXT': // Handle MTEXT within blocks
            //     blockObject = createMText(blockEntity, font);
            //     break;
            // case 'DIMENSION': // Handle DIMENSION within blocks
            //     blockObject = createDimension(blockEntity, font);
            //     break;
            case 'SOLID':
                blockObject = createSolid(blockEntity);
                break;
            // Add more entity types as needed
            default:
                console.warn(`Unsupported block entity type: ${blockEntity.type}`);
        }

        if (blockObject) {
            blockGroup.add(blockObject);
        }
    });

    // Safely access transformation properties with default values
    if(!entity.position) return null;
    const insert = entity.position || { x: 0, y: 0, z: 0 };
    const scale = {
        x: entity.scale && typeof entity.scale.x === 'number' ? entity.scale.x : 1,
        y: entity.scale && typeof entity.scale.y === 'number' ? entity.scale.y : 1,
        z: entity.scale && typeof entity.scale.z === 'number' ? entity.scale.z : 1,
    };
    const rotationZ = typeof entity.rotation === 'number' ? THREE.MathUtils.degToRad(entity.rotation) : 0;

    // Apply transformations from the INSERT entity
    blockGroup.position.set(insert.x, insert.y, insert.z);
    blockGroup.scale.set(scale.x, scale.y, scale.z);
    blockGroup.rotation.set(0, 0, rotationZ); // Only apply rotation on the Z-axis

    return blockGroup;
}


/**
 * Creates a Three.js Line from a DXF LINE entity.
 *
 * @param {Object} entity - The DXF LINE entity.
 * @returns {THREE.Line} - The resulting Three.js Line object.
 */
function createLine(entity) {
    const material = new THREE.LineBasicMaterial({ color: colors.lines });
    const geometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(entity.vertices[0].x, entity.vertices[0].y, entity.vertices[0].z || 0),
        new THREE.Vector3(entity.vertices[1].x, entity.vertices[1].y, entity.vertices[1].z || 0),
    ]);
    return new THREE.Line(geometry, material);
}

/**
 * Creates a Three.js LineLoop or Line from a DXF LWPOLYLINE entity.
 *
 * @param {Object} entity - The DXF LWPOLYLINE entity.
 * @returns {THREE.LineLoop | THREE.Line} - The resulting Three.js LineLoop or Line object.
 */
function createLwPolyline(entity) {
    const material = new THREE.LineBasicMaterial({ color: colors.lwPolylines });
    const points = entity.vertices.map(v => new THREE.Vector3(v.x, v.y, v.z || 0));
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    return entity.closed ? new THREE.LineLoop(geometry, material) : new THREE.Line(geometry, material);
}

/**
 * Creates a Three.js Line from a DXF POLYLINE entity.
 *
 * @param {Object} entity - The DXF POLYLINE entity.
 * @returns {THREE.Line} - The resulting Three.js Line object.
 */
function createPolyline(entity) {
    const material = new THREE.LineBasicMaterial({ color: colors.polylines });
    const points = entity.vertices.map(v => new THREE.Vector3(v.x, v.y, v.z || 0));
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    return new THREE.Line(geometry, material);
}

/**
 * Creates a Three.js Circle from a DXF CIRCLE entity.
 *
 * @param {Object} entity - The DXF CIRCLE entity.
 * @returns {THREE.LineLoop} - The resulting Three.js Circle object.
 */
function createCircle(entity) {
    const material = new THREE.LineBasicMaterial({ color: colors.circles });
    const geometry = new THREE.CircleGeometry(entity.radius, 64);

    // Access the position attribute (vertex data) of the geometry
    const positions = geometry.attributes.position.array;
    const vertexCount = positions.length / 3; // Each vertex has 3 components (x, y, z)

    // Create a new array excluding the center vertex (first vertex)
    const newPositions = positions.slice(3); // Skip the first 3 elements (center vertex)

    // Update the geometry with the new position data (without the center vertex)
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(newPositions, 3));

    const circle = new THREE.LineLoop(geometry, material);
    circle.position.set(entity.center.x, entity.center.y, entity.center.z || 0);
    return circle;
}


/**
 * Creates a Three.js Arc from a DXF ARC entity.
 *
 * @param {Object} entity - The DXF ARC entity.
 * @returns {THREE.Line} - The resulting Three.js Arc object.
 */
function createArc(entity) {
    const material = new THREE.LineBasicMaterial({ color: colors.arcs });
    const startAngle = THREE.MathUtils.degToRad(entity.startAngle);
    const endAngle = THREE.MathUtils.degToRad(entity.endAngle);
    const curve = new THREE.ArcCurve(
        0, // center x relative
        0, // center y relative
        entity.radius,
        startAngle,
        endAngle,
        false
    );
    const points = curve.getPoints(64);
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const arc = new THREE.Line(geometry, material);
    arc.position.set(entity.center.x, entity.center.y, entity.center.z || 0);
    return arc;
}

/**
 * Creates a Three.js Ellipse from a DXF ELLIPSE entity.
 *
 * @param {Object} entity - The DXF ELLIPSE entity.
 * @returns {THREE.Line} - The resulting Three.js Ellipse object.
 */
function createEllipse(entity) {
    const material = new THREE.LineBasicMaterial({ color: colors.ellipses });

    // Calculate rotation from major axis
    const majorAxis = new THREE.Vector2(entity.majorAxis.x, entity.majorAxis.y);
    const angle = majorAxis.angle();

    const ellipse = new THREE.EllipseCurve(
        0, 0, // relative center
        entity.majorAxis.length(), // xRadius
        entity.minorAxis.length(), // yRadius
        0, 2 * Math.PI, // start and end angles
        false, // clockwise
        angle // rotation
    );

    const points = ellipse.getPoints(64);
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const ellipseLine = new THREE.LineLoop(geometry, material);
    ellipseLine.position.set(entity.center.x, entity.center.y, entity.center.z || 0);
    return ellipseLine;
}

/**
 * Creates a Three.js Spline from a DXF SPLINE entity.
 *
 * @param {Object} entity - The DXF SPLINE entity.
 * @returns {THREE.Line} - The resulting Three.js Spline object.
 */
function createSpline(entity) {
    const material = new THREE.LineBasicMaterial({ color: colors.splines });

    // Extract control points
    const controlPoints = entity.controlPoints.map(pt => new THREE.Vector3(pt.x, pt.y, pt.z || 0));

    // Create a Catmull-Rom curve from control points
    const curve = new THREE.CatmullRomCurve3(controlPoints);
    const points = curve.getPoints(64);
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const spline = new THREE.Line(geometry, material);
    return spline;
}

/**
 * Creates a Three.js TextMesh from a DXF TEXT entity.
 *
 * @param {Object} entity - The DXF TEXT entity.
 * @param {THREE.Font} font - The loaded font.
 * @returns {THREE.Mesh} - The resulting Three.js TextMesh object.
 */
function createText(entity, font) {
    const geometry = new TextGeometry(entity.text, {
        font: font,
        size: entity.height || 1,
        height: 0.2,
        curveSegments: 12,
        bevelEnabled: false,
    });

    const material = new THREE.MeshBasicMaterial({ color: colors.text });
    const textMesh = new THREE.Mesh(geometry, material);

    // Positioning
    textMesh.position.set(entity.insert.x, entity.insert.y, entity.insert.z || 0);
    return textMesh;
}

/**
 * Creates a Three.js Mesh from a DXF SOLID entity.
 *
 * @param {Object} entity - The DXF SOLID entity.
 * @returns {THREE.Mesh} - The resulting Three.js Mesh object.
 */
function createSolid(entity) {
    const material = new THREE.MeshBasicMaterial({ color: colors.solids, side: THREE.DoubleSide });

    const vertices = entity.vertices.map(v => new THREE.Vector3(v.x, v.y, v.z || 0));

    // Assuming the solid is a quadrilateral
    if (vertices.length < 4) {
        console.warn('SOLID entity has less than 4 vertices.');
        return null;
    }

    const geometry = new THREE.BufferGeometry();
    const verticesArray = [];

    // Define two triangles for the quadrilateral
    verticesArray.push(vertices[0].x, vertices[0].y, vertices[0].z);
    verticesArray.push(vertices[1].x, vertices[1].y, vertices[1].z);
    verticesArray.push(vertices[2].x, vertices[2].y, vertices[2].z);

    verticesArray.push(vertices[0].x, vertices[0].y, vertices[0].z);
    verticesArray.push(vertices[2].x, vertices[2].y, vertices[2].z);
    verticesArray.push(vertices[3].x, vertices[3].y, vertices[3].z);

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(verticesArray, 3));
    geometry.computeVertexNormals();

    const mesh = new THREE.Mesh(geometry, material);
    return mesh;
}

/**
 * Creates a Three.js TextMesh from a DXF MTEXT entity.
 *
 * @param {Object} entity - The DXF MTEXT entity.
 * @returns {THREE.Mesh} - The resulting Three.js TextMesh object.
 */
function createMText(entity, font) {
    const geometry = new TextGeometry(entity.text, {
        font: font,
        size: entity.height || 1,
        height: 0.2,
        curveSegments: 12,
        bevelEnabled: false,
    });

    const material = new THREE.MeshBasicMaterial({ color: colors.text });
    const textMesh = new THREE.Mesh(geometry, material);

    // Positioning
    console.log("MTEXT- entity", entity);
    textMesh.position.set(entity.position.x, entity.position.y, entity.position.z || 0);

    // Apply rotation if available
    if (entity.rotation) {
        textMesh.rotation.z = THREE.MathUtils.degToRad(entity.rotation);
    }

    return textMesh;
}


/**
 * Creates a Three.js object from a DXF DIMENSION entity.
 *
 * @param {Object} entity - The DXF DIMENSION entity.
 * @returns {THREE.Group} - The resulting Three.js Group representing the dimension.
 */
function createDimension(entity, font) {
    const group = new THREE.Group();
    const material = new THREE.LineBasicMaterial({ color: colors.dimensions || 'blue' });

    // Example: Create lines between extension points
    if (entity.dimLines && entity.dimLines.length >= 2) {
        entity.dimLines.forEach(dimLine => {
            const geometry = new THREE.BufferGeometry().setFromPoints([
                new THREE.Vector3(dimLine.start.x, dimLine.start.y, dimLine.start.z || 0),
                new THREE.Vector3(dimLine.end.x, dimLine.end.y, dimLine.end.z || 0),
            ]);
            const line = new THREE.Line(geometry, material);
            group.add(line);
        });
    }

    // Example: Add dimension text
    if (entity.text && entity.text.length > 0) {
        const geometry = new TextGeometry(entity.text, {
            font: font,
            size: entity.textHeight || 1,
            height: 0.2,
            curveSegments: 12,
            bevelEnabled: false,
        });

        const textMaterial = new THREE.MeshBasicMaterial({ color: colors.dimensionsText || 'red' });
        const textMesh = new THREE.Mesh(geometry, textMaterial);

        // Position the text at the dimension's insertion point
        console.log("DIMENSION- entity", entity);
        textMesh.position.set(entity.position.x, entity.position.y, entity.position.z || 0);
        group.add(textMesh);
    }

    return group;
}

