<template>
    <div id="type-dropdown" class="form-floating" data-bs-theme="dark">
        <select v-if="individual_placement" id="type-select" class="form-select min-width" @change="emitSelectedType" v-model="selected_type">
            <option v-for="cg_type in store.state.climagruen_types.vegetation" :value="cg_type.id">{{ cg_type.common_name }}</option>
        </select>
        <select v-else id="type-select" class="form-select min-width" @change="emitSelectedTypeMix" v-model="selected_type_mix">
            <option v-for="cg_type_mix in store.state.climagruen_types.vegetation_mix" :value="cg_type_mix.id">{{ cg_type_mix.common_name }}</option>
        </select>
        <label for="type-select">{{ $t('toolbar.select_type') }}</label>
    </div>

    <!-- <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" v-model="individual_placement" :title="$t('toolbar.individual_placement')" @change="emitModeChanged">
    </div> -->

    <!-- <div class="vstack" v-if="!individual_placement">
        <div class="hstack">
            <label for="occupancy" class="form-label">{{ $t('toolbar.sigma') }}</label>
            <input type="range" class="form-range" min="0.1" max="25.0" step="0.1" id="occupancy" :title="$t('toolbar.asset_per_area')" v-model="occupancy">
            <span class="ms-2">{{ occupancy }}</span>
        </div>
    </div> -->

</template>

<script setup>
    import {ref} from "vue";
    import {useStore} from "vuex";
    import {default_values} from "@/js/modeling_utils.js";

    const emit = defineEmits(['typeSelectChange','typeMixSelectChange', 'modeChanged']);

    const store = useStore()

    const selected_type = ref(null);
    const selected_type_mix = ref(null);

    const occupancy = ref(default_values.occupancy);

    const individual_placement = ref(default_values.individual_placement);

    function emitSelectedType() {
        emit('typeSelectChange', selected_type.value)
    }

    function emitSelectedTypeMix() {
        emit('typeMixSelectChange', selected_type_mix.value)
    }
    function emitModeChanged() {
        emit('modeChanged', individual_placement.value)
    }
</script>
  
<style scoped>
    #type-dropdown{
        width: max-content;
    }

    .min-width{
        min-width: 150px;
    }

    .vstack{
        display: flex;
        flex-direction: column;
    }

    .hstack{
        display: flex;
    }

</style>
  