<template>
    <div class="weather-table-container">
        <table class="table table-bordered weather-table">
            <thead class="table-light">
                <tr>
                    <th rowspan="2">{{$t('welcome_page.modify_project_modal.rain_duration')}}</th>
                    <th colspan="100%">{{$t('welcome_page.modify_project_modal.rain_return_period')}}</th>
                </tr>
                <tr>
                    <th v-for="freq in frequencies" :key="freq">{{ freq }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="duration in durations" :key="duration">
                    <td>{{ duration }}</td>
                    <td v-for="freq in frequencies" :key="freq">
                        <input
                            type="number"
                            class="form-control"
                            v-model.number="tableData[duration][freq]"
                            @change="updateCell(duration, freq, tableData[duration][freq])"
                            step="any"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
const props = defineProps({
    durations: {
        type: Array,
        required: true
    },
    frequencies: {
        type: Array,
        required: true
    },
    tableData: {
        type: Object,
        required: true
    }
})

// Define emits to notify parent of changes
const emit = defineEmits(['update:tableData'])

// Function to emit updated cell data
const updateCell = (duration, freq, value) => {
    emit('update:tableData', { duration, freq, value })
}
</script>

<style scoped>
.weather-table-container {
    max-height: 50vh; 
    overflow-y: auto;
    width: 100%;
}

.weather-table {
    width: 100%;
    border-collapse: collapse;
}

thead th {
    position: sticky;
    top: 0;
    background-color: #f8f9fa; 
    z-index: 2; /* Ensure headers stay above table body */
}

th, td {
    text-align: center;
    vertical-align: middle;

}

input {
    width: 100px;
}

.weather-table-container::-webkit-scrollbar {
    width: 8px;
}

.weather-table-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.weather-table-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
}
</style>
