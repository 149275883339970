<template>
    <div v-if="showModal" class="custom-modal">
      <div class="modal-dialog bg-dark">
        <div class="modal-content bg-dark text-light">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('welcome_page.new_project_modal.title') }}</h5>
            <button type="button" class="btn-close btn-close-white" @click="closeModal()"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="createNewProject">
              <div class="mb-3">
                <label for="name" class="form-label">{{ $t('welcome_page.new_project_modal.p_name') }}</label>
                <input type="text" class="form-control" id="name" v-model="project.name" required>
              </div>
              <div class="mb-3">
                <label for="location" class="form-label">{{ $t('welcome_page.new_project_modal.p_location') }}</label>
                <input type="text" class="form-control" id="location" v-model="project.location" required>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary">{{ $t('welcome_page.new_project_modal.submit_btn') }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <SuccessNotification v-if="success"/>
    <ErrorNotification v-if="failure" :message="error_message"/>
</template>
  
<script setup>
    import { ref } from 'vue';
    import {api_endpoints} from  '@/js/api_endpoints.js';
    import {geoReferenceProjectLocation} from '@/js/api_calls.js';
    import axios from 'axios';
    import SuccessNotification from '../../notifications/SuccessNotification.vue';
    import ErrorNotification from '../../notifications/ErrorNotification.vue';

    import { useStore } from 'vuex';

    const store = useStore();

    const success = ref(false);
    const failure = ref(false);
    const error_message = ref("");
    
    const props = defineProps({
        showModal: Boolean
    })

    const emit = defineEmits(['update:showModal'])

    const closeModal = () => {
        emit('update:showModal', false)
    }

    const project = ref({
    name: '',
    location: '',
    })

    const createNewProject = async () => {
        console.log(project.value);
        const token = window.localStorage.getItem('climagruen_token');
        axios.post(api_endpoints.projects, project.value, {headers: {'Authorization': `Bearer ${token}`}})
            .then((response)=>{
                geoReferenceProjectLocation(response.data.id); // If this fails, the user won't notice it. We will check later again for the simulation.
                updateStore();
                showSuccess();
                closeModal();
                console.log('Form submitted:', project.value);
            })
            .catch((error) => {
                console.log(error);
                showError(error);
            });
    }

    function updateStore(){
        store.dispatch('getProjects');
    }
    function showSuccess(){
        success.value = true;
        setTimeout(() => {
            success.value = false;
        }, 2500);
    }

    function showError(message){
        failure.value = true;
        error_message.value = message;
        setTimeout(() => {
            failure.value = false;
            error_message.value = "";
        }, 2500);
    }
</script>
  
<style scoped>
  .modal-dialog {
    border-radius: 0.5rem;
    width: 90%;
    padding: 1rem;
    max-width: none;
  }

  .modal-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  @media (min-width: 576px) {
    .modal-dialog {
      width: 400px;
    }
  }
  
  /* @media (min-width: 992px) {
    .modal-dialog {
      width: 60%;
    }
  } */
  
  .btn-close-white {
    filter: invert(1);
  }
  </style>
  