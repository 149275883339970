<template>
    <div class="schema-container">
        <svg ref="svgRef" class="schema-svg" :width="svgWidth" :height="svgHeight">
            <g v-for="(connection, index) in hydraulicConnections" :key="connection.input.id + '-' + index">
                <!-- Input Component -->
                <rect :x="connection.input.x" :y="connection.input.y" width="150" height="70" class="component-rect"/>
                <text :x="connection.input.x + 75" :y="connection.input.y + 40" class="component-text">{{ connection.input.name }}</text>

                <!-- Outputs and connecting lines -->
                <g v-for="(output, outputIndex) in connection.outputs" :key="outputIndex">
                    <line :x1="connection.input.x + 150" :y1="connection.input.y + 35" 
                          :x2="output.x" :y2="output.y + 35"
                          stroke="black" stroke-width="2"/>

                    <rect :x="output.x" :y="output.y" width="150" height="70" class="component-rect"/>
                    <text :x="output.x + 75" :y="output.y + 40" class="component-text">{{ output.name }}</text>
                </g>
            </g>
        </svg>
    </div>
</template>

<script setup>
import { ref, onBeforeUnmount } from 'vue';

// Array to hold positioned hydraulic connections
const hydraulicConnections = ref([]);

// Refs for the dynamic width and height of the SVG
const svgWidth = ref(1200); // Default width
const svgHeight = ref(600); // Default height

defineExpose({
  generateSchema,
  clearSchema
});

// Clears the existing schema data
function clearSchema() {
  hydraulicConnections.value = [];
}

// Function to clear the SVG content (removes all child elements)
function clearSVG() {
  const svgElement = svgRef.value;
  while (svgElement.firstChild) {
    svgElement.removeChild(svgElement.firstChild);
  }
}

// Main algorithm to calculate positions
function generateSchema(connections, components) {
    // Reset SVG and positions to avoid duplication
    hydraulicConnections.value = [];
    svgWidth.value = 1200;
    svgHeight.value = 600;

    const xInputStart = 0;       // Starting x position for inputs
    const xOutputStart = 250;    // Starting x position for outputs
    const yStart = 0;            // Starting y position for the first component
    const verticalSpacing = 20;  // Reduced space between each input component row
    const outputSpacing = 60;    // Reduced space between each output for a single input
    const farRightX = 670;       // Fixed x position for "Groundwater" or "River"

    let currentY = yStart;        // Track the current Y position for inputs
    let maxY = yStart;            // Track the maximum Y to adjust SVG height dynamically
    let maxX = xOutputStart;      // Track the maximum X to adjust SVG width dynamically

    // Loop through each connection (input and its outputs)
    const positionedConnections = connections.map((connection, index) => {
        // Set x and y for the input component
        connection.input.x = xInputStart;
        connection.input.y = currentY;

        // Check the type of the input component instead of its name
        const isGroundwaterOrRiver = connection.input.type === 'groundwater' || connection.input.type === 'river';

        // Set x and y for each output, stacked vertically to the right of the input
        let outputY = currentY;  // Start stacking outputs at the same Y as the input
        connection.outputs = connection.outputs.map((output, outputIndex) => {
            // If the output is "Groundwater" or "River", place it on the far right
            if (output.type === 'groundwater' || output.type === 'river') {
                output.x = farRightX;
            } else {
                output.x = xOutputStart + outputIndex * 10; // Stagger outputs horizontally if multiple
            }

            output.y = outputY;
            outputY += outputSpacing; // Increment y for the next output

            // Update maximum X and Y for dynamic SVG sizing
            if (output.y > maxY) maxY = output.y;
            if (output.x > maxX) maxX = output.x;

            return output;
        });

        // Increment y for the next input to avoid overlap
        currentY += verticalSpacing + (outputSpacing * connection.outputs.length);

        // Update maximum Y for dynamic SVG sizing
        if (currentY > maxY) maxY = currentY;

        return connection;
    });

    hydraulicConnections.value = positionedConnections;

    // Update the dynamic SVG width and height based on the calculated positions
    svgWidth.value = maxX + 200;  // Add some padding to the right
    svgHeight.value = maxY + 100; // Add some padding at the bottom
}

// Cleanup logic when the component is unmounted or the tab is left
onBeforeUnmount(() => {
    // Reset connections and SVG dimensions to avoid issues when the tab is entered again
    hydraulicConnections.value = [];
    svgWidth.value = 1200;
    svgHeight.value = 600;
});
</script>

<style scoped>
.schema-container {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.schema-svg {
    width: 100%;
    height: 100%;
}

.component-rect {
    fill: lightgray;
    stroke: black;
    stroke-width: 2;
}

.component-text {
    text-anchor: middle;
    dominant-baseline: middle;
    font-size: 16px;
}
</style>
