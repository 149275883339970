<template>
    <div id="type-dropdown" class="form-floating" data-bs-theme="dark">
        <select id="type-select" class="form-select min-width" @change="emitSelectedType" v-model="selected_type">
            <option v-for="(cg_type, index) in store.state.climagruen_types.line_element_types" :value="index">{{ cg_type.name }}</option>
        </select>
        <label for="type-select">{{ $t('toolbar.select_type') }}</label>
    </div>

    <button class="btn btn-secondary margin-right" @click="showModal" :title="$t('toolbar.configure_form')"
        data-toggle="modal" data-target="#exampleModal"><font-awesome-icon icon="fa-solid fa-table-list"/>
    </button>

    <button class="btn btn-info" @click="emitConfirmPolyline" :title="$t('toolbar.confirm_polygon')" ><font-awesome-icon icon="fa-solid fa-check"/></button>
    
    <input type="checkbox" v-model="snapping" id="snapping" class="btn-check" @change="emitSnapping"/>
    <label class="btn btn-secondary" :class="{'active': snapping}" for="snapping" :title="snapping ? $t('toolbar.snapping_off'):$t('toolbar.snapping_on')">
        <font-awesome-icon icon="fa-solid fa-arrows-to-circle"/>
    </label>

    <button class="btn btn-secondary" @click="emitUndoPolyline" :title="$t('toolbar.undo')"><font-awesome-icon icon="fa-solid fa-rotate-left"/></button>
    <button class="btn btn-danger" @click="emitExitModeling" :title="$t('toolbar.exit_modeling')"><font-awesome-icon icon="fa-solid fa-xmark"/></button>
    
    
    <LineModelingToolbarForm v-if="specsModalVisible" @update:showModal="specsModalVisible = $event" :fields="store.state.climagruen_types.line_element_types[selected_type].parameters" :index="selected_type"/>
</template>

<script setup>
    import { onMounted } from "vue";
    import {ref} from "vue";
    import {useStore} from "vuex";
    import LineModelingToolbarForm from "./LineModelingToolbarForm.vue";

    const emit = defineEmits(['typeSelectChange','confirmPolyline' ,'snappingChange','undoPolyline', 'exitModeling']);

    const store = useStore();
    const selected_type = ref(null);
    const snapping =ref(true);
    const specsModalVisible =ref(false);

    onMounted(()=>{
        // select first line type by default
        if (store.state.climagruen_types.line_element_types.length > 0) {
            selected_type.value = 0;
            // emitSelectedType();
        }
    })

    // function emitSelectedType() {
    //     emit('typeSelectChange', selected_type.value)
    // }

    function emitConfirmPolyline() {
        emit('confirmPolyline', selected_type.value)
    }

    function emitSnapping() {
        emit('snappingChange', snapping.value)
    }

    function emitUndoPolyline(){
        emit('undoPolyline');
    }

    function emitExitModeling(){
        emit('exitModeling');
    }

    function showModal(){
        if(selected_type.value != null){
            specsModalVisible.value = true;
        }
    }

</script>
  
<style scoped>
    #modeling-modes-wrapper{
        display: flex;
        gap: 0.5rem;
    }
    .radio-label{
        opacity: 0.5;
    }
    .active {
        border-color: #0a5bb3; /* Slightly darker border for depth */
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5); /* A subtle glow effect */
        opacity: 1;
    }

    #sigmaRange{
        max-width: 100px;
    }

    #type-dropdown{
        width: max-content;
        max-width: 300px;
    }

    .margin-right {
        margin-right: 4rem; /* Adjust the value as needed */
    }
    .min-width{
        min-width: 150px;
    }
</style>
  