<template>
    <!-- MODELING TOOLBARs -->
    <div id="toolbar-container">
        <!-- MAIN TOOLBAR (vertical) -->
        <div class='bg-dark text-white toolbar vertical' @mouseenter="hover = true" @mouseleave="hover = false">
                        
            <button type="button" class="btn btn-dark" :title="$t('welcome_page.modify_project')" @click="projectModal =true">
                <font-awesome-icon icon="fa-solid fa-clipboard-list"/>
            </button>

            <button type="button" class="btn btn-dark" :title="$t('toolbar.upload_ifc')" @click="triggerFileUpload">
                <input type="file" @change="uploadIfc($event)" style="display: none" ref="fileInput" accept=".ifc"/>
                <font-awesome-icon icon="fa-solid fa-upload"/>
            </button>
            
            <!-- <button class="btn btn-dark" @click="saveProject" :title="$t('toolbar.save_project')"><font-awesome-icon icon="fa-solid fa-save"/></button> -->

            <!-- Model Visibility Button with Expandable Options -->
            <div class="toolbar-item">
                <!-- Eye icon for Model Visibility -->
                <button class="btn btn-secondary" @click="toggleModelVisibilityExpand" :title="$t('toolbar.model_visible')">
                    <font-awesome-icon icon="fa-solid fa-eye"/>
                </button>

                <!-- Expandable section for Model and Axis Visibility -->
                <div v-if="isModelVisibilityExpanded" class="sub-toolbar horizontal" v-click-outside="handleClickOutside">
                    <input type="checkbox" v-model="modelVisible" id="modelVisible" class="btn-check" @change="toggleModelVisibility"/>
                    <label class="btn btn-secondary" :class="{'active': modelVisible}" for="modelVisible" :title="$t('toolbar.model_visible')">
                        <font-awesome-icon icon="fa-solid fa-building"/>
                    </label>

                    <input type="checkbox" v-model="customModelVisible" id="customModelVisible" class="btn-check" @change="toggleCustomModelVisibility"/>
                    <label class="btn btn-secondary" :class="{'active': customModelVisible}" for="customModelVisible" :title="$t('toolbar.custom_model_visible')">
                        <font-awesome-icon icon="fa-solid fa-sun-plant-wilt"/>
                    </label>

                    <!-- Levels Visibility Dropdown -->
                    <div class="levels-dropdown btn btn-secondary">
                        <font-awesome-icon icon="fa-solid fa-layer-group"/>
                        <!-- List of individual levels toggles -->
                        <ul class="dropdown-menu">
                            <!-- Toggle All Visibility Button -->
                            <li class="dropdown-item">
                                <button class="btn btn-secondary" @click="toggleAllLevelsVisibility">
                                    <font-awesome-icon :icon="allLevelsVisible ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'" class="visibility-icon"/>
                                    {{ allLevelsVisible ? $t('toolbar.hide_all_levels') : $t('toolbar.show_all_levels') }}
                                </button>
                            </li>
                            <li><hr class="dropdown-divider"></li>

                            <!-- List of individual levels toggles -->
                            <li v-for="(level, index) in levels_threejs.children" :key="index" class="dropdown-item">
                                <button class="btn btn-secondary" @click="toggleIndividualLevelVisibility(index)">
                                    <font-awesome-icon :icon="level.visible ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'" class="visibility-icon"/>
                                    {{ level.name.includes('Default Level 0') ? level.name: level.userData.cg_level_name }}
                                </button>
                            </li>
                        </ul>
                    </div>

                    <input type="checkbox" v-model="gridVisible" id="gridVisible" class="btn-check" @change="toggleGridVisibility"/>
                    <label class="btn btn-secondary" :class="{'active': gridVisible}" for="gridVisible" :title="$t('toolbar.grid_visible')">
                        <font-awesome-icon icon="fa-solid fa-table-cells"/>
                    </label>

                    <input type="checkbox" v-model="axisVisible" id="axisVisible" class="btn-check" @change="toggleAxisVisibility"/>
                    <label class="btn btn-secondary" :class="{'active': axisVisible}" for="axisVisible" :title="$t('toolbar.axis_visible')">
                        <font-awesome-icon icon="fa-solid fa-l"/>
                    </label>
                </div>
            </div>

            <button class="btn btn-secondary" @click="resetCameraView" :title="$t('toolbar.reset_view')"><font-awesome-icon icon="fa-solid fa-camera-rotate"/></button>

            <input type="checkbox" v-model="planMode" id="planToggle" class="btn-check" @change="togglePlanMode"/>
            <label class="btn btn-secondary" :class="{'active': planMode}" for="planToggle" :title="$t('toolbar.show_plan')">
                <font-awesome-icon icon="fa-solid fa-pen-ruler"/>
            </label>

            <button class="btn btn-secondary" @click="levelModal = true" :title="$t('toolbar.level_interface')"><font-awesome-icon icon="fa-solid fa-layer-group"/></button>

            <input type="checkbox" v-model="drawingMode" id="drawingToggle" class="btn-check" @change="toggleDrawingMode"/>
            <label class="btn btn-info" :class="{'active': drawingMode}" for="drawingToggle" :title="$t('toolbar.drawing_mode')">
                <font-awesome-icon icon="fa-solid fa-draw-polygon"/>
            </label>

            <!-- This feature will be disabled for he moment: -->           
            <!-- <input type="checkbox" v-model="drawingModeLine" id="drawingLineToggle" class="btn-check" @change="toggleDrawingModeLine"/>
            <label class="btn btn-info" :class="{'active': drawingModeLine}" for="drawingLineToggle" :title="$t('toolbar.drawing_mode')">
                <font-awesome-icon icon="fa-solid fa-slash"/>
            </label> -->

            <input type="checkbox" v-model="vegetationMode" id="vegetationToggle" class="btn-check" @change="toggleVegetationMode"/>
            <label class="btn btn-success" :class="{'active': vegetationMode}" for="vegetationToggle" :title="$t('toolbar.vegetation_mode')">
                <font-awesome-icon icon="fa-solid fa-seedling"/>
            </label>

            <input type="checkbox" v-model="modificationMode" id="modificationToggle" class="btn-check" @change="toggleModificationMode"/>
            <label class="btn btn-danger" :class="{'active': modificationMode}" for="modificationToggle" :title="$t('toolbar.modification_mode')">
                <font-awesome-icon icon="fa-solid fa-hand-pointer"/>
            </label>
                                   
            <button class="btn btn-primary" @click="hydraulicsModal = true" :title="$t('toolbar.hydraulic_modal')"><font-awesome-icon icon="fa-solid fa-faucet-drip"/></button>

            <button class="btn btn-primary" @click="runSimulation" :title="$t('toolbar.start_simulation')"><font-awesome-icon icon="fa-solid fa-cloud-sun-rain"/></button>
            
            <button class="btn btn-primary" @click="resultsModal = true" :title="$t('toolbar.show_results')"><font-awesome-icon icon="fa-solid fa-chart-simple"/></button>
        </div>

        <!-- SECONDARY TOOLBARs (horizontal)-->
        <div class="bg-dark text-white toolbar horizontal">
            <ModelingToolbar v-if="drawingMode" :levels_threejs="levels_threejs" :polyline-ready="polyline_ready"
                @draw-on-levels-change="toggleDrawOnLevels"
                @level-change="setLevel"
                @offset-change="updateOffset"
                @type-select-change="updateTypeInfo" @confirm-polyline="bakeGreenRoof" 
                @snapping-change="toggleSnapping" @undo-polyline="undoPolyline" 
                @exit-modeling="drawingMode =false; toggleDrawingMode()"
            />
            <LineModelingToolbar v-if="drawingModeLine" @confirm-polyline="bakeLineElement" 
                @snapping-change="toggleSnapping" @undo-polyline="undoPolyline" @exit-modeling="drawingModeLine =false; toggleDrawingModeLine()"
            />
            <VegetationToolbar v-if="vegetationMode" @type-select-change="loadAsset" @type-mix-select-change="loadAssetMix" @mode-changed="toggleIndividualAssetPlacement"/>
            <ModificationToolbar v-if="modificationMode" :selected-object="selected_object" @delete-object="deleteObject" @update-sigma="setSigma" 
                @terrain-modeling="triggerTerrainModeling" @type-select-change="switchType" @elevation="triggerElevation"
            />
            <PlanToolbar v-if="planMode" @annotations-change="setAnnotations" @measurement-change="setMeasurements" @exit-plan-view="planMode=false; togglePlanMode()"/>
        </div>

        <!-- USER INSTRUCTIONS -->
        <UserGuidanceNotification v-if="instructions_enabled && current_instruction" class="toolbar horizontal" :message="current_instruction"/>
    </div>

    <!-- CONTAINER FOR 3D-MODELING CANVAS -->
    <div id="threed-canvas">

    </div>
    <!-- 2D-Plan Title Block -->
    <DynamicTitleBlock v-if="planMode" :area-data="areaDataHelper"/>


    <!-- Loading Spinner -->
    <LoadingSpinner v-if="showLoadingSpinner" :message="loadingMessage"/>

    <!-- Mouse control instruction for navigating the 3D interface -->
    <ControlInstructionToolbar/>

    <!-- POTENTIAL Error NOTIFICATIONS -->
    <ErrorNotification v-if="current_notification" :message="current_notification"/>

    <!-- Modify Project Modal -->
    <ProjectModal v-if="projectModal" :project_id="store.state.current_project.id" @close-modal="projectModal = false" />

    <!-- Modal for Hydraulic Components -->
    <HydraulicsModal v-if="hydraulicsModal" @close-modal="hydraulicsModal = false"/>

    <!-- Modal for Simulation Results -->
    <ResultsModal v-if="resultsModal" @close-modal="resultsModal = false"/>

    <!-- Modal for green roof name -->
    <GreenRoofNameModal v-if="greenRoofNameModal" @name-confirmed="closeAndStoreName" @close-modal="greenRoofNameModal = false; drawingMode =false; toggleDrawingMode()"/>

    <!-- Modal for adding and modifying levels -->
    <LevelModal ref="levelModalRef" v-if="levelModal" :levels_threejs="levels_threejs" @pick-face="pickFaceForLevel" @levels-confirmed="updateLevels" @close-modal="levelModal = false" />
</template>

<script setup>
    // vue
    import { onMounted, onUnmounted, ref, onBeforeUnmount , nextTick, computed } from 'vue';
    import { useStore } from 'vuex';

    // Multilanguage
    import { useI18n } from 'vue-i18n';

    // custom vue-components
    import ModelingToolbar from './toolbars/ModelingToolbar.vue';
    import LineModelingToolbar from './toolbars/LineModelingToolbar.vue';
    import VegetationToolbar from './toolbars/VegetationToolbar.vue';
    import ModificationToolbar from './toolbars/ModificationToolbar.vue';
    import ControlInstructionToolbar from  './toolbars/ControlInstructionToolbar.vue';
    import PlanToolbar from './toolbars/PlanToolbar.vue';
    import ErrorNotification from './notifications/ErrorNotification.vue';
    import UserGuidanceNotification from './notifications/UserGuidanceNotification.vue';
    import HydraulicsModal from './modals/hydraulics/HydraulicsModal.vue';
    import GreenRoofNameModal from './modals/GreenRoofNameModal.vue';
    import LoadingSpinner from './notifications/LoadingSpinner.vue';
    import ResultsModal from './modals/results/ResultsModal.vue';
    import DynamicTitleBlock from './DynamicTitleBlock.vue';
    import LevelModal from './modals/LevelModal.vue';
    import ProjectModal from './modals/project/ProjectModal.vue';

    // custom scripts for modeling with Threejs and openbim-components
    import {
            PolylineDrawer, AssetLoader, ObjectModifier, 
            customEvents, createGreenRoofObject, createComposedGreenRoofObject, ModelingInterfaceManager,
            createLineElement, layers, setLayerRecursively
    } from "@/js/modeling_utils.js";

    // API requests
    import { api_endpoints } from '@/js/api_endpoints';
    import axios from 'axios';

    const store = useStore();

    const { t } =  useI18n();

    const props = defineProps({
        project_id: String
    })

    const hover = ref(false);
    const modelVisible = ref(true);
    const customModelVisible = ref(true);
    const axisVisible = ref(false);
    const gridVisible = ref(true);
    const drawingMode = ref(false);
    const drawingModeLine = ref(false);
    const vegetationMode = ref(false);
    const modificationMode = ref(false);
    const planMode = ref(false);
    const fileInput = ref(null);
    const current_notification = ref(null);
    const instructions_enabled = ref(true);
    const current_instruction = ref(null);
    const showLoadingSpinner = ref(false);
    const loadingMessage = ref('');

    const type_details = ref(null);

    const selected_object = ref({cg_type: null});

    const polyline_ready = ref(false);

    const projectModal = ref(false);
    const hydraulicsModal = ref(false);
    const greenRoofNameModal = ref(false);
    const resultsModal = ref(false);
    const levelModal = ref(false);

    const areaDataHelper =ref([]);

    const levels_threejs = ref(null)

    const levelModalRef = ref(null);

    /**
     * @type {PolylineDrawer} - Instance of PolyLineDrawer
     */
    let polyLineDrawer;
    /**
     * @type {AssetLoader} - Instance of AssetLoader
     */
    let asset_loader;

    /**
     * @type {ObjectModifier} - Instance of ObjectModifier
     */
    let objectModificator;

    /**
     * @type {HTMLElement} - DOM element for the canvas
     */
    let canvasDomElement;

    /**
     * @type {ModelingInterfaceManager} - Instance of ModelingInterfaceManager
     */
    let global_manager;

    // State for controlling the expanded state of the model visibility section
    const isModelVisibilityExpanded = ref(false)

    // Function to toggle the expanded state
    const toggleModelVisibilityExpand = (event) => {
        isModelVisibilityExpanded.value = !isModelVisibilityExpanded.value
        event.stopPropagation(); // Prevent immediate closing
    }

    // Handle outside click
    const handleClickOutside = () => {
        isModelVisibilityExpanded.value = false;
    };

    // Custom directive
    const vClickOutside = {
        mounted(el, binding) {
            el.clickOutsideEvent = (event) => {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event);
            }
            };
            document.addEventListener('click', el.clickOutsideEvent);
        },
        unmounted(el) {
            document.removeEventListener('click', el.clickOutsideEvent);
        }
    };

    onMounted(async () => {
        console.log("Canvas mounted");
        loading(true);
        document.addEventListener('keydown', escapeKeyListener);
        global_manager = new ModelingInterfaceManager();
        canvasDomElement = document.getElementById('threed-canvas');
        canvasDomElement.addEventListener(customEvents.saveCustomGeometries.type, saveProject);
        canvasDomElement.addEventListener(customEvents.exitAllFunctions.type, exitAllFunctions);
        canvasDomElement.addEventListener(customEvents.triggerModificationMode.type, startModificationWithPredefinedSelection);
        canvasDomElement.addEventListener(customEvents.deleteObject.type, deleteObject);

        await global_manager.setUpCanvas( canvasDomElement );
        levels_threejs.value = global_manager.levels;

        asset_loader = new AssetLoader(global_manager);
        polyLineDrawer = new PolylineDrawer(global_manager);
        objectModificator = new ObjectModifier(global_manager);

        await loadProjectModels();

        store.commit('setModelingManager', global_manager);
        loading(false); 
    })


    onBeforeUnmount(async () =>{
        // Clean everything up... this sill needs to be improved. It does not seem that all memory is cleared
        asset_loader.disposeAllAssets();
        await global_manager.disposeAll();
        polyLineDrawer, asset_loader, objectModificator, canvasDomElement, global_manager = null;
        store.commit('setModelingManager', null);

    });

    onUnmounted(()=>{
        console.log("Canvas UNmounted");
        document.removeEventListener('keydown', escapeKeyListener);
    })

    function loading(enabled, message=''){
        showLoadingSpinner.value = enabled;
        loadingMessage.value = message;
    }

    function resetCameraView(){
        global_manager.resetCameraView();
    }

    function toggleModelVisibility() {
        if(global_manager.ifc_model){
            global_manager.ifc_model.visible = modelVisible.value;
            setLayerRecursively(global_manager.ifc_model, modelVisible.value ? layers.visible : layers.hidden);
        }
    }
    function toggleCustomModelVisibility(){
        global_manager.green_roof_objects.visible = customModelVisible.value;
        setLayerRecursively(global_manager.green_roof_objects, customModelVisible.value ? layers.visible : layers.hidden);
        global_manager.individual_assets.visible = customModelVisible.value;
        setLayerRecursively(global_manager.individual_assets, customModelVisible.value ? layers.visible : layers.hidden);
        global_manager.asset_mixes.visible = customModelVisible.value;
        setLayerRecursively(global_manager.asset_mixes, customModelVisible.value ? layers.visible : layers.hidden);
    }

    function toggleAxisVisibility() {
        global_manager.axesHelper.visible = axisVisible.value;
    }

    function toggleGridVisibility() {
        global_manager.grid.visible = gridVisible.value;
    }

    // Computed property to check if all levels are visible
    const allLevelsVisible = computed(() => {
        return levels_threejs.value.children.every(level => level.visible);
    });

    // Method to toggle the visibility of all levels
    const toggleAllLevelsVisibility = () => {
        const newVisibility = !allLevelsVisible.value; // Toggle all to opposite state
        levels_threejs.value.children.forEach(level => {
            level.visible = newVisibility;
            setLayerRecursively(level, newVisibility ? layers.visible : layers.hidden);
        });
    };

    // Toggle individual level visibility
    const toggleIndividualLevelVisibility = (index) => {
        // Perform any additional actions based on the level visibility state
        levels_threejs.value.children[index].visible = !levels_threejs.value.children[index].visible;
        setLayerRecursively(levels_threejs.value.children[index], levels_threejs.value.children[index].visible ? layers.visible : layers.hidden);
    };

    async function toggleDrawingMode() {
        if (drawingMode.value){
            deactivateOtherModes("drawing");

            greenRoofNameModal.value = true;

            canvasDomElement.addEventListener(customEvents.polylineCompleted.type, handlePolylineCompleted);
            canvasDomElement.addEventListener(customEvents.planeSelected.type, handlePlaneSelected);
            canvasDomElement.addEventListener(customEvents.polylineStarted.type, handlePolylineStarted);
            polyLineDrawer.startDrawing();
            current_instruction.value = t('modeling_instructions.select_plane_and_type');
        }else{
            canvasDomElement.removeEventListener(customEvents.polylineCompleted.type, handlePolylineCompleted);
            canvasDomElement.removeEventListener(customEvents.planeSelected.type, handlePlaneSelected);
            canvasDomElement.removeEventListener(customEvents.polylineStarted.type, handlePolylineStarted);

            await toggleDrawOnLevels({enabled: false});
            polyLineDrawer.endDrawing();
            polyline_ready.value = false;
            type_details.value = null;
            current_instruction.value = null;
        }
    }

    function toggleDrawingModeLine(){
        if(drawingModeLine.value){
            deactivateOtherModes("drawingLine");
            canvasDomElement.addEventListener(customEvents.polylineCompleted.type, handlePolylineCompleted);
            canvasDomElement.addEventListener(customEvents.planeSelected.type, handlePlaneSelected);
            canvasDomElement.addEventListener(customEvents.polylineStarted.type, handlePolylineStarted);
            polyLineDrawer.startDrawing();
        }else{
            canvasDomElement.removeEventListener(customEvents.polylineCompleted.type, handlePolylineCompleted);
            canvasDomElement.removeEventListener(customEvents.planeSelected.type, handlePlaneSelected);
            canvasDomElement.removeEventListener(customEvents.polylineStarted.type, handlePolylineStarted);

            polyLineDrawer.endDrawing();
            polyline_ready.value = false;
            current_instruction.value = null;
        }
    }

    async function toggleVegetationMode(){
        if(vegetationMode.value){
            deactivateOtherModes("vegetation");

            current_instruction.value = t('modeling_instructions.choose_vegetation');
            canvasDomElement.addEventListener(customEvents.assetPlacementError.type, asset_placement_alert);
        }else{
            current_instruction.value = null;
            canvasDomElement.removeEventListener(customEvents.assetPlacementError.type, asset_placement_alert);
            asset_loader.stopUserPlacement();
            asset_loader.disableRoofSelection();
            await saveProject();

        }
    }

    async function toggleModificationMode(){
        if(modificationMode.value){
            deactivateOtherModes("modification");

            current_instruction.value = t('modeling_instructions.select_object');
            canvasDomElement.addEventListener(customEvents.objectSelected.type, handleObjectSelected);
            canvasDomElement.addEventListener(customEvents.objectUnselected.type, handleObjectUnselected);
            objectModificator.startModification();
        }else{
            objectModificator.endModification();
            canvasDomElement.removeEventListener(customEvents.objectSelected.type, handleObjectSelected);
            canvasDomElement.removeEventListener(customEvents.objectUnselected.type, handleObjectUnselected);
            selected_object.value = {cg_type: null};
            current_instruction.value = null;
            await saveProject();
        }
    }

    function togglePlanMode(){
        if(planMode.value){
            deactivateOtherModes("plan");
            const view_cube = document.getElementById('view-helper');
            if(view_cube) view_cube.style.display = 'none';
            areaDataHelper.value = global_manager.switchToPlanView();
        }else{
            global_manager.exitPlanView();
            const view_cube = document.getElementById('view-helper');
            if(view_cube) view_cube.style.display = 'block';
            current_instruction.value = null;
        }
    }

    const modes = {
        "drawing": {reference: drawingMode, toggle: toggleDrawingMode}, 
        "drawingLine": {reference: drawingModeLine, toggle: toggleDrawingMode},
        "vegetation": {reference: vegetationMode, toggle: toggleVegetationMode},
        "modification": {reference: modificationMode, toggle: toggleModificationMode},
        "plan": {reference: planMode, toggle: togglePlanMode}
    }


    function deactivateOtherModes(exclude_mode){
        for (const mode in modes) {
            if (mode == exclude_mode) continue;

            if(modes[mode].reference.value){
                modes[mode].reference.value = false;
                modes[mode].toggle();
            }
        }
    }

    function escapeKeyListener(e) {
        if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
            if(drawingMode.value){
                drawingMode.value = false;
                toggleDrawingMode();
            }
            else if(vegetationMode.value){
                vegetationMode.value = false;
                toggleVegetationMode();
            }
            else if(modificationMode.value){
                modificationMode.value = false;
                toggleModificationMode();
            }
            else if(drawingModeLine.value){
                drawingModeLine.value = false;
                toggleDrawingModeLine();
            }
            else if(planMode.value){
                planMode.value = false;
                togglePlanMode();
            }

        }
    }

    function exitAllFunctions(){
        return new Promise((resolve) => {
        const escapeEvent = new KeyboardEvent('keydown', { key: 'Escape' });
        document.dispatchEvent(escapeEvent);

        // Add a small time out to make sure that the escape event is processed before the function returns
        setTimeout(() => {
          resolve();
        }, 300);
      })
        
    }

    function undoPolyline(){        
        current_instruction.value = t('modeling_instructions.close_polygon');
        polyLineDrawer.undo();
        if(polyLineDrawer.points.length < 3) polyline_ready.value = false;
    }

    async function updateTypeInfo(selected_type_data){
        type_details.value = selected_type_data;
        console.log(type_details.value);
    }

    function toggleSnapping(enabled){
        polyLineDrawer.snapping_enabled = enabled;
    }

    async function deleteObject(){
        try {
            await objectModificator.deleteObject();
            canvasDomElement.dispatchEvent(customEvents.objectDeleted);

        } catch (error) {
            canvasDomElement.dispatchEvent(customEvents.errorDuringDeletion);
            console.error(`${error.name}: ${error.message}`);
            handleNotifications(error.message);
        }
    }

    async function switchType(type_details){
        if(type_details){
            objectModificator.switchType(type_details);
        }
    }

    async function loadAsset(id){
        const token = window.localStorage.getItem('climagruen_token');
        const plant_details = await axios.get(api_endpoints.plants_id(id), {
                                        headers: {
                                            'Authorization': `Bearer ${token}`
                                        }
        })
        .then((response) => response.data)
        .catch((error) => {
            console.log(error);
            return null;
        });

        if(!plant_details){
            asset_loader.discardAsset();
            handleNotifications(t('modeling.asset_loading_error'));
            return;
        }
        const necessary_details = {id: id,root_radius_bottom: plant_details.root_radius_bottom}

        if(asset_loader.loadedAssets[id]){
            asset_loader.load(id);
            asset_loader.current_type_details = necessary_details;
            asset_loader.startUserPlacement();
        }else{
            document.body.classList.add('loading-cursor');
            // Here we still have to define a strategy which lod should be loaded as default. 
            // lod 300 is obligatory when creating a vegeation type, hence one can be sure that there is a file
            const lod = 300; 
            const arrayBuffer = await axios.get(api_endpoints.plants_get_glb_id(id, lod), {
                                                headers: {
                                                    'Authorization': `Bearer ${token}`
                                                },
                                                responseType: 'arraybuffer'
            })
            .then((response) => response.data)
            .catch((error) => {
                console.log(error);
                return null;
            });

            if(arrayBuffer){
                    await asset_loader.loadArraybuffer(arrayBuffer, id, necessary_details);
                    asset_loader.startUserPlacement();
            }else{
                asset_loader.discardAsset();
                handleNotifications(t('modeling.asset_loading_error'))  
            }
            document.body.classList.remove('loading-cursor');
        }

    }

    async function loadAssetMix(id){
        const selected_mix = store.state.climagruen_types.vegetation_mix.find(mix => mix.id === id);
        asset_loader.current_asset_mix = selected_mix.plants.map((plant, index) => {
            const newPlant = {...plant};
            newPlant.spreading = selected_mix.spreading_values[index];
            return newPlant;
        });
        document.body.classList.add('loading-cursor');

        for (const component of selected_mix.plants) {
            if(asset_loader.loadedAssets[component.id]) continue;

            const token = window.localStorage.getItem('climagruen_token');
            // Here we still have to define a strategy which lod should be loaded as default. 
            // lod 300 is obligatory when creating a vegeation type, hence one can be sure that there is a file
            const lod = 300;
            const arrayBuffer = await axios.get(api_endpoints.plants_get_glb_id(component.id, lod), {
                                                headers: {
                                                    'Authorization': `Bearer ${token}`
                                                },
                                                responseType: 'arraybuffer'
            })
            .then((response) => response.data)
            .catch((error) => {
                console.log(error);
                return null;
            });

            if(arrayBuffer){
                await asset_loader.loadArraybuffer(arrayBuffer, component.id);
            }else{
                asset_loader.discardAsset();
                handleNotifications(t('modeling.asset_loading_error'));
                document.body.classList.remove('loading-cursor');
                return;
            }

        }
        document.body.classList.remove('loading-cursor');
        asset_loader.tryPlacingAssetMix();
    }

    function toggleIndividualAssetPlacement(value){
        asset_loader.individual_placement = value;
        if(value){
            asset_loader.startUserPlacement();
            asset_loader.disableRoofSelection();
        }else{
            asset_loader.stopUserPlacement();
            asset_loader.enableRoofSelection();
        }
    };

    function handlePolylineCompleted(){
        polyline_ready.value = true;
        current_instruction.value = t('modeling_instructions.closed_polygon')
    };

    const handlePlaneSelected = () => {
        current_instruction.value = t('modeling_instructions.start_polygon');
    };
    const handlePolylineStarted = () => {
        current_instruction.value = t('modeling_instructions.close_polygon');
    };

    const handleTerrainPointPicked = () => {
        console.log("Terrain point picked");
        current_instruction.value = t('modeling_instructions.terrain_height');
    };

    function startModificationWithPredefinedSelection(){
        modificationMode.value =true; 
        toggleModificationMode();
        const predefined_object = global_manager.green_roof_objects.getObjectById(global_manager.selectionManager.fixed_selection_id);
        objectModificator.selectedObject = predefined_object;
        // Use nextTick to ensure the component is mounted before setting selected_object
        nextTick(() => {
            canvasDomElement.dispatchEvent(customEvents.objectSelected);
        });
    }

    async function bakeGreenRoof(){
        current_instruction.value = t('modeling_instructions.select_plane_and_type');
        polyline_ready.value = false;
        polyLineDrawer.closePolyline();
        let vertices;
        const TOLERANCE = 1e-4;
        if(Math.abs(polyLineDrawer.y_offset) > TOLERANCE){
            polyLineDrawer.fixed_drawing_plane.position.y = polyLineDrawer.fixed_drawing_plane.position.y +polyLineDrawer.y_offset;
            polyLineDrawer.fixed_drawing_plane.updateMatrixWorld(true);
            const points_with_offset = polyLineDrawer.points.map(point => {
                return [point[0], point[1] + polyLineDrawer.y_offset, point[2]];
            });
            vertices = points_with_offset.flat();
        }else{
            vertices = polyLineDrawer.points.flat();
        }
        const existing_green_roof_object = polyLineDrawer.existing_green_roof_object;
        const drawing_plane = polyLineDrawer.fixed_drawing_plane;
        polyLineDrawer.resetDrawingInterface();
        console.log("existing_green_roof_object", existing_green_roof_object);
        if(existing_green_roof_object){
            try {
                await createComposedGreenRoofObject(drawing_plane, vertices,
                type_details.value, existing_green_roof_object, canvasDomElement);
                await saveProject();

            } catch (error) {
                // There could be different types of errors here and distinguish between the error notification for the user.
                console.error(`${error.name}: ${error.message}`);
                handleNotifications(t('modeling.hole_placement_error'));
                // switch... case (HolePlacementError)...
            }

        }else{
            try{
                const new_sub_green_roof = createGreenRoofObject(drawing_plane, vertices, type_details.value);
                const greenroofHydraulicType = store.state.climagruen_types.hydraulic_components.find(type => type.description === "greenroof");
                global_manager.registerNewObject(new_sub_green_roof, props.project_id, greenroofHydraulicType);
            }
            catch (error) {
                console.error(`${error.name}: ${error.message}`);
                handleNotifications(`${error.name}: ${error.message}`);
            }

        }
    }

    function bakeLineElement(line_element_index){
        // this is not connected with the database, the data transfer between toolbars-store-backend has to be adapted
        const plane = polyLineDrawer.fixed_drawing_plane;
        const vertices = polyLineDrawer.points;
        polyLineDrawer.resetDrawingInterface();
        const line_element_specs = store.state.climagruen_types.line_element_types[line_element_index].parameters

        // At this point we will have to distinguish between different cross-sections for the line element in order to create them correctly
        // or maybe better distinguish inside the createLineElement function!
        console.log(line_element_specs);
        const extruded_line = createLineElement(plane, vertices, line_element_specs);

        global_manager.scene.add(extruded_line);
    }

    async function handleObjectSelected (){
        console.log("Object Selected");
        current_instruction.value = t('modeling_instructions.possible_modifications');
        selected_object.value = {cg_type: objectModificator.selectedObject.userData.cg_type, cg_type_details: objectModificator.selectedObject.userData.cg_type_details};
    }
    async function handleObjectUnselected (){
        console.log("Object Unselected");
        current_instruction.value = t('modeling_instructions.select_object');
        selected_object.value = {cg_type: null};
    }

    function handleNotifications(message){
        current_notification.value = message;
        setTimeout(() => {
            current_notification.value = null;
        }, 4000); 
    }

    function triggerTerrainModeling(enabled){
        if(enabled){
            canvasDomElement.addEventListener(customEvents.terrainPointPicked.type ,handleTerrainPointPicked);
            objectModificator.startTerrainModeling();
            current_instruction.value = t('modeling_instructions.terrain_start');
        }else{
            canvasDomElement.removeEventListener(customEvents.terrainPointPicked.type ,handleTerrainPointPicked);
            current_instruction.value = t('modeling_instructions.select_object');
            objectModificator.endTerrainModeling()
        }
    }

    function setSigma(sigma){
        if(objectModificator.terrain_modeler){
            objectModificator.terrain_modeler.sigma = sigma;
            console.log(`Sigma set to ${sigma}`);
        }
    }

    function triggerElevation(enabled){
        if(enabled){
            objectModificator.startObjectElevation();
            current_instruction.value = t('');
        }else{
            current_instruction.value = t('modeling_instructions.select_object');
            objectModificator.endObjectElevation();
        }
    }

    const triggerFileUpload = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    async function uploadIfc(event){
        if(!event.target.files[0]) return;
        loading(true);
        const ifc_file = event.target.files[0];
        await global_manager.loadIfcAsFragments(ifc_file);
        const frag_files = global_manager.exportFragments();
        if(frag_files){
            const token = window.localStorage.getItem('climagruen_token');
            const formData = new FormData();
            formData.append("file_json", frag_files.json);
            formData.append("file_frag", frag_files.frag);
            axios.post(api_endpoints.upload_ifc(props.project_id),formData ,{
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'
                        }}
            ).then(response=>{
                console.log(response);
            })
            .catch(error => {
                console.log(error);
                handleNotifications(t('modeling.network_error'));
            })
            .finally(()=>{
                loading(false);
            });
        }
        else{
            loading(false);
            handleNotifications(t('modeling.ifc_error'));
        }
    }

    async function loadProjectIfcModel(){
        const token = window.localStorage.getItem('climagruen_token');

        // ifc model
        const ifc_fragments = await axios.get(api_endpoints.get_ifc_frag(props.project_id), 
                                                    {headers: {
                                                        'Authorization': `Bearer ${token}`,
                                                    },
                                                    responseType: 'arraybuffer'})
        .then(response=> response.data)
        .catch(error => {
            console.log(error);
            return null
        });

        const ifc_properties = await axios.get(api_endpoints.get_ifc_json(props.project_id), 
                                                    {headers: {
                                                        'Authorization': `Bearer ${token}`,
                                                    },
                                                    responseType: 'arraybuffer'})
        .then(response=> response.data)
        .catch(error => {
            console.log(error);
            return null
        });

        if(ifc_fragments && ifc_properties){
            await global_manager.loadIfcAsFragments(ifc_fragments, ifc_properties, true);
        }

        // other geometries
    }

    async function loadProjectCustomModel(){
        const token = window.localStorage.getItem('climagruen_token');

        // project glb-file
        const glb_file = await axios.get(api_endpoints.custom_model_glb(props.project_id), 
                                                    {headers: {
                                                        'Authorization': `Bearer ${token}`,
                                                    },
                                                    responseType: 'arraybuffer'})
        .then(response=> response.data)
        .catch(error => {
            console.log(error);
            if(error.response.status === 404){
                global_manager.createDefaultLevel();
            }
            return null
        });

        if(glb_file){
            await global_manager.loadCustomGeometries(glb_file, asset_loader);
        }
    }

    async function saveProject(){
        console.log("Saving project...");
        loading(true);
        // if(needsExit){
        //     await exitAllFunctions();
        // }
        const glb_file = await global_manager.exportCustomGeometries();

        if(!glb_file){
            loading(false);
            handleNotifications(t('modeling.export_error'));
        };
        const token = window.localStorage.getItem('climagruen_token');
        const formData = new FormData();
        formData.append("file_glb", glb_file);
        await axios.post(api_endpoints.custom_model_glb(props.project_id),formData ,{
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }}
        ).then(response=>{
            console.log(response);
            canvasDomElement.dispatchEvent(customEvents.exportComplete);
        })
        .catch(error => {
            console.log(error);
            handleNotifications(t('modeling.export_error'));
        })
        .finally(()=>{
            loading(false);
        });
    }

    async function loadProjectModels() {
        await Promise.all([
            loadProjectIfcModel(),
            loadProjectCustomModel()
        ]);
    }
    async function runSimulation(){
        loading(true);
        
        // Save the model before the simulation is triggered
        await saveProject();

        // create new simulation instance
        const timestamp = new Date().toISOString().replace(/[-:.]/g, '').replace('T', '_').slice(0, -5);
        const project_name = store.state.current_project.name;
        const simulation_name = `${project_name.replace(' ', '_')}_${timestamp}`;
        console.log(simulation_name);
        const simulation_data = {name:simulation_name, status: "torun", log: ''};

        const simulation = await axios.post(
            api_endpoints.simulations(props.project_id),
            simulation_data,
            {headers: { 'Authorization': `Bearer ${window.localStorage.getItem('climagruen_token')}`}},
        )
        .then(response => {
            console.log(response);
            return response.data;
        })
        .catch(error => {
            console.log(error);
            return null
        });

        if(!simulation){
            loading(false);
            handleNotifications(t('modeling.network_error'));
            console.error("Simulation could not be created. Please try again later.");
            return;
        };

        // create data necessary for simulation and write to database
        try {
            await global_manager.createDataForSimulation(props.project_id, simulation.id);
        } catch (error) {
            console.error(`${error.name}: ${error.message}`);
            handleNotifications(error.message);
        }
        loading(false);
    }

    const asset_placement_alert = () => {handleNotifications(t('modeling.asset_placement_alert'))}

    const closeAndStoreName = (name) =>{
        // make name available
        global_manager.current_green_roof_name = name;
        // close modal
        greenRoofNameModal.value = false;
    }

    function setAnnotations(value){
        global_manager.setAnnotationVisibility(value);
    }
    function setMeasurements(value){
        global_manager.toggleMeasurement(value);
        if(value){
            current_instruction.value = t('modeling_instructions.measurements');
        }else{
            current_instruction.value = null;
        }
    }

    function pickFaceForLevel(){
        canvasDomElement.addEventListener(customEvents.levelHeightSelected.type, handleHeightSelection);
        polyLineDrawer.startFacePicking();
    }

    function handleHeightSelection(){
        const selected_height = polyLineDrawer.level_height;
        polyLineDrawer.endFacePicking();
        canvasDomElement.removeEventListener(customEvents.levelHeightSelected.type, handleHeightSelection);
        levelModalRef.value.setLevelHeight(selected_height);
    }

    function updateLevels(levels){
        global_manager.updateLevels(levels);
        levels_threejs.value = global_manager.levels;
        saveProject();
    }

    async function toggleDrawOnLevels(level_data){
        if(level_data.enabled){
            polyLineDrawer.endDrawing();
            polyLineDrawer.current_level_id = level_data.level_id;
            polyLineDrawer.y_offset = level_data.offset;
            polyLineDrawer.startDrawingOnLevels();
        }else{
            await polyLineDrawer.endDrawingOnLevels();
            polyLineDrawer.startDrawing();
        }
    }
    async function setLevel(level_data){
        polyLineDrawer.current_level_id = level_data.level_id;
        if(level_data.enabled){ 
            await polyLineDrawer.endDrawingOnLevels()
            polyLineDrawer.startDrawingOnLevels()
        };
    }
    function updateOffset(offset){
        polyLineDrawer.y_offset = offset;
    }
</script>

<style scoped>
    #threed-canvas{
        position: relative;
        display: flex;
        width: inherit;
        height: inherit;
        transition: all var(--transition-time) ease;
    }

    #toolbar-container{
        position: absolute;
        display: flex;
        left: 0;
        top: 0;
        margin-top: 1rem;
        margin-left: 1rem;
        max-height: 100%;
        max-width: 100%;
        gap: 1rem;
    }
        .toolbar {
            border-radius: 5px;
            display: flex;
            justify-content: flex-start;
            gap: 1rem;
            z-index: 10;
        }

            .vertical{
                padding: 0.5rem;
                flex-direction: column;
                height: max-content;
                opacity: 0.5;
                transition: opacity 0.3s;
            }
            @media (max-height: 800px) {
                .vertical {
                    max-width: 125px;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-around;
                }
            }
            .horizontal{
                padding: 0.5rem;
                flex-direction: row;
                width: max-content;
                height: max-content;
                align-items: center;
            }
            @media (max-width: 1000px) {
                .horizontal {
                    flex-wrap: wrap;
                    max-width: 50vw;
                }
            }

        .toolbar:hover {
            opacity: 1;
        }

        .toolbar .btn {
            margin-bottom: 2px;
        }

        .active {
            border-color: #0a5bb3; /* Slightly darker border for depth */
            box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5); /* A subtle glow effect */
        }

/* Positioning for toolbar items */
.toolbar-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Sub-toolbar should float outside the vertical toolbar */
.sub-toolbar.horizontal {
  position: absolute;
  top: -0.375rem;
  left: 100%; /* Align it next to the vertical toolbar */
  margin-left: 0.5rem; /* Adjust space between the toolbar and sub-toolbar */
  background-color: #343a40; /* Match the main toolbar color */
  border-radius: 5px;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 1rem;
  z-index: 1000; /* Ensure it appears above other elements */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: Add shadow for depth */
}

/* Adjust button styles within sub-toolbar */
.sub-toolbar .btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem;
  background-color: #495057; /* Slightly different background for buttons */
  margin-bottom: 0px;
  outline: none;
  background: none;
}


.sub-toolbar .btn.active {
  background-color: #0a5bb3; /* Active state color */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); /* A subtle glow effect */
}


/* Container for the dropdown */
.levels-dropdown {
    position: relative; /* This is fine, the child (dropdown) will be positioned relative to this */
    display: inline-block;
}

/* Style for the dropdown list */
.levels-dropdown .dropdown-menu {
    display: none; /* Hidden by default */
    position: absolute;
    top: 100%; /* Ensures the dropdown appears directly below the button */
    left: 0; /* Aligns the dropdown to the left of the button */
    background-color: #343a40; /* Dark background matching toolbar */
    color: white;
    min-width: 200px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px;
    border-radius: 5px;
}

/* Show dropdown on hover or when expanded */
.levels-dropdown:hover .dropdown-menu,
.levels-dropdown .dropdown-toggle[aria-expanded="true"] + .dropdown-menu {
    display: block;
}

/* Style for individual level items */
.levels-dropdown .dropdown-item {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: white;
}

/* Hover effect for dropdown items */
.levels-dropdown .dropdown-item:hover {
    background-color: #495057; /* Slightly lighter for hover */
    border-radius: 3px;
}

/* Style for the visibility icon (eye/eye-slash) */
.visibility-icon {
    color: #6c757d; /* Grey color to match your preference */
    margin-right: 10px; /* Space between the icon and the level name */
    font-size: 1.2em; /* Slightly larger size for better visibility */
}

/* Button for each level toggle */
.levels-dropdown .btn {
    display: flex;
    align-items: center;
    background-color: transparent; /* No background to keep it clean */
    border: none; /* Remove border */
    padding: 5px 10px; /* Add some padding for touch-friendly size */
    color: white; /* Text color white to match the toolbar */
    text-align: left;
}

/* Button hover effect */
.levels-dropdown .btn:hover {
    background-color: #495057; /* Slight hover effect */
    border-radius: 3px;
}

/* Divider between "Toggle All" and individual levels */
.dropdown-divider {
    border-top: 1px solid #6c757d;
    margin: 5px 0;
}
  
</style>