<template>
    <form id="type-form" @submit.prevent="submitForm" class="bg-dark text-white p-3">
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                    <label for="common_name" class="form-label">{{ $t('type_forms.common_name') }}</label>
                    <input type="text" id="common_name" v-model="form_data.common_name" :placeholder="$t('type_forms.placeholder_name')" class="form-control" required>
                </div>
                <div class="mb-3">
                    <label for="scientific_name" class="form-label">{{ $t('type_forms.plant_form.scientific_name') }}</label>
                    <input type="text" id="scientific_name" v-model="form_data.scientific_name" :placeholder="$t('type_forms.placeholder_name')" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label for="description" class="form-label">{{ $t('type_forms.description') }}</label>
                    <input type="text" id="description" v-model="form_data.description" :placeholder="$t('type_forms.placeholder_name')" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label for="min_substrate_depth" class="form-label">{{ $t('type_forms.plant_form.min_substrate_depth') }}</label>
                    <input type="number" step="any" id="min_substrate_depth" v-model="form_data.min_substrate_depth" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label for="stock_height" class="form-label">{{ $t('type_forms.plant_form.stock_height') }}</label>
                    <input type="number" step="any" id="stock_height" v-model="form_data.stock_height" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label for="max_LAI" class="form-label">{{ $t('type_forms.plant_form.max_LAI') }}</label>
                    <input type="number" step="any" id="max_LAI" v-model="form_data.LAI_time_series" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label for="leaf_conductance" class="form-label">{{ $t('type_forms.plant_form.leaf_conductance') }}</label>
                    <input type="number" step="any" id="leaf_conductance" v-model="form_data.leaf_conductance" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                </div>
                <div class="mb-3">
                    <label for="asset_file_100" class="form-label">{{ $t('type_forms.plant_form.file_label_100') }}</label>
                    <input type="file" id="asset_file_100" @change="handleFileUpload($event, '100')" class="form-control" accept=".glb">
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label for="LAI_soil_cover" class="form-label">{{ $t('type_forms.plant_form.LAI_soil_cover') }}</label>
                    <input type="number" step="any" id="LAI_soil_cover" v-model="form_data.LAI_soil_cover" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label for="capacity_leaf_interception" class="form-label">{{ $t('type_forms.plant_form.capacity_leaf_interception') }}</label>
                    <input type="number" step="any" id="capacity_leaf_interception" v-model="form_data.capacity_leaf_interception" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                </div>
                <div class="mb-3">
                    <div class="label-tip">
                        <label for="r_1" class="form-label">{{ `${$t('type_forms.plant_form.r_1_verbal')} (${$t('type_forms.plant_form.r_1_symbolic')})` }}</label>
                        <span class="question-mark-icon" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                            <QuestionMark/>
                        </span>
                        <div v-if="showTooltip" class="tooltip bs-tooltip-top" role="tooltip">

                                <img class="explanatory-img" :src="image" alt="explanatory_image">
                        </div>
                    </div>
                    
                    <input type="number" step="any" id="r_1" v-model="form_data.root_radius_top" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label for="r_2" class="form-label">{{ `${$t('type_forms.plant_form.r_2_verbal')} (${$t('type_forms.plant_form.r_2_symbolic')})` }}</label>                
                    <input type="number" step="any" id="r_2" v-model="form_data.root_radius_bottom" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                </div>
                
                <div class="mb-3">
                    <label for="root_angle" class="form-label">{{ `${$t('type_forms.plant_form.root_angle_verbal')} (${$t('type_forms.plant_form.root_angle_symbolic')})` }}</label>                
                    <input type="number" step="any" id="root_angle" v-model="form_data.root_radius_angle" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label for="h" class="form-label">{{ `${$t('type_forms.plant_form.h_verbal')} (${$t('type_forms.plant_form.h_symbolic')})` }}</label>                
                    <input type="number" step="any" id="h" v-model="form_data.relative_root_depth" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                </div>
                <div class="mb-3">
                    <label for="asset_file_200" class="form-label">{{ $t('type_forms.plant_form.file_label_200') }}</label>
                    <input type="file" id="asset_file_200" @change="handleFileUpload($event, '200')" class="form-control" accept=".glb">
                </div>
                <div class="mb-3">
                    <label for="asset_file_300" class="form-label">{{ $t('type_forms.plant_form.file_label_300') }}</label>
                    <input type="file" id="asset_file_300" @change="handleFileUpload($event, '300')" class="form-control" accept=".glb" :required="create_new">
                </div>
            </div>
        </div>

        <button v-if="create_new" type="submit" class="btn btn-primary">{{ $t('type_forms.create_type') }} </button>
        <div v-else class="button-container">
            <button type="submit" class="btn btn-info">{{ $t('type_forms.modify_type') }}</button>
            <button type="button" class="btn btn-danger" @click="deleteType">{{ $t('type_forms.delete_type') }}</button>
        </div>
    </form>
</template>

<script setup>
    import axios from 'axios';
    import {api_endpoints} from '../../js/api_endpoints.js';
    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';
    import QuestionMark from '../icons/Questionmark.vue';
    import {ref} from 'vue';

    const { t } =  useI18n()

    const store = useStore();

    const emit = defineEmits(['fileUpload','success', 'failure']);
    
    defineExpose({disposeUploadedFile})

    const props = defineProps({
                                form_data: Object,
                                create_new: Boolean,
                                image: String,
                                
    })

    const showTooltip = ref(false);
    let asset_files = {};

    function handleFileUpload(event, lod) {
        asset_files[lod] = event.target.files[0];
        emit('fileUpload', event.target.files[0], lod);
    }

    function submitForm(){
        if(props.create_new){
            createType();
        }else{
            modifyType();
        }
    }

    function createType() {
        const token = window.localStorage.getItem('climagruen_token');
        console.log('Form submitted:', props.form_data);
        axios.post(api_endpoints.plants, props.form_data, {headers: {'Authorization': `Bearer ${token}`}})
            .then((response)=>{
                const plant_type_id = response.data.id;
                console.log('Form submitted:', props.form_data);
                if(Object.keys(asset_files).length === 0){
                    updateStore();
                    emitSuccess();
                    return;
                }

                for (const [lod, file] of Object.entries(asset_files)) {
                    const formData = new FormData();
                    formData.append("file", file);
                    console.log(formData);
                    axios.post(api_endpoints.plants_upload(plant_type_id, lod),formData, {headers: {'Authorization': `Bearer ${token}`,
                                                                                'Content-Type': 'multipart/form-data'}})
                    .then(()=>{
                        updateStore();
                        emitSuccess();
                    })
                    .catch(error =>{
                        console.log(error);
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                emitFailure(error);
            });        
    }

    function modifyType(){
        if (window.confirm(t('type_forms.modify_confirmation'))) {
            const token = window.localStorage.getItem('climagruen_token');
            const form_data_copy = {... props.form_data};
            const saved_id = form_data_copy.id;
            delete form_data_copy.id;
            delete form_data_copy.file_path;

            axios.put(api_endpoints.plants_id(saved_id), form_data_copy, {headers: {'Authorization': `Bearer ${token}`}})
                .then((response)=>{
                    console.log(response.data);
                    console.log('Form submitted:', form_data_copy);

                    if(Object.keys(asset_files).length === 0){
                        updateStore();
                        emitSuccess();
                        return;
                    }

                    for (const [lod, file] of Object.entries(asset_files)) {
                        const formData = new FormData();
                        formData.append("file", file);
                        console.log(formData);
                        axios.post(api_endpoints.plants_upload(saved_id, lod),formData, {headers: {'Authorization': `Bearer ${token}`,
                                                                                    'Content-Type': 'multipart/form-data'}})
                        .then(()=>{
                            updateStore();
                            emitSuccess();
                        })
                        .catch(error =>{
                            console.log(error);
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    emitFailure(error);
                });

            
        }
    }

    function deleteType(){
        if (window.confirm(t('type_forms.delete_confirmation'))) {
            const token = window.localStorage.getItem('climagruen_token');

            axios.delete(api_endpoints.plants_id(props.form_data.id), {headers: {'Authorization': `Bearer ${token}`}})
                .then((response)=>{
                    console.log(response.data);
                    updateStore();
                    emitSuccess();
                    document.getElementById("type-form").reset();
                })
                .catch((error) => {
                    console.log(error);
                    emitFailure(error);
                });
        }
    }

    function updateStore(){
        store.dispatch('getVegetationTypes')
    }

    function disposeUploadedFile(){
        const file_input_100 = document.getElementById("asset_file_100");
        file_input_100.value = "";
        const file_input_200 = document.getElementById("asset_file_200");
        file_input_200.value = "";
        const file_input_300 = document.getElementById("asset_file_300");
        file_input_300.value = "";
    }

    function emitSuccess(){
        emit('success');
    }

    function emitFailure(error){
        let message;
        try{
            message = error.request.responseText;
        }
        catch(error){
            console.log(error)
            message = "";
        }
        console.log(message);
        emit('failure', message);
    }
</script>

<style scoped>
    .bg-dark {
        border-radius: 5px;
    }
    .button-container{
        display: flex;
        gap: 1rem;
    }
    .explanatory-img{
        max-height: 300px;
        width: auto;
    }

    .question-mark-icon {
        cursor: pointer;
        position: relative;
        display: inline-block;
    }

    .tooltip {
        position: absolute;
        transform: translateY(-100%);
        z-index: 1000;
        display: block;
        visibility: visible;
        opacity: 1;
        padding: 0; /* Removes default padding */
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

.label-tip{
    display: flex;
    gap: 0.5rem;
}

</style>