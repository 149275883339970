<script setup>
import TypeDeclaration from '@/components/TypeDeclaration.vue';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';

const route = useRoute();
</script>

<template>
    <TypeDeclaration :type="route.params.type"></TypeDeclaration>
</template>