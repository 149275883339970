<template>
    <div class="custom-modal">
        <div class="modal-dialog">
            <div class="modal-content bg-dark text-white">
                <div class="modal-header d-flex justify-content-between mb-3">
                    <h4 class="modal-title">{{ $t('welcome_page.modify_project') }}</h4>
                    <button class="close btn-close btn-close-white" @click="closeModal"></button>
                </div>
                <div class="modal-body d-flex p-3">
                    <!-- Left panel with project properties -->
                    <PropertiesPanel v-if="project_details != null" 
                        :project_details="project_details"
                        @update-success="handleProjectUpdateSuccess"
                        @update-fail="handleProjectUpdateFailure"
                        @update-geo-fail="handleGeoUpdateFailure"   
                    />
          
                    <!-- Right panel with tabs -->
                    <WeatherDataPanel v-if="show_weather_panel" :block_rain_available="block_rain_available"/>
                    <div v-else-if="!show_weather_panel && project_details != null" class="p-4">
                        Please, insert a valid location in order to generate weather data.
                    </div>
                </div>
            </div>
        </div>
    <!-- Success and Error messages -->
    <SuccessNotification v-if="success"/>
    <ErrorNotification v-if="failure" :message="error_message"/>

    </div>
</template>
  
<script setup>
    import { onBeforeMount, ref, computed } from 'vue';
    import { loadProjectDetails } from '@/js/api_calls';
    import { useStore } from 'vuex';

    import PropertiesPanel from './PropertiesPanel.vue';
    import WeatherDataPanel from './WeatherDataPanel.vue';
    import SuccessNotification from '@/components/notifications/SuccessNotification.vue';
    import ErrorNotification from '@/components/notifications/ErrorNotification.vue';

    const store = useStore();

    const project_details = ref(null);
    const success = ref(false);
    const failure = ref(false);
    const error_message = ref("");
    const project_id = ref(null);

    const props = defineProps({
        project_id: Number,
    });

    const show_weather_panel = computed(() => {
        return (
            project_details.value &&
            project_details.value.longitude != null &&
            project_details.value.latitude != null
        );
    });

    const block_rain_available = computed(() => {
        return (
            project_details.value &&
            project_details.value.country_code.toLowerCase() === 'de'
        );
    });

    onBeforeMount(async () => {
        project_id.value = props.project_id;
        project_details.value = await loadProjectDetails(project_id.value);
        show_weather_panel.value = !(project_details.value.longitude == null || project_details.value.latitude == null);
    });
  
    const emit = defineEmits(['close-modal']);
    const closeModal = () => {
        emit('close-modal');
    };

    const handleProjectUpdateSuccess = async() => {
        success.value = true;
        setTimeout(() => {
            success.value = false;
        }, 3000);
        store.dispatch('getProjects');
        project_details.value = await loadProjectDetails(project_id.value);
    };

    const handleProjectUpdateFailure = (message) => {
        error_message.value = message;
        failure.value = true;
        setTimeout(() => {
            failure.value = false;
        }, 3000);
    };
    const handleGeoUpdateFailure = async (message) => {
        error_message.value = message;
        failure.value = true;
        setTimeout(() => {
            failure.value = false;
        }, 3000);
        store.dispatch('getProjects');
        project_details.value = await loadProjectDetails(project_id.value);
    };

</script>
  
<style scoped>
    .modal-header{
        padding-left: 1rem;
    }
    .modal-content {
        border-radius: 0.5rem;
        min-width: 500px;
        padding: 1rem;
        max-height: 95vh;
        max-width: 95vw;
    }
</style>
  