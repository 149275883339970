<template>
   <!-- Dropdown menu for all combinations of D and T -->
   <select v-if="props.simulation_type === 'extreme'" class="form-select mb-3 align-right" v-model="selectedCombination" @change="handleSelection">
      <option :value="null" disabled>{{ $t('results.select_extreme_event') }}</option>
      <option v-for="(option, index) in combinations" :key="index" :value="option">
        {{ option }}
      </option>
    </select>

    <select class="form-select mb-3 align-right" v-model="selectedChart" @change="updateChart">
        <option :value="null" disabled>{{ $t('results.select_diagram') }}</option>
        <option value="irrigation">{{ $t('results.irrigation_chart_title') }}</option>
        <option value="water_levels">{{ $t('results.water_level_chart_title') }}</option>
        <option value="water_throughput">{{ $t('results.water_throughput_chart_title') }}</option>
    </select>
    
    <div ref="chartRef" class="chart"></div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import * as echarts from 'echarts';
    import { useI18n } from 'vue-i18n';

    const { t } =  useI18n();


    const selectedChart = ref(null);
    const chartRef = ref(null);
    let chartInstance;
    const chart_data = {};

    const selectedCombination = ref(null);



    const props = defineProps({
        chart_data: Object,
        simulation_type: String
    });

    // Define durations (D) and return periods (T) data
    const durations = [
    '5 min', '10 min', '15 min', '20 min', '30 min', '45 min', '60 min', 
    '90 min', '2 h', '3 h', '4 h', '6 h', '9 h', '12 h', '18 h', '24 h', '48 h', '72 h'
    ];
    const returnPeriods = [1, 2, 5, 10, 20, 30, 50, 100];

    // Create an array to store all combinations of D and T
    const combinations = ref([]);

    // Generate all D, T combinations and store them in the `combinations` array
    const generateCombinations = () => {
    durations.forEach(duration => {
        returnPeriods.forEach(period => {
        combinations.value.push(`D = ${duration}, T = ${period} Jahr(e)`);
        });
    });
    };

    function handleSelection() {
    console.log('Selected combination:', selectedCombination.value);
    }

    onMounted(()=>{
        chartInstance = echarts.init(chartRef.value, 'light');
        console.log(props.chart_data);
        setupCharts();
        updateChart();
        generateCombinations(); // Populate dropdown options with combinations of D and T
        selectedCombination.value = combinations.value[0]; // Set the default selected combination

    })

    const setupCharts = () => {
        setupIrrigationChart(props.chart_data.irrigation);
        setupWaterLevelsChart(props.chart_data.water_levels);
        setupThroughputChart(props.chart_data.throughput);
    };

    function updateChart() {
        let option;
        chartInstance.clear();
        switch (selectedChart.value) {
            case 'irrigation':
                option = chart_data['irrigation'];
                break;
            case 'water_levels':
                option = chart_data['water_levels'];
                break;
            case 'water_throughput':
                option = chart_data['water_throughput'];
                break;
            default:
                option = chart_data['irrigation'];
                selectedChart.value = 'irrigation';
        }
        chartInstance.setOption(option);
        chartInstance.resize();
    }


    // Generate timestamps for each hour over a year
    const generateTimestamps = () => {
        const timestamps = [];
        const startDate = new Date('2023-01-01T00:00:00Z');
        for (let i = 0; i < 8760; i++) { // 8760 hours in a year
            const date = new Date(startDate.getTime() + i * 3600000); // 3600000 ms in an hour
            timestamps.push(date.toISOString());
        }
        return timestamps;
    };

    const setupIrrigationChart = (data)=>{
        const series_data = [];
        const series_default_options = {
            type: 'line',
            smooth: true,
            symbol: 'none',
        };
        const hourly_timestamps = generateTimestamps();

        for (const [key, value] of Object.entries(data)) {
            const irrigation_data = hourly_timestamps.map((timestamp, index) => [timestamp, value[index]]);
            series_data.push({ ...series_default_options, name: key, data: irrigation_data });
        }

        console.log(series_data)

        const option = {
                        backgroundColor: '#FFFFFF', // Set background to white

                        tooltip: {
                            trigger: 'axis',
                            position: function (pt) {
                            return [pt[0], '10%'];
                            }
                        },
                        legend: {
                            data: series_data.map(series => series.name),
                            top: '40px'
                        },
                        toolbox: {
                            feature: {
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                            restore: {},
                            saveAsImage: {}
                            }
                        },
                        xAxis: {
                            type: 'time',
                            boundaryGap: false
                        },
                        yAxis: {
                            name: t('results.irrigation_chart_y'),
                            type: 'value',
                            boundaryGap: [0, '10%']
                        },
                        dataZoom: [
                            {
                            type: 'inside',
                            start: 0,
                            end: 100
                            },
                            {
                            start: 0,
                            end: 100
                            }
                        ],
                        series: series_data
        };
        chart_data['irrigation'] = option;
    }

    const setupWaterLevelsChart = (data)=>{
        const series_data = [];
        const series_default_options = {
            type: 'line',
            smooth: true,
            symbol: 'none',
        };
        const hourly_timestamps = generateTimestamps();

        for (const [key, value] of Object.entries(data)) {
            const irrigation_data = hourly_timestamps.map((timestamp, index) => [timestamp, value[index]]);
            series_data.push({ ...series_default_options, name: key, data: irrigation_data });
        }

        const option = {
                        backgroundColor: '#FFFFFF', // Set background to white

                        tooltip: {
                            trigger: 'axis',
                            position: function (pt) {
                            return [pt[0], '10%'];
                            }
                        },
                        legend: {
                            data: series_data.map(series => series.name),
                            top: '40px'
                        },
                        toolbox: {
                            feature: {
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                            restore: {},
                            saveAsImage: {}
                            }
                        },
                        xAxis: {
                            type: 'time',
                            boundaryGap: false
                        },
                        yAxis: {
                            name: t('results.water_level_chart_y'),
                            type: 'value',
                            boundaryGap: [0, '10%']
                        },
                        dataZoom: [
                            {
                            type: 'inside',
                            start: 0,
                            end: 100
                            },
                            {
                            start: 0,
                            end: 100
                            }
                        ],
                        series: series_data
        };
        chart_data['water_levels'] = option;
    }

    const setupThroughputChart = (data)=>{
        const series_data = [];
        const series_default_options = {
            type: 'line',
            smooth: true,
            symbol: 'none',
        };
        const hourly_timestamps = generateTimestamps();

        for (const [key, value] of Object.entries(data)) {
            const irrigation_data = hourly_timestamps.map((timestamp, index) => [timestamp, value[index]]);
            series_data.push({ ...series_default_options, name: key, data: irrigation_data });
        }

        const option = {
                        backgroundColor: '#FFFFFF', // Set background to white

                        tooltip: {
                            trigger: 'axis',
                            position: function (pt) {
                            return [pt[0], '10%'];
                            }
                        },
                        legend: {
                            data: series_data.map(series => series.name),
                            top: '40px'
                        },
                        toolbox: {
                            feature: {
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                            restore: {},
                            saveAsImage: {}
                            }
                        },
                        xAxis: {
                            type: 'time',
                            boundaryGap: false
                        },
                        yAxis: {
                            name: t('results.water_throughput_chart_y'),
                            type: 'value',
                            boundaryGap: [0, '10%']
                        },
                        dataZoom: [
                            {
                            type: 'inside',
                            start: 0,
                            end: 100
                            },
                            {
                            start: 0,
                            end: 100
                            }
                        ],
                        series: series_data
        };
        chart_data['water_throughput'] = option;
    }

    const clearChart = ()=>{
        chartInstance.clear();
    }
</script>

<style scoped>
.align-right{
    width: max-content;
    align-self: flex-end;
}
</style>