<template>
    <div class="overlay">
        <div class="alert alert-success" role="alert">
            {{ $t('type_forms.success') }}
        </div>
    </div>
</template>

<style scoped>
    .overlay {
        position: fixed;
        top: 1rem;
        left: 50%;
        transform: translateX(-50%);
        /* Remove width and height to allow the content to dictate the size */
        display: flex;
        justify-content: center;
        align-items: flex-start;
        color: white;
    }
</style>