<template>
    <div class="custom-modal">
        <div class="hydraulics-modal bg-dark text-white">
            <div class="modal-header">
                <div class="header-wrapper">
                    <h3 class="modal-title">{{ $t('hydraulics.modal_title') }}</h3>
                    <button class="close btn-close btn-close-white" @click="closeModal"></button>
                </div>

                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: activeTab === 'components' }"
                            @click="activeTab = 'components'">{{ $t('hydraulics.components') }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: activeTab === 'connections' }"
                            @click="switchToConnections">{{ $t('hydraulics.connections') }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: activeTab === 'schema' }" @click="switchToSchema">{{
                            $t('hydraulics.schema') }}</a>
                    </li>
                </ul>
            </div>
            <div class="modal-body">
                <div v-show="activeTab === 'components'" class="tab-content">
                    <HydraulicComponents ref="HydraulicComponentsRef" />
                </div>
                <div v-show="activeTab === 'connections'" class="tab-content">
                    <HydraulicConnections ref="HydraulicConnectionsRef" :connections="hydraulicConnections" :components="hydraulicComponents"/>
                </div>
                <div v-show="activeTab === 'schema'" class="tab-content">
                    <HydraulicConnectionsSchema ref="HydraulicConnectionsSchemaRef" :connections="hydraulicConnections" :components="hydraulicComponents" />
                </div>

                <button v-if="activeTab === 'connections'" class="btn btn-primary w-100 mt-5" @click="save"
                    :title="$t('hydraulics.save')">
                    <font-awesome-icon icon="fa-solid fa-save" />
                </button>
                <button v-else-if="activeTab === 'schema'" class="btn btn-primary w-100 mt-5" @click="save"
                    :title="$t('hydraulics.save')">
                    <font-awesome-icon icon="fa-solid fa-save" />
                </button>
                <button v-else class="btn btn-primary w-100 mt-5" @click="switchToConnections"
                    :title="$t('hydraulics.next')">
                    <font-awesome-icon icon="fa-solid fa-arrow-right" />
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import HydraulicComponents from './HydraulicComponents.vue';
import HydraulicConnections from './HydraulicConnections.vue';
import HydraulicConnectionsSchema from './HydraulicConnectionsSchema.vue'; // Import the new schema component
import axios from 'axios';
import { api_endpoints } from '@/js/api_endpoints';
import { useRoute } from 'vue-router';

const route = useRoute();

const activeTab = ref('components');
const HydraulicComponentsRef = ref(null);
const HydraulicConnectionsRef = ref(null);
const HydraulicConnectionsSchemaRef = ref(null);  // Reference for schema component

let modeledAreas, loadedConnections;

onBeforeMount(async () => {
    modeledAreas = await getAreas();
    await loadHydraulicComponents();
    await loadHydraulicConnections();
});

const emit = defineEmits(['close-modal']);
const closeModal = () => {
    emit('close-modal');
};

const save = async () => {
    if (!HydraulicConnectionsRef.value.validateInput()) return;
    await updateHydraulicConnectionsInDatabase();
    emit('close-modal');
};

const switchToConnections = async () => {
    if (!HydraulicComponentsRef.value.validateInput()) return;
    await updateHydraulicComponentsInDatabase();
    await loadHydraulicComponents();
    const components = HydraulicComponentsRef.value.getComponents();
    HydraulicConnectionsRef.value.updateConnections(components);
    activeTab.value = 'connections';
};

const switchToSchema = async () => {
    // Clear existing schema data
    HydraulicConnectionsSchemaRef.value.clearSchema();

    // Fetch components and connections
    const components = HydraulicComponentsRef.value.getComponents();
    const connections = HydraulicConnectionsRef.value.getConnections();

    // Generate new schema
    HydraulicConnectionsSchemaRef.value.generateSchema(connections, components);
    activeTab.value = 'schema';
};

async function getAreas() {
    const token = window.localStorage.getItem('climagruen_token');
    return axios.get(
        api_endpoints.areas(route.params.id),
        { headers: { 'Authorization': `Bearer ${token}` } }
    )
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.log(error);
        });
}

async function loadHydraulicComponents() {
    const token = window.localStorage.getItem('climagruen_token');

    await axios.get(
        api_endpoints.hydraulic_elements(route.params.id),
        { headers: { 'Authorization': `Bearer ${token}` } }
    )
        .then(response => {
            const components = response.data.map(component => {
                const parsedComponent = JSON.parse(component.data);
                if (parsedComponent.area_id) {
                    const area = modeledAreas.find(area => area.id === parsedComponent.area_id);
                    parsedComponent.area = area.area;
                }
                parsedComponent.id = component.id;
                return parsedComponent;
            });

            HydraulicComponentsRef.value.setComponents(components);
        })
        .catch(error => {
            console.log(error);
        });
}

async function loadHydraulicConnections() {
    const token = window.localStorage.getItem('climagruen_token');

    await axios.get(
        api_endpoints.hydraulic_connections(route.params.id),
        { headers: { 'Authorization': `Bearer ${token}` } }
    )
        .then(response => {
            loadedConnections = response.data;
            const components = HydraulicComponentsRef.value.getComponents();
            HydraulicConnectionsRef.value.setConnections(loadedConnections, components);
        })
        .catch(error => {
            console.log(error);
        });
}

async function updateHydraulicComponentsInDatabase() {
    const token = window.localStorage.getItem('climagruen_token');

    const deleteComponents = HydraulicComponentsRef.value.getDeletedComponents();
    for (const component of deleteComponents) {
        await axios.delete(
            api_endpoints.hydraulic_elements_id(route.params.id, component.id),
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
            .catch(error => {
                console.log(error);
            });
    }
    HydraulicComponentsRef.value.clearDeletedComponents();

    const components = HydraulicComponentsRef.value.getComponents();
    for (const component of components) {
        const type_id = component.type_id;
        if (component.id) {
            const component_copy = { ...component };
            delete component_copy.type_id;
            delete component_copy.id;
            await axios.put(
                api_endpoints.hydraulic_elements_id(route.params.id, component.id),
                { data: JSON.stringify(component_copy), type_id: type_id },
                { headers: { 'Authorization': `Bearer ${token}` } }
            )
                .catch(error => {
                    console.log(error);
                });
        } else {
            const component_copy = { ...component };
            delete component_copy.type_id;
            const component_data = JSON.stringify(component_copy);

            await axios.post(
                api_endpoints.hydraulic_elements(route.params.id),
                { geometry_asset_id: 0, data: component_data },
                { params: { type_id: type_id }, headers: { 'Authorization': `Bearer ${token}` } }
            )
                .catch(error => {
                    console.log(error);
                });
        }
    }
}

async function updateHydraulicConnectionsInDatabase() {
    const token = window.localStorage.getItem('climagruen_token');
    const connections = HydraulicConnectionsRef.value.getConnections();

    const connection_ids_in_db = loadedConnections.map(item => item.id);

    for (const connection_id of connection_ids_in_db) {
        await axios.delete(
            api_endpoints.hydraulic_connections_id(route.params.id, connection_id),
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
            .catch(error => {
                console.log(error);
            });
    }

    for (const connection of connections) {
        const type_id = connection.input.type_id;
        const component_copy = { ...connection.input };
        delete component_copy.type_id;
        delete component_copy.id;
        await axios.put(
            api_endpoints.hydraulic_elements_id(route.params.id, connection.input.id),
            { data: JSON.stringify(component_copy), type_id: type_id },
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
            .catch(error => {
                console.log(error);
            });

        for (const output_component of connection.outputs) {
            const connection_data = {
                input_type: connection.input.area_id ? 'area' : 'hydraulic',
                input_id: connection.input.id,
                output_type: output_component.area_id ? 'area' : 'hydraulic',
                output_id: output_component.id
            };
            await axios.post(
                api_endpoints.hydraulic_connections(route.params.id),
                connection_data,
                { headers: { 'Authorization': `Bearer ${token}` } }
            )
                .catch(error => {
                    console.log(error);
                });
        }
    }
}
</script>

<style scoped>
.hydraulics-modal {
    width: 60vw;
    max-height: 95vh;
    max-width: 1300px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.modal-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 16px 0;
    gap: 20px;
    border-bottom: 1px solid #e5e5e5;
}

.header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    width: 100%;
}

.modal-title {
    font-size: 1.25rem;
    font-weight: 500;
}



.nav-tabs {
    display: flex;
    margin-bottom: 0;
    border: none;
    position: sticky;
    top: 0;
    z-index: 1;
}

.nav-item {
    margin-right: 8px;
}

.nav-link {
    padding: 8px 16px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    color: #f8f9fa;
    text-decoration: none;
}

.nav-link.active {
    background-color: white;
    border-color: #e5e5e5 #e5e5e5 #fff;
    color: #495057;
}

.modal-body {
    padding: 2rem 1rem;
    overflow-y: hidden;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.tab-content {

    flex: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}
</style>