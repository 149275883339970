<template>
    <form id="type-form" @submit.prevent="submitForm" class="bg-dark text-white p-3">
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                    <label for="common_name" class="form-label">{{ $t('type_forms.common_name') }}</label>
                    <input type="text" id="common_name" v-model="form_data.common_name" :placeholder="$t('type_forms.placeholder_name')" class="form-control" required>
                </div>
                <div class="mb-3">
                    <label for="description" class="form-label">{{ $t('type_forms.description') }}</label>
                    <input type="text" id="description" v-model="form_data.description" :placeholder="$t('type_forms.placeholder_name')" class="form-control">
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label for="min_depth" class="form-label">{{ $t('type_forms.material_form.min_depth') }}</label>
                    <input type="number" step="any" id="min_depth" v-model="form_data.min_depth" :placeholder="$t('type_forms.placeholder_number')" class="form-control">
                </div>
                <div class="mb-3">
                    <label for="max_depth" class="form-label">{{ $t('type_forms.material_form.max_depth') }}</label>
                    <input type="number" step="any" id="max_depth" v-model="form_data.max_depth" :placeholder="$t('type_forms.placeholder_number')" class="form-control">
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label for="density" class="form-label">{{ $t('type_forms.material_form.density') }}</label>
                    <input type="number" step="any" id="density" v-model="form_data.density" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                </div>
            </div>
        </div>

        <div class="form-floating" data-bs-theme="dark">
            <select id="type-select" class="form-select" v-model="form_data.material_type" required>
                <option v-for="mat_type in mat_types" :value="mat_type.value">{{ mat_type.display_name }}</option>
            </select>
            <label for="type-select">{{ $t('type_forms.material_form.type') }}</label>
        </div>
        <br/>

        <div>
            <button class="mb-3 btn btn-outline-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleForm" aria-controls="collapsibleForm">
                <font-awesome-icon icon="fa-solid fa-table-list"/>
                {{ $t('type_forms.material_form.purpose_specific_data') }}        
            </button>
            <div id="collapsibleForm">
                <!-- Add type dependent forms here -->
                <MaterialsFormSubstrateData v-if="form_data.material_type == 'substrate'" :sub_form_data="form_data.substrate_data" />
                <MaterialsFormStorageData v-if="form_data.material_type == 'storage'" :sub_form_data="form_data.storage_data" />
                <MaterialsFormIrrigationData v-if="form_data.material_type == 'irrigation'" :sub_form_data="form_data.irrigation_data" />
                <MaterialsFormCoveringData v-if="form_data.material_type == 'covering'" :sub_form_data="form_data.covering_data" />
            </div>
        </div>
        <br/>

        <button v-if="create_new" type="submit" class="btn btn-primary">{{ $t('type_forms.create_type') }} </button>
        <div v-else class="button-container">
            <button type="submit" class="btn btn-info">{{ $t('type_forms.modify_type') }}</button>
            <button type="button" class="btn btn-danger" @click="deleteType">{{ $t('type_forms.delete_type') }}</button>
        </div>
    </form>

</template>

<script setup>
    import axios from 'axios';
    import {api_endpoints} from '../../js/api_endpoints.js';
    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';
    import MaterialsFormSubstrateData from "./MaterialsFormSubstrateData.vue";
    import MaterialsFormStorageData from "./MaterialsFormStorageData.vue";
    import MaterialsFormIrrigationData from "./MaterialsFormIrrigationData.vue";
    import MaterialsFormCoveringData from "./MaterialsFormCoveringData.vue";
    const { t } =  useI18n();

    const store = useStore();
    
    const props = defineProps({
                                form_data: Object,
                                create_new: Boolean,
                                image: String,
                                
    })

    const emit = defineEmits(['success', 'failure']);

    const mat_types = [
                        {display_name: t('type_forms.material_form.substrate'), value: "substrate"},
                        {display_name:t('type_forms.material_form.storage'), value: "storage"},
                        {display_name: t('type_forms.material_form.irrigation'), value: "irrigation"},
                        {display_name: t('type_forms.material_form.vegetation_a'), value: "vegetation_a"},
                        {display_name: t('type_forms.material_form.vegetation_b'), value: "vegetation_b"},
                        {display_name: t('type_forms.material_form.vegetation_c'), value: "vegetation_c"},
                        {display_name: t('type_forms.material_form.vegetation_d'), value: "vegetation_d"},
                        {display_name: t('type_forms.material_form.vegetation_e'), value: "vegetation_e"},
                        {display_name: t('type_forms.material_form.vegetation_f'), value: "vegetation_f"},
                        {display_name: t('type_forms.material_form.non_vegetation_cover'), value: "covering"},                  
                        {display_name: t('type_forms.material_form.other'), value: "other"}                  
    ]

    function submitForm(){
        if(props.create_new){
            createType();
        }else{
            modifyType();
        }
    }

    function createType() {
        const token = window.localStorage.getItem('climagruen_token');

        axios.post(api_endpoints.materials, props.form_data, {headers: {'Authorization': `Bearer ${token}`}})
            .then(()=>{
                console.log('Form submitted:', props.form_data);
                updateStore();
                emitSuccess();
            })
            .catch((error) => {
                console.log(error);
                emitFailure(error);
            });   
    }

    function modifyType(){
        if (window.confirm(t('type_forms.modify_confirmation'))) {
            const token = window.localStorage.getItem('climagruen_token');
            const form_data_copy = {... props.form_data};
            const saved_id = form_data_copy.id;
            delete form_data_copy.id;

            axios.put(api_endpoints.materials_id(saved_id), form_data_copy, {headers: {'Authorization': `Bearer ${token}`}})
                .then((response)=>{
                    console.log(response.data);
                    console.log('Form submitted:', form_data_copy);
                    updateStore();
                    emitSuccess();
                })
                .catch((error) => {
                    console.log(error);
                    emitFailure(error);
                });
        }
    }

    function deleteType(){
        if (window.confirm(t('type_forms.delete_confirmation'))) {
            const token = window.localStorage.getItem('climagruen_token');

            axios.delete(api_endpoints.materials_id(props.form_data.id), {headers: {'Authorization': `Bearer ${token}`}})
                .then((response)=>{
                    console.log(response.data);
                    updateStore();
                    emitSuccess();
                    document.getElementById("type-form").reset();
                })
                .catch((error) => {
                    console.log(error);
                    emitFailure(error);
                });
        }
    }

    function updateStore(){
        store.dispatch('getMaterialTypes')
    }

    function emitSuccess(){
        emit('success');
    }
    function emitFailure(error){
        let message;
        try{
            message = error.request.responseText;
        }
        catch(error){
            console.log(error)
            message = "";
        }
        console.log(message);
        emit('failure', message);
    }
</script>

<style scoped>
    .bg-dark {
        border-radius: 5px;
    }
    .button-container{
        display: flex;
        gap: 1rem;
    }
    .explanatory-img{
        max-height: 300px;
        width: auto;
    }

    .question-mark-icon {
        cursor: pointer;
        position: relative;
        display: inline-block;
    }

    .tooltip {
        position: absolute;
        transform: translateY(-100%);
        z-index: 1000;
        display: block;
        visibility: visible;
        opacity: 1;
        padding: 0; /* Removes default padding */
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

.label-tip{
    display: flex;
    gap: 0.5rem;
}

</style>