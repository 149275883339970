<template>
    <img :src="imageUrl" class="background"/>
    <div class="background"></div>
    <div class="wrapper">
        <Welcome class="welcome"/>
        <div class="projects">
            <ProjectManagement/>
        </div>
        
    </div>


</template>

<script setup>
    import Welcome from '@/components/Welcome.vue';
    import ProjectManagement from '@/components/ProjectManagement.vue';
    const imageUrl = "/open_terra_background.png"
</script>

<style scoped>
.wrapper{
    grid-area: main;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    overflow: hidden;
    z-index: 2;
}
    .welcome{
            width: 30vw;
    }
    .projects{
        height: 100%;
        width: 70vw;
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-self: flex-start;
        margin-top: 10vh;
    }
        
    .background {
        grid-area: main;
        /* background-image: url('open_terra_background.png'); */
        width: 100%;
        height: 100%; 
        /* background-position: center;
        background-repeat: no-repeat;
        background-size: cover; */
        object-fit: cover;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    .background::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to right, transparent, rgba(50,50,50,1));
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (max-width: 768px) {
        .wrapper{
            flex-direction: column;
            overflow-y: auto;
            gap: 2rem;
        }
        .welcome{
            width: 100%;
        }
        .background::after {
            background: linear-gradient(to bottom, transparent, rgba(50,50,50,1));
        }
        .projects{
            width: 100%;
            margin-top: 0;
        }

    }

</style>