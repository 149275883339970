const initial_state = {
                        vegetation: {
                                        common_name: '',
                                        scientific_name: '',
                                        description: '',
                                        min_substrate_depth: null,
                                        stock_height: null,
                                        max_LAI: null,
                                        LAI_time_series: null,
                                        leaf_conductance: null,
                                        LAI_soil_cover: null,
                                        capacity_leaf_interception: null,
                                        root_radius_bottom: null,
                                        root_radius_top: null,
                                        root_radius_angle: null,
                                        relative_root_depth: null,
                        },
                        vegetation_mix: {
                                        common_name: '',
                                        description: '',
                                        min_substrate_depth: null,
                                        stock_height: null,
                                        max_LAI: null,
                                        LAI_time_series: null,
                                        leaf_conductance: null,
                                        LAI_soil_cover: null,
                                        capacity_leaf_interception: null,
                                        root_radius_bottom: null,
                                        root_radius_top: null,
                                        root_radius_angle: null,
                                        relative_root_depth: null,
                                        plant_ids: [],
                                        spreading_values: [],

                        },
                        layers: {
                            enable: true,
                            common_name: '',
                            description: '',
                            flexible_layer: null,
                            layer1_material: null,
                            layer1_depth: null,
                            layer1_depth_editable: false,
                            layer1_material_editable: false,
                            layer2_material: null,
                            layer2_depth: null,
                            layer2_depth_editable: false,
                            layer2_material_editable: false,
                            layer3_material: null,
                            layer3_depth: null,
                            layer3_depth_editable: false,
                            layer3_material_editable: false,
                            layer4_material: null,
                            layer4_depth: null,
                            layer4_depth_editable: false,
                            layer4_material_editable: false,
                            layer5_material: null,
                            layer5_depth: null,
                            layer5_depth_editable: false,
                            layer5_material_editable: false,
                            layer6_material: null,
                            layer6_depth: null,
                            layer6_depth_editable: false,
                            layer6_material_editable: false,
                            layer7_material: null,
                            layer7_depth: null,
                            layer7_depth_editable: false,
                            layer7_material_editable: false,
                            layer8_material: null,
                            layer8_depth: null,
                            layer8_depth_editable: false,
                            layer8_material_editable: false,
                            layer9_material: null,
                            layer9_depth: null,
                            layer9_depth_editable: false,
                            layer9_material_editable: false,
                            layer10_material: null,
                            layer10_depth: null,
                            layer10_depth_editable: false,
                            layer10_material_editable: false,
                        },
                        materials: {
                            common_name: '',
                            description: '',
                            material_type: '',
                            min_depth: null,
                            max_depth: null,
                            density: null,
                            substrate_data: {},
                            storage_data: {},
                            irrigation_data: {},
                            covering_data: {}
                        }
}

export {initial_state}

                