<template>
    <div>
        <div ref="chart" class="chart"></div>
    </div>
</template>
  
<script setup>
    import { ref, onMounted } from 'vue'
    import * as echarts from 'echarts'

    import { useI18n } from 'vue-i18n';

    const { t } =  useI18n();
    
    // Reference to the chart DOM element
    const chart = ref(null)
    
    onMounted(() => {
        // Initialize the chart when the component is mounted
        const myChart = echarts.init(chart.value, 'light')
    
        const option = {
        tooltip: {
            trigger: 'item',
            formatter: '{b} <br/>{a}: {c}%',
        },
        legend: {
            top: 'top',
            data: [t('results.evaporation'), t('results.infiltration'), t('results.outlet')]
        },
        xAxis: {
            type: 'category',
            data: [t('results.before_intervention'), t('results.planned')]
        },
        yAxis: {
            type: 'value',
            min: 0,
            max: 100,
            axisLabel: {
            formatter: '{value} %'
            }
        },
        series: [
            {
            name: t('results.evaporation'),
            type: 'bar',
            stack: 'total',
            label: {
                show: true,
                position: 'inside'
            },
            data: [45, 31] // Example percentages
            },
            {
            name: t('results.infiltration'),
            type: 'bar',
            stack: 'total',
            label: {
                show: true,
                position: 'inside'
            },
            data: [55, 33] // Example percentages
            },
            {
            name: t('results.outlet'),
            type: 'bar',
            stack: 'total',
            label: {
                show: true,
                position: 'inside'
            },
            data: [null, 36] // Example percentages
            }
        ]
        }
    
        // Set the option for the chart
        myChart.setOption(option)
    
        // Ensure the chart resizes with the window
        window.addEventListener('resize', () => {
            myChart.resize()
        })
    })
    
  </script>
  
  <style>
  /* Optional styling for your chart container */
  </style>
  