import { createStore } from 'vuex';
import {api_endpoints} from '../js/api_endpoints.js';
import router from '../router';
import axios from 'axios';

const store = createStore({
    state () {
        return {
            current_user: null,
            isAuthenticated: false,
            projects: [],
            current_project: null,
            modeling_manager: null,
            climagruen_types: {
                vegetation: null,
                vegetation_mix: null,
                layers: null,
                materials: null,
                hydraulic_components: null,

                // !! The following have still to be included in the database. This is just a Mock-Up and might not be implemented even!!
                // For now we kicked out the modeling of line elements completely
                line_element_types: [{name: "Randstreifen", parameters:{h:0.2, b:0.5}},
                    {name: "Mulde (standard)", parameters:{h:0.75,b:1.5,h_1:0.5,b_1:0.2,alpha:50.0}}
                ]
            },
            simulation_data: {},
        }
    },

    mutations: {
        setModelingManager(state, modeling_manager){
            state.modeling_manager = modeling_manager;
        },
        updateUserInfo (state, user_info){
            state.current_user = user_info.info;
            state.isAuthenticated = user_info.auth;
        },
        updateProjects(state, project_list){
            state.projects = project_list;
        },
        updateProjectInfo(state, id){
            const project_info = state.projects.find(project => project.id === id);
            state.current_project = project_info;
        },
        updateClimagruenTypes(state, data){
            state.climagruen_types[data.type] = data.info
        },
        updateLineElementsParameter(state, data){
            state.climagruen_types.line_element_types[data.index].parameters = data.parameters;
        },
        storeSimulationData(state, data){
            state.simulation_data[data.id] = data.data;
        }
    },

    actions: {
        async initialize({dispatch, commit}){
            // This is a wrapper to retrieve all necessary information from the backend on initialization
            const success_user = await dispatch('getUserInfo');
            const success_projects = await dispatch('getProjects');

            if (!success_user){
                router.push({ name: 'login'});
                return;
            }

            if(router.currentRoute.value.name === 'login') router.push({ name: 'welcome'}); // this prevents that a user gets stuck on the login page if he is already logged in

            if(router.currentRoute.value.name === 'canvas'){
                // If the user is already on the canvas page, we have to update the project info
                const id = router.currentRoute.value.params.id;
                commit('updateProjectInfo', parseInt(id));
            }

            dispatch('getVegetationTypes'); // No await here... can load while the app is mounting
            dispatch('getVegetationMixTypes'); // No await here... can load while the app is mounting
            dispatch('getLayerTypes'); // No await here... can load while the app is mounting
            dispatch('getMaterialTypes'); // No await here... can load while the app is mounting
            dispatch('getHydraulicComponentTypes'); // No await here... can load while the app is mounting
        },

        async getUserInfo ({commit}) {
            const token = window.localStorage.getItem('climagruen_token');
            return axios.get(api_endpoints.users_me,{
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }}
                )
                .then(response => {
                    console.log(`User Info:`);
                    console.log(response.data);
                    commit('updateUserInfo', {info: response.data, auth: true});
                    return true;
                })
                .catch(error =>{
                    console.log(error);
                    commit('updateUserInfo', {info: null, auth: false});
                    return false;
            })
        },

        async getProjects ({commit}) {
            const token = window.localStorage.getItem('climagruen_token');
            return axios.get(api_endpoints.projects,{
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }}
                )
                .then(response => {
                    console.log(`Projects:`);
                    console.log(response.data);
                    commit('updateProjects', response.data);
                    return true;
                })
                .catch(error =>{
                    console.log(error);
                    commit('updateProjects', []);
                    return false;
            })
        },

        getVegetationTypes({commit}){
            const token = window.localStorage.getItem('climagruen_token');
            axios.get(api_endpoints.plants,{
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }}
                )
                .then(response => {
                    console.log(`Vegetation Types:`);
                    console.log(response.data);
                    commit('updateClimagruenTypes', {type: "vegetation", info: response.data});
                })
                .catch(error =>{
                    console.log(error);
            })
        },

        getVegetationMixTypes({commit}){
            const token = window.localStorage.getItem('climagruen_token');
            axios.get(api_endpoints.plants_cong,{
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }}
                )
                .then(response => {
                    console.log(`Vegetation Mix Types:`);
                    console.log(response.data);
                    commit('updateClimagruenTypes', {type: "vegetation_mix", info: response.data});
                })
                .catch(error =>{
                    console.log(error);
            })
        },

        getLayerTypes({commit}){
            const token = window.localStorage.getItem('climagruen_token');
            axios.get(api_endpoints.layers,{
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }}
                )
                .then(response => {
                    console.log(`Layer Types:`);
                    console.log(response.data);
                    commit('updateClimagruenTypes', {type: "layers", info: response.data});
                })
                .catch(error =>{
                    console.log(error);
            })
        },
        getMaterialTypes({commit}){
            const token = window.localStorage.getItem('climagruen_token');
            axios.get(api_endpoints.materials,{
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }}
                )
                .then(response => {
                    console.log(`Materials:`);
                    console.log(response.data);
                    commit('updateClimagruenTypes', {type: "materials", info: response.data});
                })
                .catch(error =>{
                    console.log(error);
            })
        },

        getHydraulicComponentTypes({commit}){
            const token = window.localStorage.getItem('climagruen_token');
            axios.get(api_endpoints.hydraulic_types,{
                        headers: {  'Authorization': `Bearer ${token}` }
                        })
                .then(response => {
                    console.log(`Hydraulic Components:`);
                    console.log(response.data);
                    commit('updateClimagruenTypes', {type: "hydraulic_components", info: response.data}); 
                })
                .catch(error => { console.log(error); })
        },

        async getProjectsHydraulics({commit}, id){
            // Get hydraulic elements related to this project and their connection
            
            // commit('updateProjectInfo', id);
        }
            
    }
})

export default store