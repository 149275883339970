<template>
    <div class="form-floating" data-bs-theme="dark">
        <select id="type-select" class="form-select  min-width" @change="emitSelectedType" v-model="selected_type_id" :disabled="(props.selectedObject.cg_type != objectTypes.standard) || terrainMode || elevationMode">
            <option v-for="cg_type in store.state.climagruen_types.layers" :value="cg_type.id">{{ cg_type.common_name }}</option>
        </select>
        <label for="type-select">{{ $t('toolbar.switch_type') }}</label>
    </div>

    <button class="btn btn-danger margin-right" @click="emitDeleteObject" :title="$t('toolbar.delete_object')" :disabled="!props.selectedObject.cg_type || terrainMode"><font-awesome-icon icon="fa-solid fa-trash"/></button>

    <input type="checkbox" v-model="elevationMode" id="elevationToggle" class="btn-check" @change="toggleElevationMode" :disabled="props.selectedObject.cg_type != objectTypes.standard"/>
    <label v-if="!elevationMode" class="btn btn-info" :class="{'active': elevationMode}" for="elevationToggle" :title="$t('toolbar.elevation_start')">
        <font-awesome-icon icon="fa-solid fa-up-down"/>
    </label>
    <label v-else class="btn btn-danger" :class="{'active': elevationMode}" for="elevationToggle" :title="$t('toolbar.elevation_end')">
        <font-awesome-icon icon="fa-solid fa-xmark"/>
    </label>

    <!-- Uncomment the following when action-stack and undo functionality for elevation is implemented: -->
    <!-- <div v-if="elevationMode" class="sub-toolbar">
        <button class="btn btn-info" @click="dispatchElevationConfirmed" :title="$t('toolbar.confirm_elevation')"><font-awesome-icon icon="fa-solid fa-check"/></button>
        <button class="btn btn-secondary" @click="dispatchUndoElevation" :title="$t('toolbar.undo')"><font-awesome-icon icon="fa-solid fa-rotate-left"/></button>
    </div> -->


    <input type="checkbox" v-model="terrainMode" id="terrainToggle" class="btn-check" @change="toggleTerrainMode" :disabled="props.selectedObject.cg_type != objectTypes.standard || elevationMode"/>
    <label v-if="!terrainMode" class="btn btn-info" :class="{'active': terrainMode}" for="terrainToggle" :title="$t('toolbar.terrain_drawing_start')">
        <font-awesome-icon icon="fa-solid fa-mountain"/>
    </label>
    <label v-else class="btn btn-danger" :class="{'active': terrainMode}" for="terrainToggle" :title="$t('toolbar.terrain_drawing_end')">
        <font-awesome-icon icon="fa-solid fa-xmark"/>
    </label>

    <div v-if="terrainMode" class="sub-toolbar">
        <button class="btn btn-info" @click="dispatchTerrainPointConfirmed" :title="$t('toolbar.confirm_terrain')"><font-awesome-icon icon="fa-solid fa-check"/></button>
        <button class="btn btn-secondary" @click="dispatchUndoTerrain" :title="$t('toolbar.undo')"><font-awesome-icon icon="fa-solid fa-rotate-left"/></button>

        <label for="sigmaRange" class="form-label">{{ $t('toolbar.sigma') }}</label>
        <input type="range" class="form-range" min="0.5" max="5.0" step="0.1" id="sigmaRange" :title="$t('toolbar.sigma_tooltip')" @change="updateSigma" v-model="sigma">
    </div>
</template>

<script setup>
    import {onMounted, ref, watch} from "vue";
    import {useStore} from "vuex";
    import { objectTypes, customEvents } from "../../js/modeling_utils.js";
    import {default_values} from "@/js/modeling_utils.js";
    import {loadLayerTypeDetails} from "@/js/api_calls.js";


    const emit = defineEmits(['deleteObject','typeSelectChange', 'updateSigma', 'terrainModeling', 'elevation']);
    const props = defineProps({
        selectedObject: Object,
    })

    const store = useStore()

    const selected_type_id = ref(null);

    // // Another functionality would be the movement of assets
    // const movement = ref(false);


    const sigma = ref(default_values.sigma);

    const terrainMode = ref(false);
    const elevationMode = ref(false);

    onMounted(() => {
        console.log("ModificationToolbar mounted");
    })

    watch(()=>props.selectedObject, ()=>{
        console.log("object watcher fired");
        console.log(props.selectedObject.cg_type_details);
        if(props.selectedObject.cg_type == objectTypes.standard){
            selected_type_id.value = props.selectedObject.cg_type_details.id;
        }else{
            selected_type_id.value = null;
        }
    })

    function emitDeleteObject() {
        emit('deleteObject');
    }

    async function emitSelectedType() {
        console.log(selected_type_id.value);
        const type_details = await loadLayerTypeDetails(selected_type_id.value)
        emit('typeSelectChange', type_details)
    }

    function updateSigma(){
        emit('updateSigma', sigma.value);
    }

    function dispatchTerrainPointConfirmed(){
        store.state.modeling_manager.domElement.dispatchEvent(customEvents.terrainPointConfirmed);
    }
    function dispatchUndoTerrain(){
        store.state.modeling_manager.domElement.dispatchEvent(customEvents.undoTerrain);
    }

    function toggleTerrainMode() {
        if(terrainMode.value){
            emit('terrainModeling', true);
            emit('updateSigma', sigma.value);
        }else{
            emit('terrainModeling', false)
        }
    }
    
    function toggleElevationMode() {
        if(elevationMode.value){
            emit('elevation', true);
        }else{
            emit('elevation', false)
        }
    }
</script>
  
<style scoped>
    #type-select{
        width: max-content;
    }

    .margin-right {
        margin-right: 4rem; /* Adjust the value as needed */
    }
    .min-width{
        min-width: 150px;
    }
    .sub-toolbar{
        display: flex;
        gap: 1rem;
    }
</style>
  