import axios from 'axios';
import { api_endpoints } from './api_endpoints';

async function loadLayerTypeDetails(id){
    const token = window.localStorage.getItem('climagruen_token');
    return axios.get(api_endpoints.layers_id(id),{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }}
    ).then(response=>{
        return response.data
    })
    .catch(error => {
        console.log(error);
        return null
    })
}

async function loadProjectDetails(id){
    const token = window.localStorage.getItem('climagruen_token');
    return axios.get(api_endpoints.projects_id(id),{
            headers: {
                'Authorization': `Bearer ${token}`
            }}
        )
        .then(response=> response.data)
        .catch(error => {
            console.log(error);
            return null
    })
}

async function updateProjectDetails(id, data){
    const token = window.localStorage.getItem('climagruen_token');
    return axios.put(api_endpoints.projects_id(id), data,
            {   
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response=> response.data)
        .catch(error => {
            console.log(error);
            return null
    })
}

function geoReferenceProjectLocation(project_id){
    const token = window.localStorage.getItem('climagruen_token');
    return axios.put(api_endpoints.location(project_id), {}, {headers: {'Authorization': `Bearer ${token}`}})
        .then((response)=>{
            console.log('Location found:', response.data);
            return true;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
}

export { loadLayerTypeDetails, loadProjectDetails, updateProjectDetails, geoReferenceProjectLocation }