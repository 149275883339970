<template>
    <form id="type-form" @submit.prevent="submitForm" class="bg-dark text-white p-3">
        <!-- ALL FOLLOWING Component (MAX max_nof_comp) -->
        <template v-for="(id, index) in form_data.plant_ids" :key="id">
            <div class="row mb-3" v-if="id!=0">
                <div class="col gap">
                    <div class="label-wrapper">                
                        <span class="layer-label">{{ $t('type_forms.plant_mix_form.component',{ component_no: index + 1 }) }}</span>
                        <button type="button" v-if="index > 0" :id="index" class="btn btn-danger small" @click="removeLayer" :title="$t('type_forms.plant_mix_form.remove')"><font-awesome-icon icon="fa-solid fa-minus"/></button>
                    </div>

                    <div class="form-floating bottom-aligned" data-bs-theme="dark">
                        <select :id="`type-select-${index+1}`" class="form-select" v-model="form_data.plant_ids[index]">
                            <option v-for="cg_type in store.state.climagruen_types.vegetation" :value="cg_type.id">{{ cg_type.common_name }}</option>
                        </select>
                        <label :for="`type-select-${index+1}`">{{ $t('type_forms.plant_mix_form.select_plant') }}</label>
                    </div>
                </div>

                <div class="col bottom-aligned">
                    <label :for="`spreading-${index+1}`" class="form-label">{{ $t('type_forms.plant_mix_form.spreading') }}</label>
                    <input type="number" min="0.0" max="1.0" step="any" :id="`spreading-${index+1}`" v-model="form_data.spreading_values[index]" :placeholder="$t('type_forms.placeholder_number')" class="form-control">
                </div>
            </div>
        </template>

        <button type="button" class="btn btn-secondary mb-3" @click="addComponent" :title="$t('type_forms.plant_mix_form.add')">
            <font-awesome-icon icon="fa-solid fa-plus"/>
        </button>
        <br/>

        <div>
            <button class="mb-3 btn btn-outline-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleForm" aria-controls="collapsibleForm">
                <font-awesome-icon icon="fa-solid fa-table-list"/>
                {{ $t('type_forms.plant_mix_form.toggle_form') }}        
            </button>
            <div id="collapsibleForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="common_name" class="form-label">{{ $t('type_forms.common_name') }}</label>
                            <input type="text" id="common_name" v-model="form_data.common_name" :placeholder="$t('type_forms.placeholder_name')" class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label for="description" class="form-label">{{ $t('type_forms.description') }}</label>
                            <input type="text" id="description" v-model="form_data.description" :placeholder="$t('type_forms.placeholder_name')" class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label for="min_substrate_depth" class="form-label">{{ $t('type_forms.plant_form.min_substrate_depth') }}</label>
                            <input type="number" step="any" id="min_substrate_depth" v-model="form_data.min_substrate_depth" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label for="stock_height" class="form-label">{{ $t('type_forms.plant_form.stock_height') }}</label>
                            <input type="number" step="any" id="stock_height" v-model="form_data.stock_height" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label for="max_LAI" class="form-label">{{ $t('type_forms.plant_form.max_LAI') }}</label>
                            <input type="number" step="any" id="max_LAI" v-model="form_data.LAI_time_series" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label for="leaf_conductance" class="form-label">{{ $t('type_forms.plant_form.leaf_conductance') }}</label>
                            <input type="number" step="any" id="leaf_conductance" v-model="form_data.leaf_conductance" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="LAI_soil_cover" class="form-label">{{ $t('type_forms.plant_form.LAI_soil_cover') }}</label>
                            <input type="number" step="any" id="LAI_soil_cover" v-model="form_data.LAI_soil_cover" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label for="capacity_leaf_interception" class="form-label">{{ $t('type_forms.plant_form.capacity_leaf_interception') }}</label>
                            <input type="number" step="any" id="capacity_leaf_interception" v-model="form_data.capacity_leaf_interception" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <div class="label-tip">
                                <label for="r_1" class="form-label">{{ `${$t('type_forms.plant_form.r_1_verbal')} (${$t('type_forms.plant_form.r_1_symbolic')})` }}</label>
                                <span class="question-mark-icon" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                                    <QuestionMark/>
                                </span>
                                <div v-if="showTooltip" class="tooltip bs-tooltip-top" role="tooltip">
                                    <img class="explanatory-img" :src="image" alt="explanatory_image">
                                </div>
                            </div>
                            <input type="number" step="any" id="r_1" v-model="form_data.root_radius_top" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label for="r_2" class="form-label">{{ `${$t('type_forms.plant_form.r_2_verbal')} (${$t('type_forms.plant_form.r_2_symbolic')})` }}</label>
                            <input type="number" step="any" id="r_2" v-model="form_data.root_radius_bottom" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label for="root_angle" class="form-label">{{ `${$t('type_forms.plant_form.root_angle_verbal')} (${$t('type_forms.plant_form.root_angle_symbolic')})` }}</label>
                            <input type="number" step="any" id="root_angle" v-model="form_data.root_radius_angle" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label for="h" class="form-label">{{ `${$t('type_forms.plant_form.h_verbal')} (${$t('type_forms.plant_form.h_symbolic')})` }}</label>
                            <input type="number" step="any" id="h" v-model="form_data.relative_root_depth" :placeholder="$t('type_forms.placeholder_number')" class="form-control" required>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br/>

        <button v-if="create_new" type="submit" class="btn btn-primary">{{ $t('type_forms.create_type') }} </button>
        <div v-else class="button-container">
            <button type="submit" class="btn btn-info">{{ $t('type_forms.modify_type') }}</button>
            <button type="button" class="btn btn-danger" @click="deleteType">{{ $t('type_forms.delete_type') }}</button>
        </div>
    </form>
</template>

<script setup>
    import axios from 'axios';
    import {api_endpoints} from '../../js/api_endpoints.js';
    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';
    import QuestionMark from '../icons/Questionmark.vue';
    import {ref, watch, onMounted} from 'vue';

    const { t } =  useI18n()

    const store = useStore();

    const emit = defineEmits(['success', 'failure']);
    
    const props = defineProps({
                                form_data: Object,
                                create_new: Boolean,
                                image: String,
                                
    })

    const showTooltip = ref(false);
    const max_nof_components = 4;

    watch(()=>props.form_data, ()=>{
        console.log("form_changed");
        console.log(props.form_data.plants)
        if(props.form_data.plants){
            props.form_data.plant_ids = props.form_data.plants.map(plant => plant.id);
        }
    })

    onMounted(()=>{
        // console.log(props.form_data.plant_ids);
        // if(props.form_data.plant_ids.length==0){
        //     addComponent();

        // }
    })
    
    function submitForm(){
        if(props.create_new){
            createType();
        }else{
            modifyType();
        }
    }
    function createType() {
        const valid_data = checkConglomerateComposition();
        if(!valid_data) return;
        const token = window.localStorage.getItem('climagruen_token');
        console.log('Form submitted:', props.form_data);
        axios.post(api_endpoints.plants_cong, props.form_data, {headers: {'Authorization': `Bearer ${token}`}})
            .then(()=>{
                console.log('Form submitted:', props.form_data);
                updateStore();
                emitSuccess();
            })
            .catch((error) => {
                console.log(error);
                emitFailure(error);
            });        
    }

    function modifyType(){
        const valid_data = checkConglomerateComposition();
        if(!valid_data) return;
        if (window.confirm(t('type_forms.modify_confirmation'))) {
            const token = window.localStorage.getItem('climagruen_token');
            const form_data_copy = {... props.form_data};
            delete form_data_copy.plants;
            axios.put(api_endpoints.plants_cong_id(props.form_data.id), form_data_copy, {headers: {'Authorization': `Bearer ${token}`}})
                .then((response)=>{
                    updateStore();
                    emitSuccess();
                    console.log('Form submitted:', form_data_copy);
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    emitFailure(error);
                });

            
        }
    }

    function deleteType(){
        if (window.confirm(t('type_forms.delete_confirmation'))) {
            const token = window.localStorage.getItem('climagruen_token');

            axios.delete(api_endpoints.plants_cong_id(props.form_data.id), {headers: {'Authorization': `Bearer ${token}`}})
                .then((response)=>{
                    console.log(response.data);
                    updateStore();
                    emitSuccess();
                    document.getElementById("type-form").reset();
                })
                .catch((error) => {
                    console.log(error);
                    emitFailure(error);
                });
        }
    }

    function checkConglomerateComposition(){
        const plants_set = new Set(props.form_data.plant_ids);
        const spreading_sum = props.form_data.spreading_values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        let input_valid = true;
        if(props.form_data.plant_ids.length === 0){
            input_valid = false;
            emitFailure("You have to add at least one component");
        }
        if(plants_set.size != props.form_data.plant_ids.length){
            input_valid = false;
            emitFailure("Please remove duplicate components");
        }
        if(spreading_sum >1.01){
            input_valid = false;
            emitFailure("The sum of all component shares cannot be greater than 1.0 (100%)");
        }
        return input_valid;
    }

    function updateStore(){
        store.dispatch('getVegetationMixTypes')
    }

    function emitSuccess(){
        emit('success');
    }

    function emitFailure(error){
        if(typeof error === 'string'){
            emit('failure', error);
            return;
        }
        let message;
        try{
            message = error.request.responseText;
        }
        catch(error){
            console.log(error)
            message = "";
        }
        console.log(message);
        emit('failure', message);
    }

    function addComponent(){
        if(props.form_data.plant_ids.length < max_nof_components){
            if(store.state.climagruen_types.vegetation && store.state.climagruen_types.vegetation.length > 0){
                props.form_data.plant_ids.push(store.state.climagruen_types.vegetation[0].id);
                props.form_data.spreading_values.push(0.5);
            }
        }
    }
    function removeLayer(event){
        const component_index = parseInt(event.currentTarget.id);
        props.form_data.plant_ids.splice(component_index, 1); 
        props.form_data.spreading_values.splice(component_index, 1); 
    }
</script>

<style scoped>
    .bg-dark {
        border-radius: 5px;
    }
    .button-container{
        display: flex;
        gap: 1rem;
    }
    .explanatory-img{
        max-height: 300px;
        width: auto;
    }

    .question-mark-icon {
        cursor: pointer;
        position: relative;
        display: inline-block;
    }

    .tooltip {
        position: absolute;
        transform: translateY(-100%);
        z-index: 1000;
        display: block;
        visibility: visible;
        opacity: 1;
        padding: 0; /* Removes default padding */
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

.label-tip{
    display: flex;
    gap: 0.5rem;
}

.label-wrapper{
    display: flex;
    gap: 1rem;
}

.layer-label{
    font-weight: 700;
}

.gap{
    gap: 1rem;
    display: flex;
    flex-direction: column;
}


.small{
    padding: 0 5px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    height: 1rem;
}

.bottom-aligned{
    margin-top: auto ;
}
</style>