<template>
    <input type="checkbox" v-model="drawOnLevels" id="level_checkbox" class="btn-check" @change="emitDrawOnLevel"/>
    <label class="btn btn-secondary" :class="{'active': drawOnLevels}" for="level_checkbox" :title="$t('toolbar.draw_on_levels')">
        <font-awesome-icon icon="fa-solid fa-layer-group"/>
    </label>

    <div v-if="drawOnLevels" id="level-dropdown" class="form-floating me-2 dropdown" data-bs-theme="dark">
        <select id="level-select" class="form-select min-width" @change="emitLevelChange" v-model="selected_level">
            <option v-for="level in props.levels_threejs.children" :value="level.name">{{ level.userData.cg_level_name }}</option>
        </select>
        <label for="type-select">{{ $t('toolbar.select_level') }}</label>
    </div>

    <div v-if="drawOnLevels" class="form-floating">
        <input
            type="number"
            step="any"
            class="form-control"
            v-model="levelOffset"
            id="level-offset"
            @change="emitOffsetChange"
        />
        <label for="level-offset">{{ $t('toolbar.level_offset') }}</label>
    </div>

    <div id="type-dropdown" class="form-floating me-2 dropdown" data-bs-theme="dark">
        <select id="type-select" class="form-select min-width" @change="emitSelectedType" v-model="selected_type">
            <option v-for="cg_type in enabledCgTypes" :key="cg_type.id" :value="cg_type.id">{{ cg_type.common_name }}</option>
        </select>
        <label for="type-select">{{ $t('toolbar.select_type') }}</label>
    </div>

    <button class="btn btn-secondary" @click="showLayerModal = true" :title="$t('toolbar.modify_layer_type')"><font-awesome-icon icon="fa-solid fa-table-list"/></button>

    <button class="btn btn-info" @click="emitConfirmPolyline" :title="$t('toolbar.confirm_polygon')" :disabled="!props.polylineReady"><font-awesome-icon icon="fa-solid fa-check"/></button>
    
    <input type="checkbox" v-model="snapping" id="snapping" class="btn-check" @change="emitSnapping"/>
    <label class="btn btn-secondary" :class="{'active': snapping}" for="snapping" :title="snapping ? $t('toolbar.snapping_off'):$t('toolbar.snapping_on')">
        <font-awesome-icon icon="fa-solid fa-arrows-to-circle"/>
    </label>

    <button class="btn btn-secondary" @click="emitUndoPolyline" :title="$t('toolbar.undo')"><font-awesome-icon icon="fa-solid fa-rotate-left"/></button>
    <button class="btn btn-danger" @click="emitExitModeling" :title="$t('toolbar.exit_modeling')"><font-awesome-icon icon="fa-solid fa-xmark"/></button>


    <!-- Layer Modal -->
    <LayerModal
        v-if="showLayerModal"
        :layerData="selectedLayerData"
        @onClose="showLayerModal = false"
        @update-success="handleSuccess"
        @update-failure="handleFailure"
    />
</template>

<script setup>
    import { onMounted, onBeforeMount, computed } from "vue";
    import {ref} from "vue";
    import {useStore} from "vuex";
    import LayerModal from "../modals/LayerModal.vue";
    import {loadLayerTypeDetails} from "../../js/api_calls";

    const emit = defineEmits(['typeSelectChange',
                                'confirmPolyline' ,
                                'snappingChange',
                                'undoPolyline', 
                                'exitModeling',
                                'drawOnLevelsChange',
                                'offsetChange',
                                'levelChange'
    ]);

    const props = defineProps({
        polylineReady: Boolean,
        levels_threejs: Object
    })

    const enabledCgTypes = computed(() => {
        return store.state.climagruen_types.layers.filter(cg_type => cg_type.enable);
    });

    const showLayerModal = ref(false);

    const store = useStore();
    const selected_type = ref(null);
    const selectedLayerData = ref(null);
    const selected_level = ref(null);
    const snapping =ref(true);
    const drawOnLevels = ref(false);
    const levelOffset = ref(0);

    onBeforeMount(async ()=>{
        // select first level by default
        if(props.levels_threejs.children.length > 0){
            selected_level.value = props.levels_threejs.children[0].name;
        }

        // select first layer type by default
        if (store.state.climagruen_types.layers.length > 0) {
            selected_type.value = store.state.climagruen_types.layers[0].id;
            await emitSelectedType();
        }
    })

    async function emitSelectedType() {
        selectedLayerData.value = await loadLayerTypeDetails(selected_type.value)
        emit('typeSelectChange', selectedLayerData.value)
    }

    function emitConfirmPolyline() {
        emit('confirmPolyline')
    }

    function emitSnapping() {
        emit('snappingChange', snapping.value)
    }

    function emitUndoPolyline(){
        emit('undoPolyline');
    }

    function emitExitModeling(){
        emit('exitModeling');
    }

    function emitDrawOnLevel(){
        emit('drawOnLevelsChange', {enabled: drawOnLevels.value, level_id: selected_level.value, offset: levelOffset.value});

    }

    function emitOffsetChange(){
        emit('offsetChange', levelOffset.value);
    }

    function emitLevelChange(){
        levelOffset.value = 0;
        emitOffsetChange();
        emit('levelChange', {enabled: drawOnLevels.value, level_id: selected_level.value, offset: levelOffset.value});
    }


</script>
  
<style scoped>
    #modeling-modes-wrapper{
        display: flex;
        gap: 0.5rem;
    }
    .radio-label{
        opacity: 0.5;
    }
    .active {
        border-color: #0a5bb3; /* Slightly darker border for depth */
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5); /* A subtle glow effect */
        opacity: 1;
    }

    #sigmaRange{
        max-width: 100px;
    }


    .dropdown{
        width: max-content;
        max-width: 300px;
    }

    .min-width{
        min-width: 150px;
    }
</style>
  