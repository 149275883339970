<template>
    <!-- Offcanvas Trigger Button -->
    <button v-if="!showOffcanvas" id="offcanvas-btn" @click="toggleOffcanvas" class="offcanvas-trigger" :title="$t('chat.open_chat')">
        <Gaia />
    </button>


    <!-- Offcanvas -->
    <div id="offcanvas" :class="{ 'offcanvas': true, 'show': showOffcanvas }" tabindex="-1" aria-labelledby="offcanvasLabel">
        <div class="offcanvas-header">
            <h5 id="offcanvasLabel" class="offcanvas-title">{{ $t('chat.chat_header') }}</h5>
            <button type="button" class="btn-close btn-close-white" aria-label="Close" @click="toggleOffcanvas"></button>
        </div>
        
        <Chat/>
    </div>

</template>
  
<script setup>
    import Gaia from './icons/Gaia.vue';
    import {ref} from 'vue';
    import { useStore } from 'vuex';
    import Chat from './Chat.vue';

    const store = useStore();
    const showOffcanvas= ref(false);
    let stored_view_helper_right;
    let stored_title_block_right;
    let observer;
    let plan_title_block;

    function toggleOffcanvas() {
        showOffcanvas.value = !showOffcanvas.value;
        
        const offcanvas = document.getElementById("offcanvas");
        const viewHelper = document.getElementById("view-helper");
        const canvas = document.getElementById("threed-canvas");
        plan_title_block = document.getElementById("plan-title-block");

        if(showOffcanvas.value){
            const offcanvas_style = getComputedStyle(offcanvas);
            // const viewHelper_style = getComputedStyle(viewHelper);
            const offcanvas_width = parseFloat(offcanvas_style.width);
            // stored_view_helper_right = parseFloat(viewHelper_style.right) || 0;
            // viewHelper.style.right = `${offcanvas_width + stored_view_helper_right}px`;
            canvas.style.width = `${canvas.offsetWidth - offcanvas.offsetWidth}px`;
            if(plan_title_block){
                const plan_title_block_style = getComputedStyle(plan_title_block);
                stored_title_block_right = parseFloat(plan_title_block_style.right) || 0;
                plan_title_block.style.right = `${offcanvas_width + stored_title_block_right}px`;
            }

            // Set up the MutationObserver
            observer = new MutationObserver((mutationsList) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        const targetElement = document.getElementById('plan-title-block');
                        if (targetElement && !stored_title_block_right) {
                            console.log('Target element exists:', targetElement);
                            const plan_title_block_style = getComputedStyle(targetElement);
                            stored_title_block_right = parseFloat(plan_title_block_style.right) || 0;
                            targetElement.style.right = `${offcanvas_width + stored_title_block_right}px`;
                            // Perform any additional actions on the target element
                        }
                    }
                }
            });

            // Observe the entire document body for changes
            observer.observe(document.body, { childList: true, subtree: true });
        }else{

            // viewHelper.style.right = `${stored_view_helper_right}px`;
            canvas.style.width = `${window.innerWidth}px`;
            if(plan_title_block) {
                if(!stored_title_block_right) {
                    const plan_title_block_style = getComputedStyle(plan_title_block);
                    stored_title_block_right = parseFloat(plan_title_block_style.right) || 0;
                };
                plan_title_block.style.right = `${stored_title_block_right}px`
            };

            // Disconnect the MutationObserver
            if (observer) {
                observer.disconnect();
            }
        }

        
        store.state.modeling_manager.world.renderer.resize();
        const camera = store.state.modeling_manager.world.camera;
        camera.updateAspect();

    }

</script>
  
<style scoped>

.offcanvas-trigger {
  position: absolute;
  background: gray;
  border-radius: 50%;
  outline: none;
    border: none;
  width: 3rem;
  height: 3rem;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.247);
  overflow: hidden;
  opacity: 0.3;
  z-index: 101;
}

.offcanvas-trigger:hover {
  opacity: 1;
}

  
.offcanvas {
    position: absolute;
    top: 0;
    right: 0; /* Ensure it's anchored to the right */
    height: inherit;
    background-color: #343a40;
    color: white;
    z-index: 1045;
    width: 35vw; /* Define a width for the offcanvas */
    height: 100%;
    min-width: 300px;
    transition: transform var(--transition-time) ease; /* Smooth transition for sliding in */
    transform: translateX(100%); /* Start off-screen */
}
  
  .offcanvas.show {
    transform: translateX(0); /* Slide in */
  }
  </style>
  