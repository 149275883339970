<template>
    <div class="custom-modal">
        <div class="modal-dialog bg-dark">
            <div class="modal-content bg-dark text-light">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('toolbar.configure_form') }}</h5>
                    <span class="question-mark-icon" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                        <QuestionMark/>
                    </span>
                    <div v-if="showTooltip" class="tooltip bs-tooltip-top" role="tooltip">
                        <img class="explanatory-img" src="../img/mulden_prameter.png" alt="explanatory_image">
                    </div>
                    <button type="button" class="btn-close btn-close-white" @click="closeModal()"></button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="saveValues" class="gap">
                        <div v-for="(value, name) in formData" :key="name">
                            <label :for="`param_${name}`" class="form-label text-white">{{ name }}</label>
                            <input type="number" step="0.01" v-model="formData[name]" class="form-control bg-dark text-white">
                        </div>
                        <div class="modal-footer">
                            <!-- <button class="btn btn-secondary" @click="resetDefault">{{ $t('toolbar.reset_default') }}</button> -->
                            <button type="submit" class="btn btn-primary">{{ $t('toolbar.confirm') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
    import { onBeforeMount } from 'vue';
    import { ref } from 'vue';
    import { useStore } from 'vuex';
    import QuestionMark from '../icons/Questionmark.vue';

    const store = useStore();
    const props = defineProps({
        index: Number,
        fields: {
            type: Object,
            required: true,
            default: () => {},
        }
    });
    const emit = defineEmits(['update:showModal'])

    const formData = ref({});
    const showTooltip = ref(false);

    onBeforeMount(() => {
        for (const [key, value] of Object.entries(props.fields)) {
            formData.value[key] = value;
        }
    })
    
    const saveValues = () => {
        console.log(formData.value)
        store.commit('updateLineElementsParameter', {index: props.index, parameters: formData.value});
        closeModal();
        console.log('Form data:', formData.value);
    };

    const closeModal = () => {
        emit('update:showModal', false)
    }
</script>
  
<style scoped>
  .custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    
  }

  .modal-dialog {
    border-radius: 0.5rem;
    width: 90%;
    padding: 1rem;
    max-width: none;
  }

  .modal-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .modal-footer{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  @media (min-width: 576px) {
    .modal-dialog {
      width: 400px;
    }
  }
  
  /* @media (min-width: 992px) {
    .modal-dialog {
      width: 60%;
    }
  } */
  
  .btn-close-white {
    filter: invert(1);
  }
  .gap{
    display: flex;
    flex-direction: column;
    gap:1rem;
  }

  .explanatory-img{
        max-height: 300px;
        width: auto;
    }

    .question-mark-icon {
        cursor: pointer;
        position: relative;
        display: inline-block;
    }

    .tooltip {
        position: absolute;
        transform: translateX(60%);
        z-index: 1000;
        display: block;
        visibility: visible;
        opacity: 1;
        padding: 0; /* Removes default padding */
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
</style>

