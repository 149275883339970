// Internazionalization?? 

const createStandardAnnotation = (name, area, type) => {
    const mark = document.createElement("div");
    mark.style.backgroundColor = "white";
    mark.style.color = "black";
    mark.style.padding = "8px";
    mark.style.borderRadius = "8px";
    mark.style.fontFamily = "sans-serif";
    mark.style.display = "flex";
    mark.style.flexDirection = "column";
    mark.style.alignItems = "flex-start";

    // Create and append name element
    const nameElement = document.createElement("div");
    nameElement.textContent = `Name: ${name}`;
    mark.appendChild(nameElement);

    // Create and append type element
    const typeElement = document.createElement("div");
    typeElement.textContent = `${type}`;
    mark.appendChild(typeElement);

    // Create and append area element with unit
    const areaElement = document.createElement("div");
    areaElement.textContent = `${area.toFixed(2)} m²`; // Add your desired unit here
    mark.appendChild(areaElement);

    return mark;
};

const createDimensionLineTick = () => {
    const mark = document.createElement("div");
    mark.style.backgroundColor = "black";
    mark.style.height = "5px";
    mark.style.width = "5px";
    mark.style.borderRadius = "50%";
    return mark;
};



export { createStandardAnnotation, createDimensionLineTick };