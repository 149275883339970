<template>
    <div class="chat-container">
        <div v-if="messages.length==0" class="chat-welcome">
                <img id="olaia-img" src="./img/olaia_links.png" alt="olaia_links.img">
                <span>{{$t('chat.welcome')}}</span>
        </div>
        <div v-else class="message-container">           
            <div v-for="(message, index) in messages" :key="index" class="chat-message">
                <component :is="message.role_pic" class="profile-icon"></component>
                <div class="column-wrapper">
                    <div  class="role-label">{{ message.role_name }}</div>
                    <div :id="'msg-'+index" class="message-content">
                        <span :class="{ 'typing-wrapper': message.isTyping }">
                            {{ message.content }}
                            <span class="typing-dot" v-if="message.isTyping"></span>
                        </span>
                        <span v-html="message.content_markdown"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="input-group mb-3 chat-input">
            <textarea id="chat-textarea" class="form-control" :placeholder="$t('chat.input_placeholder')" v-model="userMessage" @keyup.enter="sendMessage" spellcheck="false" :disabled="waitForResponse"></textarea>
            <button class="btn btn-outline-secondary" type="button" @click="sendMessage" :disabled="waitForResponse"><font-awesome-icon icon="fa-solid fa-arrow-up"/></button>           
        </div>
    </div>
</template>
  
<script setup>
    import { ref, onMounted, shallowRef  } from 'vue';
    
    import Gaia from './icons/Gaia.vue';
    import User from './icons/User.vue';

    import { api_endpoints } from '@/js/api_endpoints';
    import { v4 as uuidv4 } from 'uuid';
    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';

    import * as DOMPurify from 'dompurify';
    import * as marked from 'marked';

    import { AiToolManager } from '@/js/modeling_utils.js';

    const store = useStore();
    const { t, locale } =  useI18n();

    const userMessage = ref('');
    const messages = ref([]);
    const waitForResponse = ref(false);
    let textarea;

    const initial_textarea_height = "4rem";
    let chat_session_id;
    const ai_tool_manager = new AiToolManager();

    onMounted(() => {
        // messages.value.push({role:'ai-bot', content: `Hello, how can I assist you today?`}); 
        textarea = document.getElementById('chat-textarea');
        textarea.addEventListener('input', autoResize, false);
        chat_session_id = uuidv4(); // Outputs a UUID string
    });

    async function sendMessage() {
        if (userMessage.value.trim() !== '') {
            waitForResponse.value = true;
            messages.value.unshift({role:'user', role_name:t('chat.you'), role_pic: shallowRef(User), content: `${userMessage.value}`, content_markdown: null, isTyping: false});
            messages.value.unshift({role:'ai-bot', role_name:t('chat.bot_name'), role_pic: shallowRef(Gaia), content: '', content_markdown: null, isTyping: true});
            const user_message_helper = userMessage.value;
            userMessage.value = '';
            textarea.style.height = initial_textarea_height;
            await postData(user_message_helper);
            
            waitForResponse.value = false;
        }
    }

    async function postData(user_message) {
        if(!ai_tool_manager.is_setup){
            if(store.state.modeling_manager){
                ai_tool_manager.setup(store.state.modeling_manager, store.state.current_project.id, store.state.climagruen_types);
            }
            else{
                console.warn('Modeling manager not set up. AI tools will not work.');
            }
        }

        const token = window.localStorage.getItem('climagruen_token');
        const selected_roof_id = getSelectedGreenRoofId();
        let data;
        if(selected_roof_id){
            data = {
                session_id: chat_session_id,
                user_text: `${t('chat.hidden_messages.roof_selected')}: ${user_message}`,
                language: t('lang.'+ locale.value),
                bearer: token,
                roof_id: selected_roof_id
            };
        }else{
            data = {
                session_id: chat_session_id,
                user_text: user_message,
                language: t('lang.'+ locale.value),
                bearer: token,
                roof_id: 0
            };
        }

        console.log(data);
        // the following streamed response and the function readChunks are from the following source:
        // https://www.loginradius.com/blog/engineering/guest-post/http-streaming-with-nodejs-and-fetch-api/#:~:text=fetch()%20will%20set%20response,a%20ReadableStream%20for%20streaming%20responses.&text=To%20see%20it%20more%20clearly,chunks%20are%20being%20processed%20gradually.
        await fetch(api_endpoints.chat, {
                method: "POST",
                credentials: "same-origin", 
                headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(data),
        })
        .then(async (response) => {
            // response.body is a ReadableStream
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let hidden_message = '';
            let hidden_message_started = false;

            // The response has always the format: text_for_user + hidden_message
            // The hidden message contains of a list of tools between '$§' and '§$', followed by an optional set of parameters between '&#' and '#&'
            for await (const chunk of readChunks(reader)) {
                const text = decoder.decode(chunk);
                if(hidden_message_started){
                    hidden_message += text;
                    continue;
                }   
                if(text.includes('$§')){
                    // get string until the first occurence of $§
                    messages.value.at(0).content += text.substring(0, text.indexOf('$§'));
                    // add the rest to the hidden message
                    hidden_message += text.substring(text.indexOf('$§'));
                    hidden_message_started = true;
                }else{
                    messages.value.at(0).content += text;
                }
            }
            console.log(messages.value.at(0).content)
            console.log(hidden_message)
            // Use the marked library to convert the markdown to HTML
            const html = marked.parse(messages.value.at(0).content);
            const clean_html = DOMPurify.default.sanitize(html);

            messages.value.at(0).content = '';
            messages.value.at(0).isTyping = false;
            messages.value.at(0).content_markdown = clean_html;

            const tools = extractToolsAndParameters(hidden_message);
            console.log(tools);
            for (const tool of tools) {
                // Check if the function exists in the registry
                if (typeof ai_tool_manager.functionRegistry[tool.tool_name] === 'function') {
                    try {
                        if (tool.params) {
                            ai_tool_manager.functionRegistry[tool.tool_name](tool.params);
                        } else {
                            ai_tool_manager.functionRegistry[tool.tool_name]();
                        }
                    } catch (error) {
                        console.error(`Error executing function ${tool.tool_name}:`, error);
                    }
                } else {
                    console.error(`Function ${tool.tool_name} does not exist in the registry`);
                }
            }  
        })
        .catch((error) => {
            messages.value.at(0).isTyping = false;
            messages.value.at(0).content = 'There has been an error :/';
            console.log(error)}
        );

    };

    
    // readChunks() reads from the provided reader and yields the results into an async iterable
    function readChunks(reader) {
        return {
            async* [Symbol.asyncIterator]() {
                let readResult = await reader.read();
                while (!readResult.done) {
                    yield readResult.value;
                    readResult = await reader.read();
                }
            },
        };
    }

    function extractToolsAndParameters(input) {
        const toolRegex = /\$§(.*?)§\$/g;
        const paramsRegex = /&#(.*?)#&/g;

        let tools = [];
        let parameters = [];

        let toolMatch;
        let paramsMatch;

        while ((toolMatch = toolRegex.exec(input)) !== null) {
            tools.push(toolMatch[1]);
        }

        while ((paramsMatch = paramsRegex.exec(input)) !== null) {
            try {
                // Replace single quotes with double quotes
                const jsonString = paramsMatch[1].replace(/'/g, '"');
                parameters.push(JSON.parse(jsonString));
            } catch (e) {
                console.error("Error parsing parameters:", e);
                parameters.push(null);
            }
        }

        let result = tools.map((tool, index) => ({
            tool_name: tool,
            params: index < parameters.length ? parameters[index] : null
        }));

        return result;
    }

    function autoResize() {
        this.style.height = initial_textarea_height;
        this.style.height = this.value ? this.scrollHeight + 'px' : initial_textarea_height;
    }

    function getSelectedGreenRoofId(){
        const selected_id = store.state.modeling_manager.selectionManager.fixed_selection_id;
        console.log("Selected_id",selected_id);
        if(!selected_id) return null;

        const selected_object = store.state.modeling_manager.green_roof_objects.getObjectById(selected_id);
        console.log("Selected_object",selected_object);
        if(!selected_object) return null;
        console.log("Selected_object_id ",selected_object.userData.cg_ai_roof_id);
        return selected_object.userData.cg_ai_roof_id
    }
</script>
  
<style scoped>
    .chat-container{
        height: 100%;
        width: 100%;
        padding: 1rem 1rem 0.5rem;
        display: flex;
        flex-direction: column;
        overflow: hidden
    }
    #olaia-img{
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
    .chat-welcome{
        display: flex;
        flex-direction: column;
        width: 75%;
        height: auto;
        align-self: center;
        justify-self: center;
        justify-content: center;
        align-items: center;
    }
    .message-container {
        display: flex;
        flex-direction: column-reverse;
        gap: 2rem;
        overflow-y: auto;
        margin-bottom: 2rem;
        /* margin-top: auto; */
        /* flex-grow: 1; */

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .message-container::-webkit-scrollbar {
        display: none;
    }

    .role-label{
        font-weight: bold;
    }
    
    .chat-message {
        display: flex;
        gap: 1rem;
    }
    .chat-input{
        margin-top: auto;
        flex-shrink: 0;
    }
    .profile-icon{
        width: 2rem;
        height: 2rem;
        border-style: solid;
        border-width: thin;
        border-color:  #6c757d;
        background-color: white;
        border-radius: 50%;
        flex-shrink: 0;
    }

    textarea, textarea:focus {
        align-self: center;
        resize: none;
        overflow: hidden;
        height: 4rem;
        min-height: 4rem;
        max-height: 30vh;
        background-color: transparent;
        border-color:  #6c757d;
        border-right:none;
        color: white;
    }

    textarea:disabled {
        color: inherit;
        background-color: inherit;
        border-color:  #6c757d;
    }

    .btn{
        border-left:none;
    }

    .btn:disabled {
        color: inherit;
        background-color: inherit;
        border-left:none;
    }

    .form-control:focus {
        outline: none;
        box-shadow: none;
    }

    .form-control::placeholder {
        color: #6c757d;
    }


    .typing-wrapper {
        display: inline;
    }

    .typing-dot {
        display: none;
        animation: typing-dot 1s infinite;
        height: 1rem;
        width: 1rem;
        background-color: white;
        border-radius: 50%;
    }

    .typing-wrapper .typing-dot {
        display: inline-flex;
    }


    @keyframes typing-dot {

        0%,
        80%,
        100% {
            transform: scale(0);
        }

        40% {
            transform: scale(1);
        }
    }


</style>
  