<template>   
    <div ref="chartRef" class="chart"></div>
</template>

<script setup>
    import { onMounted, ref, watch } from 'vue';
    import * as echarts from 'echarts';
    import { useI18n } from 'vue-i18n';

    const { t } =  useI18n();


    const chartRef = ref(null);
    let chartInstance;
    const chart_data = {};


    const props = defineProps({
        chart_data: Object,
        simulation_type: String
    });

    watch(() => props.simulation_type, (newVal) => {
        console.log("Simulation type changed", newVal);
        updateChart();
    });

    // Refined data to match the curve shapes shown in the image
    const blockRainData = {
    categories: [
        5, 10, 15, 20, 30, 45, 60, 90, 120
    ], // Durations in minutes
    series: {
        'T = 1':  [5,  8,  10, 12,  14,  16,  18,  19,  20],
        'T = 2':  [6,  9,  12, 14,  17,  19,  21,  23,  25],
        'T = 5':  [8,  12, 15, 18,  21,  24,  27,  29,  31],
        'T = 10': [10, 14, 18, 22,  25,  29,  32,  34,  36],
        'T = 20': [12, 17, 22, 26,  31,  35,  38,  41,  43],
        'T = 30': [14, 19, 24, 30,  35,  39,  43,  46,  48],
        'T = 50': [16, 21, 27, 33,  39,  43,  47,  50,  52],
        'T = 100':[18, 25, 31, 38,  44,  48,  52,  55,  57]
    }
    }

    onMounted(()=>{
        chartInstance = echarts.init(chartRef.value, 'light');
        console.log(props.chart_data);
        setupCharts();
        updateChart();
    })

    const setupCharts = () => {
        setupPrecipitationChart(props.chart_data.precipitation);
        setupBlockRainChart();
    };

    function updateChart() {
        let option;
        chartInstance.clear();

        switch (props.simulation_type) {
            case 'statistical':
                option = chart_data['precipitation'];
                break;
            case 'extreme':
                option = chart_data['block_rain'];
                break;
            default:
                option = chart_data['precipitation'];
        }
        chartInstance.setOption(option);
        chartInstance.resize();
    }


    // Generate timestamps for each hour over a year
    const generateTimestamps = () => {
        const timestamps = [];
        const startDate = new Date('2023-01-01T00:00:00Z');
        for (let i = 0; i < 8760; i++) { // 8760 hours in a year
            const date = new Date(startDate.getTime() + i * 3600000); // 3600000 ms in an hour
            timestamps.push(date.toISOString());
        }
        return timestamps;
    };

    const setupPrecipitationChart = (data)=>{
        const series_data = [];
        const series_default_options = {
            type: 'line',
            smooth: true,
            symbol: 'none',
        };
        const hourly_timestamps = generateTimestamps();
        const precipitation_data = hourly_timestamps.map((timestamp, index) => [timestamp, data[index]]);
        series_data.push({ ...series_default_options, name: t('results.precipitation_chart_title'), data: precipitation_data });
        const option = {
                        backgroundColor: '#FFFFFF', // Set background to white

                        tooltip: {
                            trigger: 'axis',
                            position: function (pt) {
                            return [pt[0], '10%'];
                            }
                        },
                        legend: {
                            data: series_data.map(series => series.name),
                            top: '40px'
                        },
                        toolbox: {
                            feature: {
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                            restore: {},
                            saveAsImage: {}
                            }
                        },
                        xAxis: {
                            type: 'time',
                            boundaryGap: false
                        },
                        yAxis: {
                            name: t('results.precipitation_chart_y'),
                            type: 'value',
                            boundaryGap: [0, '10%']
                        },
                        dataZoom: [
                            {
                            type: 'inside',
                            start: 0,
                            end: 100
                            },
                            {
                            start: 0,
                            end: 100
                            }
                        ],
                        series: series_data
        };
        chart_data['precipitation'] = option;

    }

    const setupBlockRainChart = () =>{
        const option = {
            tooltip: {
            trigger: 'axis',
            formatter: function (params) {
                let tooltip = 'Regendauer: ' + params[0].name + ' Minuten<br/>';
                params.forEach(item => {
                tooltip += item.marker + item.seriesName + ': ' + item.value + ' mm<br/>';
                });
                return tooltip;
            }
            },
            legend: {
                data: Object.keys(blockRainData.series),
                title: {
                    text: 'T = Wiederkehrzeit in Jahren',  // Legend title
                    left: 'left'
                },
            top: 0
            },
            xAxis: {
                nameLocation: 'middle', // Name location ('middle' aligns it below the axis)
                nameGap: 30, // Adjusts the gap between the axis and the name            type: 'value',
                name: 'Regendauer [min]'
            },
            yAxis: {
                type: 'value',
                name: 'Niederschlagshöhe [mm]',
            },
            series: 
                Object.keys(blockRainData.series).map(key => ({
                    name: key,
                    type: 'line',
                    data: blockRainData.series[key].map((y, index) => [blockRainData.categories[index], y]),  // Map x and y values
                    smooth: true,
                    lineStyle: {
                        width: 2,
                        type: key === 'T = 1' ? 'dotted' :
                            key === 'T = 2' ? 'dashed' :
                            key === 'T = 5' ? 'solid' :
                            key === 'T = 10' ? 'solid' :
                            key === 'T = 20' ? 'dashed' :
                            key === 'T = 30' ? 'dashed' :
                            key === 'T = 50' ? 'dashed' :
                            'solid',
                    }
                })),
                graphic: {
                    elements: [
                        {
                            type: 'text',
                            left: '15%',  // Adjust 'left' for horizontal position (can also use 'right')
                            top: '15%',   // Adjust 'top' for vertical position (can also use 'bottom')
                            style: {
                                text: 'T = Jährlichkeit',  // <-- Place your content here
                                font: 'bold 14px Arial',  // Font styling
                                fill: '#000',  // Text color
                                align: 'center'  // Text alignment
                            }
                        }
                    ]
                }
        }

        chart_data['block_rain'] = option;
    }

</script>

<style scoped>

</style>