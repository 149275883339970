import * as THREE from 'three';
import * as poly2tri from 'poly2tri';

import * as jsts from 'jsts';

function createRegularMesh(vertices, grid_size, hole_vertices){
    
    const vertices_with_interpolation_points = interpolateBoundaryPoints(vertices,grid_size);

    const hole_vertices_with_interpolation_points = [];
    hole_vertices.forEach(hole => {
        hole_vertices_with_interpolation_points.push( interpolateBoundaryPoints(hole, grid_size) );
    });
        
    
    const inner_grid_points = createInnerGridPoints(vertices, grid_size, hole_vertices);
    const [combined_vertices, triangles, index_info] = triangulation(vertices_with_interpolation_points, inner_grid_points, hole_vertices_with_interpolation_points);
    const geometry = createBufferGeometry(combined_vertices, triangles);

    return [geometry, index_info];
}

function createInnerGridPoints(vertices, grid_size, exclusion_vertices) {
    // Create a GeoJSON reader
    const reader = new jsts.io.GeoJSONReader();
    const geometryFactory = new jsts.geom.GeometryFactory();

    // Convert vertices to GeoJSON format with holes if exclusion_vertices are provided
    const coordinates = [vertices];

    exclusion_vertices.forEach(hole => {
        coordinates.push(hole);
    });

    const polygonGeoJSON = { "type": "Polygon", "coordinates": coordinates };

    // Read GeoJSON into JSTS polygons
    const polygon = reader.read(polygonGeoJSON);

    // Calculate the bounding box
    const envelope = polygon.getEnvelopeInternal();
    const [minX, maxX] = roundRange(envelope.getMinX(), envelope.getMaxX());
    const [minY, maxY] = roundRange(envelope.getMinY(), envelope.getMaxY());

    let gridPoints = [];
    // Create an array of coordinates with a  fixed intervals in x and y direction.
    for(let i = minX; i < maxX; i = parseFloat((i+ grid_size).toFixed(2))) {
        for(let j = minY; j < maxY; j = parseFloat((j+ grid_size).toFixed(2))) {
            gridPoints.push([i, j]);
        }
    }

    // Check if points are inside the polygon
    let inPoints = [];
    gridPoints.forEach(point => {
        const jstsPoint = geometryFactory.createPoint(new jsts.geom.Coordinate(point[0], point[1]));
        if (polygon.contains(jstsPoint)) {
            inPoints.push(point);
        }
    });

    return inPoints;
}

function triangulation(boundary_vertices, inner_vertices, hole_vertices){
    let holes = [];

    // The last vertex is the same as the first one, so it has to be removed to avoid duplicate vertices
    const boundary_vertices_reduced = boundary_vertices.slice(0,-1); 
    let combined_vertices = inner_vertices.concat(boundary_vertices_reduced);


    hole_vertices.forEach(hole => {
        const hole_reduced = hole.slice(0,-1); // see above...
        combined_vertices = combined_vertices.concat(hole_reduced);
        holes.push( hole_reduced.map(v =>({x:v[0], y:v[1]})));
    });

    // Create a map from point string representation to their indices
    const pointIndexMap = new Map();
    combined_vertices.forEach((point, index) => {
        pointIndexMap.set(`${point[0]},${point[1]}`, index);
    });

    const contour = boundary_vertices_reduced.map(v =>({x:v[0], y:v[1]}));
    const steiner_points = inner_vertices.map(v =>({x:v[0], y:v[1]}));
    
    const swctx = new poly2tri.SweepContext(contour);

    // Add holes
    swctx.addHoles(holes);
    // or swctx.addHoles([hole1, hole2]) for multiple holes

    // Add Steiner points (inner vertices)
    swctx.addPoints(steiner_points)

    swctx.triangulate();
    const triangles = swctx.getTriangles();

    // Create an array to store the indices that form triangles to create the three.js geometry later
    const meshing_indices = [];

    // Iterate over the triangles and map their vertices to the original point indices
    triangles.forEach(triangle => {
        for (let i = 0; i < 3; i++) {
            const point = triangle.getPoint(i);
            const index = pointIndexMap.get(`${point.x},${point.y}`);
            meshing_indices.push(index);
        }
    });

    // Store the information which indices belong to inner vertices and which one belong to the boundaries
    const index_info = { inner: {start_index: 0, end_index: inner_vertices.length}, 
                        boundaries: {start_index: inner_vertices.length, end_index: combined_vertices.length}
    }

    return [combined_vertices, meshing_indices, index_info];
}

function createBufferGeometry(combined_vertices, triangles){
    const combined_vertices_3d = combined_vertices.map(v => [v[0], v[1], 0.0]).flat()
    const geometry = new THREE.BufferGeometry();
    
    geometry.setIndex( triangles );
    geometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array(combined_vertices_3d), 3 ) );
    geometry.computeVertexNormals();
    return geometry;
}

function roundRange(min, max) {
    const roundedMin = Math.floor(min);
    const roundedMax = Math.ceil(max);
    return [roundedMin, roundedMax];
}
function interpolateBoundaryPoints(vertices, grid_size){
    let vertices_with_interpolation_points = []
    for (let i = 0; i < vertices.length-1; i++) {
        const p1 = vertices[i];
        const p2 = vertices[i+1];
        const direction = [p2[0]-p1[0], p2[1]-p1[1]]
        const length = Math.sqrt(Math.pow(direction[0],2) + Math.pow(direction[1],2));

        vertices_with_interpolation_points.push(p1);
        if(length > 1.5*grid_size){
            const num_interpolation_points = Math.round(length/grid_size);
            const increment_factor = 1/num_interpolation_points;
            for(let j=1; j< num_interpolation_points; j++){
                vertices_with_interpolation_points.push([p1[0]+increment_factor*j*direction[0],
                                                        p1[1]+increment_factor*j*direction[1]
                ])
            }
        }
    }
    vertices_with_interpolation_points.push(vertices[vertices.length - 1]);
    return vertices_with_interpolation_points;
}



export {createRegularMesh}