<template>
    <div id="main">
        <Canvas :project_id="route.params.id"/>
        <OffcanvasSidebar/>
    </div>

</template>

<script setup>
    import Canvas from '@/components/Canvas.vue';
    import OffcanvasSidebar from '@/components/OffcanvasSidebar.vue';
    import { useRoute } from 'vue-router';

    const route = useRoute();
</script>

<style scoped>
    #main{
        position: relative;
        grid-area: main;
        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

</style>