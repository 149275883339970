import './assets/main.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import { createI18n } from 'vue-i18n';
import messages from '@intlify/unplugin-vue-i18n/messages';

import { createApp, h, Suspense } from 'vue';
import App from './App.vue';
import LoadingSpinner from './components/notifications/LoadingSpinner.vue';

import router from './router';
import store from './store';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as icons from './js/font_awesome_icons'; // DO NOT DELETE THIS LINE - it is needed to show the icons


export const i18n = createI18n({
    legacy: false,
    locale: 'de',
    messages,
    fallbackLocale: 'en',
    missingWarn: false, // Disable warnings for missing keys
    fallbackWarn: false, // Disable warnings for fallback keys
})

// const app = createApp(App);
const app = createApp({
    components: { App },
    setup() {
      return () => h(
        Suspense,
        null,
        {
          default: h(App),
          fallback: h(LoadingSpinner, { message : ''})
        }
      )
    }
  })

app.component('font-awesome-icon', FontAwesomeIcon)

app.use(router);
app.use(i18n);
app.use(store);

app.mount('#app');
