<template>
    <div class="props-panel border-end border-secondary">
        <form @submit.prevent="update">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="projectName" v-model="props.project_details.name" 
                    required
                    pattern=".*\S.*"
                    :title="$t('generic.empty_form_input')"
                >
                <label for="projectName">{{$t('welcome_page.new_project_modal.p_name')}}</label>
            </div>
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="location" v-model="props.project_details.location" @change="location_changed = true" 
                    required
                    pattern=".*\S.*"
                    :title="$t('generic.empty_form_input')"
                >
                <label for="location">{{$t('welcome_page.new_project_modal.p_location_short')}}</label>
            </div>
            <div class="d-flex gap-3">
                <div class="form-floating flex-fill">
                    <input type="text" class="form-control" id="longitude" v-model="props.project_details.longitude" disabled>
                    <label for="longitude">{{$t('welcome_page.modify_project_modal.p_long')}}</label>
                </div>
                <div class="form-floating flex-fill mb-3">
                    <input type="text" class="form-control" id="latitude" v-model="props.project_details.latitude" disabled>
                    <label for="latitude">{{$t('welcome_page.modify_project_modal.p_lat')}}</label>
                </div>
            </div>
            <button type="submit" class="btn btn-primary d-flex gap-3 align-items-center justify-content-center w-100">
                <font-awesome-icon icon="fa-solid fa-save"/>
            </button>
        </form>
    </div>
</template>
  
<script setup>
    import { ref } from 'vue';
    import { updateProjectDetails, geoReferenceProjectLocation } from '@/js/api_calls';
    import { useI18n } from 'vue-i18n';

    const props = defineProps({
        project_details: Object,
    });

    const emit = defineEmits(['update-success', 'update-fail']);

    const { t } = useI18n();

    const location_changed = ref(false);

    const update = async () => {
        let updatedProjectDetails;
        if(location_changed.value){
            updatedProjectDetails = {
                name: props.project_details.name,
                location: props.project_details.location,
            };
        }else{
            updatedProjectDetails = {
                name: props.project_details.name,
            };
        }
        console.log('Updated project details', updatedProjectDetails);

        const updated_details = await updateProjectDetails(props.project_details.id, updatedProjectDetails);

        if(!updated_details){
            emit('update-fail', t('welcome_page.modify_project_modal.update_fail'));
            return;
        }

        if(location_changed.value){
            console.log('Project details updated');
            const geo_ref_success = await geoReferenceProjectLocation(updated_details.id);
            if(!geo_ref_success){
                emit('update-geo-fail', t('welcome_page.modify_project_modal.update_geo_fail'));
                return;
            }
            console.log('Geo reference success', geo_ref_success);
        }
        emit('update-success');

    };

</script>
  
<style scoped>
    .props-panel{
        padding-right: 2rem ;
    }

    .form-control:disabled
    {
        cursor: not-allowed;
        opacity: 0.85;
    }

    .form-floating > label:after {
        background: transparent !important;
        color: white !important;
    }
</style>
  