<template>
    <div class="type-container">
        <div id="type-dropdown" class="form-floating" data-bs-theme="dark">
            <select id="type-select" class="form-select" @change="loadDetails" v-model="selected_type">
                <option :value="initial_select">-- {{ $t('type_forms.create_type') }} --</option>
                <option v-for="cg_type in store.state.climagruen_types[type]" :value="cg_type.id">{{ cg_type.common_name }}</option>
            </select>
            <label for="type-select">{{ $t('type_forms.create_type_or') }}</label>
        </div>


        <component ref="formRef" class="type-form" :is="forms[type]" :form_data="form_data[type]" :create_new="create_new" :image="images[type]" 
            @file-upload="updatePreview" @success="showSuccess" @failure="showError"
        />
        
        <div v-if="file_apis[type]" class="viewer-container">
            <FilePreview ref="FilePreviewRef" :lods="asset_lods"/>
        </div>

        <SuccessNotification v-if="success"/>
        <ErrorNotification v-if="failure" :message="error_message"/>
    </div>
</template>

<script setup>
    import { useStore } from 'vuex';
    import {ref} from 'vue';
    import VegetationForm from './forms/VegetationForm.vue';
    import VegetationMixForm from './forms/VegetationMixForm.vue';
    import LayersForm from './forms/LayersForm.vue';
    import MaterialsForm from './forms/MaterialsForm.vue';
    import FilePreview from './FilePreview.vue';
    import ErrorNotification from './notifications/ErrorNotification.vue';
    import SuccessNotification from './notifications/SuccessNotification.vue';
    import { api_endpoints } from '@/js/api_endpoints';
    import axios from 'axios';
    import {initial_state} from './forms/forms_initial_state';

    const store = useStore();

    const initial_select = "create_type";
    const create_new = ref(true);
    const selected_type = ref(initial_select);
    const success = ref(false);
    const failure = ref(false);
    const error_message = ref("");

    const FilePreviewRef = ref(null);
    const formRef = ref(null);

    const initial_state_static = JSON.parse(JSON.stringify(initial_state));
    const form_data = ref(initial_state);


    const props = defineProps({
        type: String
    })

    const forms ={
        vegetation: VegetationForm,
        vegetation_mix: VegetationMixForm,
        layers: LayersForm,
        materials: MaterialsForm
    }
    const images = {
        vegetation: "/src/components/img/root_params_explanation.png",
        layers: "/src/components/img/layers_explanation.png",
    }

    const apis = {
        vegetation: api_endpoints.plants_id, 
        vegetation_mix: api_endpoints.plants_cong_id, 
        layers: api_endpoints.layers_id, 
        materials: api_endpoints.materials_id
    };

    const file_apis = {vegetation: api_endpoints.plants_get_glb_id}

    const asset_lods = [ "300" ,"200", "100",]; // needs to be aligned with backend and database!!!

    function loadDetails(){        
        if(selected_type.value == initial_select){
            create_new.value = true;
            console.log(initial_state_static);
            updateForm(initial_state_static[props.type])
            return;
        }

        create_new.value = false;
        const token = window.localStorage.getItem('climagruen_token');
        axios.get(apis[props.type](selected_type.value),{
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }}
        ).then(response=>{
            console.log(response);
            updateForm(response.data)
        })
        .catch(error => {console.log(error)})

        if(file_apis[props.type]){
            formRef.value.disposeUploadedFile();
            FilePreviewRef.value.clearScene();
            let file_preview_triggered = false;
            for (const lod of asset_lods) {
                console.log(lod);
                axios.get(file_apis[props.type](selected_type.value, lod), {
                    headers: {
                                'Authorization': `Bearer ${token}`
                    },
                    responseType: 'arraybuffer'
                })
                .then((response) => {
                    FilePreviewRef.value.asset_arraybuffers[lod] = response.data;
                    if(!file_preview_triggered){
                        file_preview_triggered = true;
                        FilePreviewRef.value.loadFile(response.data, lod);
                    }
                })
                .catch((error) =>{
                    console.log(error);
                })
            }

        }
        
    }

    function updatePreview(asset_file, lod){
        console.log(asset_file);
        FilePreviewRef.value.loadFile(asset_file, lod);
    }

    function updateForm(data){
        form_data.value = { ...form_data.value, [props.type]: data };
        console.log(form_data.value)
    }

    function showSuccess(){
        success.value = true;
        setTimeout(() => {
            success.value = false;
        }, 2500);
    }

    function showError(message){
        failure.value = true;
        error_message.value = message;
        setTimeout(() => {
            failure.value = false;
            error_message.value = "";
        }, 2500);
    }
</script>

<style scoped>
    .type-container{
        grid-area:  main;
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas: 
            "hd1 hd2"
            "main1 main2"
            ;
        overflow-y: auto;
        gap: 1rem;
        padding: 1rem;

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .type-container::-webkit-scrollbar {
        display: none;
    }
    .type-dropdown{
        grid-area: hd1;
    }
    .type-form{
        grid-area: main1;
        height: max-content;
    }
    .viewer-container{
        grid-area: main2;
        height: 50%;
        max-height: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    @media (min-width: 2400px) {
        .type-container{
            grid-template-columns: 2fr 1fr 3fr;
        grid-template-rows: auto 1fr;
        grid-template-areas: 
        "hd1 hd2 hd3"
        "main1 main2 main3"
        ;
        }
    }

</style>
