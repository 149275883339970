<template>
    <div class="weather-panel container-fluid">
        
        <!-- Top Buttons -->
        <div class="d-flex mb-5 align-items-start">
            <h5 class="me-4">{{ t('welcome_page.modify_project_modal.weather_data') }}</h5>
            <button 
                class="btn btn-secondary me-2" 
                @click="handleButtonClick('button1')"
            >
                <font-awesome-icon icon="fa-solid fa-rotate-left"/>
                {{ t('welcome_page.modify_project_modal.generate_statistical') }}
            </button>
            <button 
                class="btn btn-secondary" 
                @click="handleButtonClick('button2')"
                :disabled="!block_rain_available"
            >
                <font-awesome-icon icon="fa-solid fa-rotate-left"/>
                {{ t('welcome_page.modify_project_modal.generate_extreme') }}
            </button>
            <button v-if="!block_rain_available" type="button" class="info ms-1" :title="t('welcome_page.modify_project_modal.extreme_rain_note')">
                <font-awesome-icon icon="fa-solid fa-circle-info"/>
            </button>
        </div>

        <!-- Tabs -->
        <ul class="nav nav-tabs" id="dashboardTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button 
                    class="nav-link" 
                    :class="{ active: activeTab === 'precipitation' }"
                    @click="activeTab = 'precipitation'"
                    type="button"
                    role="tab"
                    aria-controls="precipitation"
                    aria-selected="true"
                >
                    {{ t('welcome_page.modify_project_modal.statistical_data') }}
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button 
                    class="nav-link" 
                    :class="{ active: activeTab === 'blockRain' }"
                    @click="activeTab = 'blockRain'"
                    type="button"
                    role="tab"
                    aria-controls="blockRain"
                    aria-selected="false"
                >
                    {{ t('welcome_page.modify_project_modal.extreme_data') }}
                </button>
            </li>
        </ul>

        <!-- Tab Content -->
        <div class="tab-content" id="dashboardTabContent">
            <div 
                class="tab-pane fade" 
                :class="{ 'show active': activeTab === 'precipitation' }" 
                id="precipitation" 
                role="tabpanel" 
                aria-labelledby="precipitation-tab"
            >
                <WeatherChart
                    :simulation_type="'statistical'"
                    :chart_data="chartData"
                />
            </div>
            <div 
                class="tab-pane fade" 
                :class="{ 'show active': activeTab === 'blockRain' }" 
                id="blockRain" 
                role="tabpanel" 
                aria-labelledby="blockRain-tab"
            >
                <div class="d-flex align-items-center mb-3">
                    <!-- Button Group for View Mode -->
                    <div class="btn-group me-4" role="group" aria-label="View mode selection">
                        <input 
                            type="radio" 
                            class="btn-check" 
                            name="viewMode" 
                            id="viewChart" 
                            autocomplete="off" 
                            value="chart"
                            v-model="viewMode"
                        >
                        <label class="btn btn-outline-secondary" for="viewChart">{{  t('welcome_page.modify_project_modal.diagram') }}</label>
                    
                        <input 
                            type="radio" 
                            class="btn-check" 
                            name="viewMode" 
                            id="viewTable" 
                            autocomplete="off" 
                            value="table"
                            v-model="viewMode"
                        >
                        <label class="btn btn-outline-secondary" for="viewTable">{{ t('welcome_page.modify_project_modal.table') }}</label>
                    </div>

                    <button  
                        class="btn btn-primary" 
                        @click="saveData"
                    >
                        <font-awesome-icon icon="fa-solid fa-save"/>
                    </button>
                </div>

                <!-- Success and Error Messages -->
                <div class="mb-3">
                    <div v-if="successMessage" class="alert alert-success" role="alert">
                        {{ successMessage }}
                    </div>
                    <div v-if="errorMessage" class="alert alert-danger" role="alert">
                        {{ errorMessage }}
                    </div>
                </div>

                <WeatherChart 
                    v-if="viewMode === 'chart'"
                    :simulation_type="'extreme'"
                    :chart_data="chartData"
                />
                
                <WeatherTable 
                    v-else
                    :durations="durations"
                    :frequencies="frequencies"
                    :tableData="tableData"
                    @update:tableData="handleTableDataUpdate"
                />
            </div>

            <div class="spinner-overlay" v-if="loading_weather_data">
                <LoadingSpinnerModal :message="''" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import WeatherChart from '@/components/modals/results/Weather.vue'
import WeatherTable from './WeatherTable.vue' // Import the WeatherTable component
import LoadingSpinnerModal from '@/components/notifications/LoadingSpinnerModal.vue';

const props = defineProps({
    block_rain_available: Boolean,
})


// Internationalization
const { t } = useI18n()

// Reactive state for active tab
const activeTab = ref('precipitation')

// Reactive state for view mode: 'chart' or 'table'
const viewMode = ref('chart')

const loading_weather_data = ref(false);

// Define durations and frequencies
const durations = [
    5, 10, 15, 20, 30, 45, 60, 90, 120,
    180, 240, 360, 540, 720, 1080, 1440,
    2880, 4320, 5760, 7200, 8640, 10080,
]

const frequencies = [1, 2, 3, 5, 10, 20, 30, 50, 100]

// Initialize table data
const tableData = ref({})

durations.forEach(duration => {
    tableData.value[duration] = {}
    frequencies.forEach(freq => {
        tableData.value[duration][freq] = 0 // Initialize with default value (e.g., 0)
    })
})

// Sample data for precipitation (8760 hours)
const generateSamplePrecipitationData = () => {
    const data = []
    for (let i = 0; i < 8760; i++) {
        // Generate random precipitation values between 0 and 100 mm
        data.push(Math.floor(Math.random() * 100))
    }
    return data
}

// Reactive chart data
const chartData = ref({
    precipitation: generateSamplePrecipitationData()
    // 'block_rain' data is handled internally in WeatherChart
})

// Handlers for top buttons
const handleButtonClick = (button) => {
    console.log(`${button} clicked`)
    // Implement desired functionality for each button
}

// Handler for table data updates
const handleTableDataUpdate = ({ duration, freq, value }) => {
    tableData.value[duration][freq] = value
}

// Success and error messages
const successMessage = ref('')
const errorMessage = ref('')

// Handler for save button
const saveData = () => {
    console.log('Save button clicked')
    console.log('Table Data:', tableData.value)

    // Clear previous messages
    successMessage.value = ''
    errorMessage.value = ''

}


</script>

<style scoped>
.weather-panel {
    padding-left: 2rem;
    width: auto;
}

.nav{
    border: none;
}

.nav-link {
    border: 1px solid transparent;
    color:#f8f9fa;
    text-decoration: none;
}

.nav-link.active {
    background-color: white;
    border-color: #e5e5e5 #e5e5e5 #fff;
    color: #495057;
}
.tab-content{
    position: relative;
    background-color: white;
    padding: 2rem 1rem 1rem 1rem;
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}


.spinner-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1050;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
.info{
    background: none;
    outline: none;
    border: none;
    color: white;
}

.info[title]{
    max-width: 200px;
}
</style>
