<template>
    <div class="form-floating" data-bs-theme="dark">
        <select class="form-select" @change="switchLod" v-model="selected_lod">
            <option v-for="lod in lods" :value="lod">{{ `LoD ${lod}` }}</option>
        </select>
        <label for="type-select">{{ $t('type_forms.plant_form.select_lod') }}</label>
    </div>

    <div id="preview_canvas" class="previewer"></div>
</template>
  
<script setup>
    import { onMounted, onUnmounted, ref } from 'vue';
    import * as THREE from 'three';
    import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

    import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
   
    import {createGLTFLoader} from '../js/modeling_utils.js'
    
    let preview_canvas;
    let scene, camera, renderer,controls, gltfLoader, animationId, current_asset;
    
    const props = defineProps({
        lods: Array
    })
    const asset_arraybuffers = {};
    const selected_lod = ref(null)

    defineExpose({ loadFile, clearScene , asset_arraybuffers});
    
    onMounted(() => {
        preview_canvas = document.getElementById("preview_canvas")

        scene = new THREE.Scene();
        camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        renderer = new THREE.WebGLRenderer();
        gltfLoader = createGLTFLoader();

        preview_canvas.appendChild(renderer.domElement);

        // camera.position.z = 10;
        // camera.position.y = 10;


        // Or, for a gradient sky (simpler)
        const skyColor = new THREE.Color(0xbfd1e5); // Light blue sky
        scene.background = skyColor;

        const ambientLight = new THREE.AmbientLight(0x404040); // Soft white light
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xfffad4, 1); // Warm sunlight
        directionalLight.position.set(-1, 2, 4); // Adjust the position to simulate the sun's direction
        scene.add(directionalLight);

        // Add OrbitControls
        controls = new OrbitControls(camera, renderer.domElement);

        const pmremGenerator = new THREE.PMREMGenerator( renderer );
		pmremGenerator.compileEquirectangularShader();
        scene.environment = pmremGenerator.fromScene( new RoomEnvironment(), 0.04 ).texture;

        const animate = function () {
            animationId = requestAnimationFrame(animate);
            if (resizeRendererToDisplaySize(renderer)) {
                const canvas = renderer.domElement;
                camera.aspect = canvas.clientWidth / canvas.clientHeight;
                camera.updateProjectionMatrix();
            }
            controls.update()
            renderer.render(scene, camera);
        };

        animate();
    })

    onUnmounted(()=>{
        clearScene();
        renderer.dispose();
        cancelAnimationFrame(animationId);
    })

    const resizeRendererToDisplaySize = (renderer) => {
        const canvas = renderer.domElement;
        const width =  preview_canvas.clientWidth;
        const height =  preview_canvas.clientHeight;
        const needResize = canvas.width !== width || canvas.height !== height;
        if (needResize) {
            renderer.setSize(width, height, false);
        }
        return needResize;
    };

    function switchLod(){
        console.log("Switch triggered");
        if(selected_lod.value && asset_arraybuffers[selected_lod.value]){
            loadFile(asset_arraybuffers[selected_lod.value], selected_lod.value)
        }else{
            clearScene();
        }
    }

    function loadFile(arrayBuffer, lod){
        selected_lod.value = lod;
        if(arrayBuffer instanceof File){
            const url = URL.createObjectURL(arrayBuffer);
            gltfLoader.load(
                // file path
                url,
                // called when the resource is loaded
                ( gltf ) => {
                    clearScene();
                    fitCameraToObject(gltf.scene);
                    current_asset = gltf.scene
                    scene.add( current_asset );
                },
                // called while loading is progressing
                ( xhr ) => {
                    console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
                },
                // called when loading has errors
                ( error ) => {
                    console.log( error );
                }
            );
        }
        else{
            gltfLoader.parse(
                // Data
                arrayBuffer,
                "",
                // called when the resource is loaded
                ( gltf ) => {
                    clearScene();
                    fitCameraToObject(gltf.scene);
                    current_asset = gltf.scene
                    scene.add( current_asset );
                },
                // called when loading has errors
                function ( error ) {
                    console.log( error );
                }
            );
        }
    }

    function clearScene(){
        if(current_asset){
            disposeRecursive(current_asset);
        }
        
    }

    function disposeRecursive(object){
            while(object.children.length > 0){
                disposeRecursive(object.children[0]);
            }
            if(object.geometry) object.geometry.dispose();
            if(Array.isArray(object.material)){
                for(const material of object.material){
                    if(material.map) material.map.dispose();
                    material.dispose();
                }
            }else if(object.material){
                if(object.material.map) object.material.map.dispose();
                object.material.dispose();
            }
            object.removeFromParent();
        }

    function fitCameraToObject(object, offset = 5, heightFactor = 0.5) {
        const boundingBox = new THREE.Box3().setFromObject(object);
        
        const center = boundingBox.getCenter(new THREE.Vector3());
        const size = boundingBox.getSize(new THREE.Vector3());
        
        // Fit the object to the camera
        const maxDim = Math.max(size.x, size.y, size.z);
        const fov = camera.fov * (Math.PI / 180);
        let cameraZ = Math.abs(maxDim / 4 * Math.tan(fov * 2));

        cameraZ *= offset; // Apply offset factor

        // Set camera position and look at the center of the object
        camera.position.z = center.z + cameraZ;
        camera.position.y = center.y + (maxDim * heightFactor); // Adjust height to view from above
        camera.position.x = center.x;
        camera.lookAt(center);

        if (controls) {
            // If there are orbit controls, update their target
            controls.target.copy(center);
            controls.update();
        } else {
            camera.lookAt(center);
        }
        
        // Update the camera's projection matrix
        camera.updateProjectionMatrix();
    }
</script>
<style scoped>
    .previewer{
        width: 100%;
        height: 100%;
        border-radius: 5px;
        overflow: hidden;
    }
</style>