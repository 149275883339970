<template>
    <div>
      <!-- Dropdown select with Gründach and Rigole options -->
      <select v-model="selectedOption" class="form-select">
        <option value="Gründach">Gründach</option>
        <option value="Rigole">Rigole</option>
      </select>
  
      <!-- Display for Gründach (All current content) -->
      <div v-if="selectedOption === 'Gründach'">
        <div class="bewaesserung-section">
          <h3>Bewässerungssystem Daten</h3>
          <ul class="data-list">
            <li>Bewässerungsbedarf zusätzlich zu Regen [m³]: <strong>{{ bewaesserungsbedarf }}</strong></li>
            <li>Trinkwasserbedarf ohne Speicher [m³]: <strong>{{ trinkwasserOhneSpeicher }}</strong></li>
            <li>Trinkwasserbedarf mit Speicher [m³]: <strong>{{ trinkwasserMitSpeicher }}</strong></li>
            <li>Trinkwasser eingespart: <strong>{{ trinkwasserEingespart }}%</strong></li>
          </ul>
        </div>
  
        <!-- Display tables only when "Gründach" is selected -->
        <h3>Benötigte Dachabläufe bei Normalbetrieb (Bemessungswert [r5,5])</h3>
  
        <!-- Tables in a row (Freispiegelentwässerung and Drucksystem) -->
        <div class="table-row">
          <div class="table-wrapper">
            <h4>Freispiegelentwässerung</h4>
            <table>
              <thead>
                <tr>
                  <th>DN</th>
                  <th>Mindesabflussmenge [l/s]</th>
                  <th>Anzahl</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in normalbetriebFreispiegelentwaesserung" :key="index">
                  <td>{{ row.dn }}</td>
                  <td>{{ row.mindesabflussmenge }}</td>
                  <td>{{ row.anzahl }}</td>
                </tr>
              </tbody>
            </table>
          </div>
  
          <div class="table-wrapper">
            <h4>Drucksystem</h4>
            <table>
              <thead>
                <tr>
                  <th>DN</th>
                  <th>Mindesabflussmenge [l/s]</th>
                  <th>Anzahl</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in normalbetriebDrucksystem" :key="index">
                  <td>{{ row.dn }}</td>
                  <td>{{ row.mindesabflussmenge }}</td>
                  <td>{{ row.anzahl }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        <h3>Zusätzlich benötigte Dachabläufe bei Jahrhundertereignis (r[5.100])</h3>
  
        <!-- Tables in a row (Freispiegelentwässerung and Drucksystem) -->
        <div class="table-row">
          <div class="table-wrapper">
            <h4>Freispiegelentwässerung</h4>
            <table>
              <thead>
                <tr>
                  <th>DN</th>
                  <th>Mindesabflussmenge [l/s]</th>
                  <th>Anzahl</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in jahrhundertFreispiegelentwaesserung" :key="index">
                  <td>{{ row.dn }}</td>
                  <td>{{ row.mindesabflussmenge }}</td>
                  <td>{{ row.anzahl }}</td>
                </tr>
              </tbody>
            </table>
          </div>
  
          <div class="table-wrapper">
            <h4>Drucksystem</h4>
            <table>
              <thead>
                <tr>
                  <th>DN</th>
                  <th>Mindesabflussmenge [l/s]</th>
                  <th>Anzahl</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in jahrhundertDrucksystem" :key="index">
                  <td>{{ row.dn }}</td>
                  <td>{{ row.mindesabflussmenge }}</td>
                  <td>{{ row.anzahl }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
      <!-- Display for Rigole -->
      <div v-if="selectedOption === 'Rigole'" class="rigole-section">
        <h3>Rigole Daten</h3>
        <p>Maximaler Füllstand: <strong>95%</strong></p>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  // Track the selected option
  const selectedOption = ref('Gründach');
  
  // Data for Gründach (Bewässerungssystem and Dachabläufe)
  const bewaesserungsbedarf = ref(82.6);
  const trinkwasserOhneSpeicher = ref(82.6);
  const trinkwasserMitSpeicher = ref(20);
  const trinkwasserEingespart = ref(75.79);
  
  const normalbetriebFreispiegelentwaesserung = ref([
    { dn: 50, mindesabflussmenge: 0.9, anzahl: 2 },
    { dn: 70, mindesabflussmenge: 1.7, anzahl: 1 },
    { dn: 100, mindesabflussmenge: 4.5, anzahl: 1 },
    { dn: 125, mindesabflussmenge: 7.5, anzahl: 1 },
    { dn: 150, mindesabflussmenge: 8.1, anzahl: 1 },
  ]);
  
  const normalbetriebDrucksystem = ref([
    { dn: 50, mindesabflussmenge: 6.0, anzahl: 1 },
    { dn: 70, mindesabflussmenge: 12.0, anzahl: 1 },
    { dn: 100, mindesabflussmenge: 22.0, anzahl: 1 },
  ]);
  
  const jahrhundertFreispiegelentwaesserung = ref([
    { dn: 50, mindesabflussmenge: 0.9, anzahl: 15 },
    { dn: 70, mindesabflussmenge: 1.7, anzahl: 5 },
    { dn: 100, mindesabflussmenge: 4.5, anzahl: 2 },
    { dn: 125, mindesabflussmenge: 7.5, anzahl: 1 },
    { dn: 150, mindesabflussmenge: 8.1, anzahl: 1 },
  ]);
  
  const jahrhundertDrucksystem = ref([
    { dn: 50, mindesabflussmenge: 6.0, anzahl: 1 },
    { dn: 70, mindesabflussmenge: 12.0, anzahl: 1 },
    { dn: 100, mindesabflussmenge: 22.0, anzahl: 1 },
  ]);
  </script>
  
  <style scoped>
  /* Set text color and background */
  div {
    color: #495057;
    background-color: #ffffff;
  }
  
  select {
    margin-bottom: 20px;
  }
  
  /* Style for Bewässerungssystem section */
  .bewaesserung-section {
    margin-top: 20px;
  }
  
  .data-list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .data-list li {
    margin-bottom: 10px;
  }
  
  /* Tables layout with two tables side by side */
  .table-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .table-wrapper {
    flex: 1;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    color: #495057;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  h3, h4 {
    margin-top: 20px;
    color: #495057;
  }
  
  /* Style for Rigole section */
  .rigole-section {
    margin-top: 20px;
    color: #495057;
  }
  
  .rigole-section p {
    font-size: 18px;
  }
  </style>
  