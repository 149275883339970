<template>
    <div class="custom-modal">
        <div class="results-modal bg-dark text-white">
            <div class="modal-header">
                <div class="modal-title">{{ $t("results.modal_title") }}</div>
                <button class="close btn-close btn-close-white" @click="closeModal"></button>
            </div>
            <div class="modal-body">
                <select class="form-select" v-model="selectedSimulation" @change="fetchSimulationData">
                    <option :value="null" disabled>{{ $t('results.select_simulation') }}</option>
                    <option v-for="simulation in simulations" :key="simulation.id" :value="simulation.id">
                        {{ simulation.name }}
                    </option>
                </select>
                <div v-if="selectedSimulation" class="result-container">

                    <select class="form-select mb-3 align-right" v-model="selectedSimulationType" @change="">
                        <option :value="null" disabled>{{ $t('results.select_simulation_type') }}</option>
                        <option value="statistical">{{ $t('results.statistical_type') }}</option>
                        <option value="extreme">{{ $t('results.extreme_type') }}</option>
                        <option value="long_term">{{ $t('results.long_term_type') }}</option>
                    </select>

                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'weather' }" @click="activeTab = 'weather'">{{ $t('results.tab_weather') }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'time_series' }" @click="activeTab = 'time_series'">{{ $t('results.tab_time_series') }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'hydraulics' }" @click="activeTab = 'hydraulics'">{{ $t('results.tab_hydraulic') }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'water_balance' }" @click="activeTab = 'water_balance'">{{ $t('results.tab_water_balance') }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'flooding' }" @click="activeTab = 'flooding'">{{ $t('results.tab_flooding') }}</a>
                        </li>
                    </ul>
                    
                    <LoadingSpinnerModal v-if="loading" :message="''" />

                    <div v-if="!loading && dataLoaded" class="tab-content">
                        <Weather v-if="activeTab === 'weather'" :simulation_type="selectedSimulationType" :chart_data="simulation_data.weather"/>
                        <TimeSeries v-else-if="activeTab === 'time_series'" :simulation_type="selectedSimulationType" :chart_data="simulation_data.time_series"/>
                        <Hydraulics v-else-if="activeTab === 'hydraulics'"/>
                        <WaterBalance v-else-if="activeTab === 'water_balance'"/>
                        <Flooding v-else-if="activeTab === 'flooding'"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, watch, nextTick } from 'vue';
    import axios from 'axios';
    import { useStore } from 'vuex';
    import { api_endpoints } from '@/js/api_endpoints';
    import { useRoute } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import LoadingSpinnerModal from '../../notifications/LoadingSpinnerModal.vue';
    import TimeSeries from './TimeSeries.vue';
    import Weather from './Weather.vue';
    import WaterBalance from './WaterBalance.vue';
    import Flooding from './Flooding.vue';

    import fakeData from './fake_data.json';
    import Hydraulics from './Hydraulics.vue';


    const { t } =  useI18n();

    const simulations = ref([]);
    const selectedSimulation = ref(null);
    const selectedSimulationType = ref('statistical');
    const activeTab = ref('weather');

    const simulation_data = ref({});

    const store = useStore();
    const emit = defineEmits(['close-modal']);
    const route = useRoute();
    const loading = ref(false);
    const dataLoaded = ref(false);

    const fetchSimulations = async () => {
        const token = window.localStorage.getItem('climagruen_token');
        try {
            const response = await axios.get(api_endpoints.simulations(route.params.id), {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            simulations.value = response.data;
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching simulations:', error);
        }
    };

    const fetchSimulationData = async () => {
        if (!selectedSimulation.value) return;
        loading.value = true;
        const token = window.localStorage.getItem('climagruen_token');
        let data = store.state.simulation_data[selectedSimulation.value];
        // if (!data) {
        //     try {
        //         // const response = await axios.get(api_endpoints.results(route.params.id , selectedSimulation.value), {
        //         //     headers: {
        //         //         Authorization: `Bearer ${token}`,
        //         //     },
        //         // });
        //         const response = await axios.get(api_endpoints.results(5 , 4), {
        //             headers: {
        //                 Authorization: `Bearer ${token}`,
        //             },
        //         });
        //         ;
        //         const data_1 = JSON.parse(response.data[0].data.replace(/'/g, '"'));
        //         const data_2 = JSON.parse(response.data[1].data.replace(/'/g, '"'));
        //         const data_3 = JSON.parse(response.data[2].data.replace(/'/g, '"'));
        //         console.log("Data 1: ", data_1);
        //         console.log("Data 2: ", data_2);
        //         console.log("Data 3: ", data_3);
        //         console.log("Data String: ", response.data[2].data.replace(/'/g, '"'));
        //         data = processData(data_3);
        //         console.log("Processed Data: ", data);
        //         store.commit('storeSimulationData', { id: selectedSimulation.value, data });
        //     } catch (error) {
        //         loading.value = false;
        //         console.error('Error fetching simulation data:', error);
        //         return;
        //     }
        // }
        if(!data){
            data = processData(fakeData);
            store.commit('storeSimulationData', { id: selectedSimulation.value, data });
        };
        const { irrigation, water_levels, precipitation, throughput } = data;
        simulation_data.value['time_series'] ={ irrigation, water_levels, throughput }
        simulation_data.value['weather'] ={ precipitation }
        console.log("Simulation Data: ", simulation_data.value);
        nextTick(() => {
            loading.value = false;
            dataLoaded.value = true;
        });
        // loading.value = false;
    };

    function processData(data){
        const processedData = {};

        processedData.irrigation = data.irrigation;
        processedData.throughput = data.throughput;

        processedData.precipitation = reduceGranularity(data.precipitation, 'minutes');

        processedData.water_levels = {};
        for(const component in data.water_levels){
            const data_values = data.water_levels[component];
 
            if(data_values.length === 0) continue;
            
            processedData.water_levels[component] = reduceGranularity(data_values, 'tenMinutes');
        }

        return processedData;
    }

    function reduceGranularity(data, granularity) {
        const hourlyData = [];
        const valuesPerHour = {
            'minutes': 60,  // 60 minutes in an hour
            'tenMinutes': 6 // 6 ten-minute intervals in an hour
        }[granularity];

        for (let i = 0; i < data.length; i += valuesPerHour) {
            const hourData = data.slice(i, i + valuesPerHour);
            const average = hourData.reduce((sum, value) => sum + value, 0) / hourData.length;
            hourlyData.push(average);
        }

        return hourlyData;
    }

    onMounted(fetchSimulations);

    // watch(selectedSimulation, fetchSimulationData);

    const closeModal = () => {
        emit('close-modal');
    };
</script>

<style scoped>
.results-modal {
    width: 80vw;
    min-height: 30vh;
    max-height: 95vh;
    max-width: 1000px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    padding: 1rem;
}

.modal-title {
    font-size: 1.25rem;
    font-weight: 500;
}



.modal-body {
    padding: 2rem 1rem;
    overflow-y: hidden;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
}

.result-container{
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    background-color:#343a40;
    border-radius: 0.5rem;
    overflow: hidden;
}

.nav-tabs {
    display: flex;
    width: max-content;
    margin-bottom: 0;
    border: none;
    /* position: sticky; */
    top: 0;
    border: 1px solid #e5e5e5;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    gap: 8px;
}

/* .nav-item {
    margin-right: 8px;
} */

.nav-link {
    padding: 8px 16px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    color:#f8f9fa;
    text-decoration: none;
}

.nav-link.active {
    background-color: white;
    border-color: #e5e5e5 #e5e5e5 #fff;
    color: #495057;
}

.tab-content {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    /* border: 1px solid #e5e5e5; */
    /* background-color: rgba(128, 128, 128, 0.527); */
    background-color: white;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.align-right{
    width: max-content;
    align-self: flex-end;
}
</style>