<template>
    <h5>Abzuführende Wassermengen in m³</h5> <!-- Table title -->

    <table>
        <thead>
        <tr>
            <th rowspan="2" style="vertical-align: bottom;font-weight: bold;">Jährlichkeit</th> <!-- Spanning down for return period header -->
            <th colspan="3" style="font-weight: bold;">Niederschlagsdauer</th> <!-- Span across the columns for time -->
        </tr>
        <tr>
            <th>5 min</th>
            <th>10 min</th>
            <th>15 min</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <th>30-jährlich</th> <!-- Specific row header for 30-year period -->
            <td>{{ data['30jährlich']['5min'] }}</td>
            <td>{{ data['30jährlich']['10min'] }}</td>
            <td>{{ data['30jährlich']['15min'] }}</td>
        </tr>
        <tr>
            <th>100-jährlich</th> <!-- Specific row header for 100-year period -->
            <td>{{ data['100jährlich']['5min'] }}</td>
            <td>{{ data['100jährlich']['10min'] }}</td>
            <td>{{ data['100jährlich']['15min'] }}</td>
        </tr>
        </tbody>
    </table>
  </template>
  
  <script setup>
  import { reactive } from 'vue';
  
  const data = reactive({
    '30jährlich': {
      '5min': 3.11,
      '10min': 4.05,
      '15min': 4.7,
    },
    '100jährlich': {
      '5min': 3.95,
      '10min': 5.07,
      '15min': 5.82,
    },
  });
  </script>
  
  <style scoped>
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
    color: #495057;
  }
  h5
    {
        color: #495057;
    }
  
  </style>